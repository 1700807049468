import sentryEnvironment from '@glass/shared/modules/sentry/sentryEnvironment';

const defaultSentrySettings = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: sentryEnvironment,
  tracesSampleRate: 0.05,
  attachStacktrace: true,
  maxBreadcrumbs: 5,
  autoSessionTracking: true,
  normalizeDepth: 10,
};

export default defaultSentrySettings;
