import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';

import { ThemeGeneratorProps, ThemeProps } from '@glass/web/modules/theme/types';

const createThemeGenerator = ({
  muiTheme,
  responsiveFontSizeOptions,
  usePrefersDarkMode = false,
}: ThemeProps) => {
  const theme = createMuiTheme(muiTheme);

  const themeWithFontSizes = responsiveFontSizeOptions
    ? responsiveFontSizes(theme, responsiveFontSizeOptions)
    : theme;

  // this is generated in useEffect in `withTheme` HOC
  return ({ prefersDarkMode }: ThemeGeneratorProps = {}) => {
    if (usePrefersDarkMode && prefersDarkMode) {
      themeWithFontSizes.palette.mode = 'dark';
    }

    if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
      (window as any).theme = theme;
    }

    return themeWithFontSizes;
  };
};

export default createThemeGenerator;
