import getResumeContactPhone from '@glass/common/modules/resumes/selectors/getResumeContactPhone';
import { standardizePhone } from '@glass/common/modules/utils/formatPhone';
import { selectUser } from '@glass/web/modules/auth';
import { selectResumeValue } from '@glass/web/modules/resumes/selectors';

const createSelectUserStandardizedPhone = (options) => (state) => {
  return (
    standardizePhone(selectUser(state)?.profile?.phone, options) ||
    standardizePhone(getResumeContactPhone(selectResumeValue(state)), options)
  );
};

export default createSelectUserStandardizedPhone;
