import amber from '@mui/material/colors/amber';
import cyan from '@mui/material/colors/cyan';
import grey from '@mui/material/colors/grey';
import indigo from '@mui/material/colors/indigo';
import purple from '@mui/material/colors/purple';
import yellow from '@mui/material/colors/yellow';

export { default as blue } from '@mui/material/colors/blue';
export { default as brown } from '@mui/material/colors/brown';
export { default as common } from '@mui/material/colors/common';
export { default as deepOrange } from '@mui/material/colors/deepOrange';
export { default as green } from '@mui/material/colors/green';
export { default as lightGreen } from '@mui/material/colors/lightGreen';
export { default as lime } from '@mui/material/colors/lime';
export { default as orange } from '@mui/material/colors/orange';
export { default as pink } from '@mui/material/colors/pink';
export { default as red } from '@mui/material/colors/red';
export { default as teal } from '@mui/material/colors/teal';

export { darken } from '@mui/material/styles';
export { purple };
export { amber };
export { indigo };
export { yellow };
export { cyan };
export { grey };

export const GREY_50 = grey[50];
export const GREY_200 = grey[200];
export const AMBER_300 = amber[300];

export const colorOptions = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];
