import type { LocaleType } from '@glass/env/modules/locales/constants';
import type { PayloadAction, RootState } from '@glass/web/modules/redux/types';

export const SET_APP_LOADING = 'SET_APP_LOADING';
export const OPEN_HEADER_CTA = 'OPEN_HEADER_CTA';
export const SET_ROUTE_BLOCK = 'SET_ROUTE_BLOCK';
export const SET_ROUTE_UNBLOCK = 'SET_ROUTE_UNBLOCK';
export const SET_LOCALE = 'SET_LOCALE';
export const APP_REDUCER_KEY = 'app';

export type AppState = {
  loading: boolean;
  block: boolean;
  onBlock: ((evt: React.MouseEvent<HTMLAnchorElement>) => void) | null;
  fallbackMessage: string | null;
  headerCtaOpen: boolean;
  locale: LocaleType | null;
};

const initialState: AppState = {
  loading: false,
  block: false,
  onBlock: null,
  fallbackMessage: null,
  headerCtaOpen: false,
  locale: null,
};

export default function appReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AppState = initialState,
  action: PayloadAction<AppState>,
): AppState {
  switch (action.type) {
    case OPEN_HEADER_CTA:
      return {
        ...state,
        headerCtaOpen: action.payload.headerCtaOpen,
      };
    case SET_APP_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case SET_ROUTE_BLOCK:
      return {
        ...state,
        block: true,
        onBlock: action.payload.onBlock,
      };
    case SET_ROUTE_UNBLOCK:
      return {
        ...state,
        block: false,
        onBlock: null,
      };
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };
    default:
      return state;
  }
}

export const selectIsAppLoading = (state: RootState) => state[APP_REDUCER_KEY].loading;
export const selectRouteOnBlock = (state: RootState) => state[APP_REDUCER_KEY].onBlock;
export const selectHeaderCtaOpen = (state: RootState) => state[APP_REDUCER_KEY].headerCtaOpen;
export const selectAppLocale = (state: RootState) => state[APP_REDUCER_KEY].locale;
