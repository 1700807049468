type DeadlineWithShim = IdleDeadline & { _isShim: boolean };
export type CallbackWithShim = (deadline: DeadlineWithShim) => void;

const setupRequestIdleCallbackShim = () => {
  if (typeof window !== 'undefined') {
    window.requestIdleCallback =
      window.requestIdleCallback ||
      function requestIdleCallback(cb: CallbackWithShim) {
        const start = Date.now();
        return setTimeout(function requestIdleCallbackTimeoutShim() {
          cb({
            _isShim: true,
            didTimeout: false,
            timeRemaining() {
              return Math.max(0, 50 - (Date.now() - start));
            },
          });
        }, 1);
      };

    window.cancelIdleCallback =
      window.cancelIdleCallback ||
      function (id) {
        clearTimeout(id);
      };
  }
};

export default setupRequestIdleCallbackShim;
