import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  PRIORITY_ASSETS_LOADED_EVENT,
  PRIORITY_ASSETS_LOADED_WINDOW_KEY,
} from '@glass/web/modules/loading/setupHighPriorityAssetEvent';

export const READY_STATE_COMPLETE = 'complete';
export const READY_STATE_INTERACTIVE = 'interactive';
export const READY_STATE_LOADING = 'loading';

export type ReadyState =
  | typeof READY_STATE_COMPLETE
  | typeof READY_STATE_INTERACTIVE
  | typeof READY_STATE_LOADING;

export const READY_STATE_ORDER: ReadyState[] = [
  READY_STATE_LOADING,
  READY_STATE_INTERACTIVE,
  READY_STATE_COMPLETE,
];

const READY_STATE_EVENT_TYPE = 'readystatechange';

const AppTimingContext = createContext(false);
const DocumentReadyContext = createContext<ReadyState>(READY_STATE_LOADING);

// internal use and only one instance of this hook / use the context hook below
const useIsPriorityLoadedEvent = () => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleLoad = () => {
        setLoaded(true);
      };
      // @ts-ignore
      if (window[PRIORITY_ASSETS_LOADED_WINDOW_KEY]) {
        handleLoad();
        return;
      }
      window.addEventListener(PRIORITY_ASSETS_LOADED_EVENT, handleLoad, { once: true });
      return () => {
        window.removeEventListener(PRIORITY_ASSETS_LOADED_EVENT, handleLoad);
      };
    }
  }, []);

  return isLoaded;
};

const useDocumentReadyStateEvent = () => {
  const [readyState, setReadyState] = useState<ReadyState>(READY_STATE_ORDER[0]);

  useEffect(() => {
    // Function to update the ready state
    const updateReadyState = () => {
      const currentReadyState = document.readyState;

      // Update the state
      setReadyState(currentReadyState);

      // If the ready state is complete, remove the event listener
      if (currentReadyState === READY_STATE_ORDER[READY_STATE_ORDER.length - 1]) {
        document.removeEventListener(READY_STATE_EVENT_TYPE, updateReadyState);
      }
    };

    // Check the initial ready state
    const initialReadyState = document.readyState;

    // Update the state immediately
    setReadyState(initialReadyState);

    // If the document is not yet fully loaded, listen for future changes to the ready state
    if (initialReadyState !== READY_STATE_COMPLETE) {
      document.addEventListener(READY_STATE_EVENT_TYPE, updateReadyState);

      // Return a cleanup function that removes the event listener.
      return () => {
        document.removeEventListener(READY_STATE_EVENT_TYPE, updateReadyState);
      };
    }
  }, []);

  return readyState;
};

export const useIsPriorityLoaded = () => useContext(AppTimingContext);
export const useDocumentReadyState = () => useContext(DocumentReadyContext);

// @ts-ignore
const withPriorityEvents = () => (Component) => {
  // @ts-ignore
  function WithIsPriorityLoaded(props) {
    const isPriorityLoadedEvent = useIsPriorityLoadedEvent();
    const documentReadyState = useDocumentReadyStateEvent();

    return (
      <DocumentReadyContext.Provider value={documentReadyState}>
        <AppTimingContext.Provider value={isPriorityLoadedEvent}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...props} />
        </AppTimingContext.Provider>
      </DocumentReadyContext.Provider>
    );
  }

  WithIsPriorityLoaded.getInitialProps = Component.getInitialProps;

  return WithIsPriorityLoaded;
};

export default withPriorityEvents;
