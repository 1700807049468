import makeArray from '@glass/common/modules/utils/makeArray';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const selectErrorCode = (error: unknown): string => error?.extensions?.code || '';

const isErrorType = (error: unknown, types: string | string[]) => {
  const code = selectErrorCode(error);
  return !!code && makeArray(types).includes(code);
};

export default isErrorType;
