import { persistStore as reduxPersistStore } from 'redux-persist';

const persistStore = (...args: Parameters<typeof reduxPersistStore>) => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return reduxPersistStore(...args);
};

export default persistStore;
