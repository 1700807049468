import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useMemo } from 'react';

import ClassNameGenerator from '@glass/web/components/base/ClassNameGenerator';
import ThemeColorHead from '@glass/web/modules/theme/ThemeColorHead';
import ThemeProvider from '@glass/web/modules/theme/ThemeProvider';
import createEmotionCache from '@glass/web/modules/theme/emotion/createEmotionCache';

// todo: figure out how to reduce the classnames further
ClassNameGenerator.configure((componentName) => componentName.replace('Mui', ''));

const withTheme = (createThemeSettings: Function) => (Component: any) => {
  function ComponentWithTheme({ emotionCache = createEmotionCache(), ...otherProps }: any) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = useMemo(() => createThemeSettings({ prefersDarkMode }), [prefersDarkMode]);
    const themeColor = process.env.NEXT_PUBLIC_THEME_COLOR || theme.palette?.primary.main;

    return (
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <ThemeColorHead color={themeColor} />
          <CssBaseline />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...otherProps} />
        </ThemeProvider>
      </CacheProvider>
    );
  }

  if (Component.getInitialProps) {
    ComponentWithTheme.getInitialProps = Component.getInitialProps;
  }

  return ComponentWithTheme;
};

export default withTheme;
