export const FIRST_NAME_FIELD_V2 = 'firstName';
export const LAST_NAME_FIELD_V2 = 'lastName';
export const LOCATION_FIELD_V2 = 'location';
export const CONTACT_FIELD_V2 = 'contact';
export const ZIP_CODE_FIELD_V2 = 'zip';
export const CITY_FIELD_V2 = 'city';
export const STATE_FIELD_V2 = 'state';
export const COUNTRY_FIELD_V2 = 'country';
export const STREET_FIELD_V2 = 'street';
export const RESUME_V2_FIELD = 'v2';
export const EXPERIENCES_FIELD_V2 = 'experiences';
export const EDUCATION_FIELD_V2 = 'educations';
export const ORGANIZATION_FIELD_V2 = 'organization';
export const TITLE_FIELD_V2 = 'title';
export const START_DATE_FIELD_V2 = 'startDate';
export const END_DATE_FIELD_V2 = 'endDate';
export const DETAILS_FIELD_V2 = 'details';
export const EMAIL_FIELD_V2 = 'email';
export const PHONE_FIELD_V2 = 'phone';
export const FIELD_OF_STUDY_FIELD_V2 = 'fieldOfStudy';
export const DEGREE_FIELD_V2 = 'degree';
export const SECTIONS_FIELD_V2 = 'sections';
export const NAME_FIELD_V2 = 'name';
export const TYPE_FIELD_V2 = 'type';
export const HIDDEN_FIELD_V2 = 'hidden';
export const PUBLIC_FIELD_V2 = 'public';
export const START_DATE_UTC_FIELD_V2 = 'startDateUTC';
export const END_DATE_UTC_FIELD_V2 = 'endDateUTC';

// metadata
export const RESUME_METADATA_FIELD = 'metadata';
export const RESUME_METADATA_COMPLETED_STEPS_FIELD = 'completedSteps';
export const RESUME_METADATA_BASE_PATH_FIELD = 'basePath';

// template
export const TEMPLATE_FIELD_V2 = 'template';

// separator
export const SEPARATOR_TYPE_FIELD = TYPE_FIELD_V2;
export const SEPARATOR_SIZE_FIELD = 'size';
export const SEPARATOR_POSITION_FIELD = 'position';
export const SEPARATOR_COLOR_FIELD = 'color';

// resume
export const X_MARGIN_FIELD = 'xMargin';
export const Y_MARGIN_FIELD = 'yMargin';
export const FONT_SIZE_FIELD = 'fontSize';
export const FONT_STYLE_FIELD = 'fontStyle';
export const FONT_WEIGHT_FIELD = 'fontWeight';
export const LINE_HEIGHT_FIELD = 'lineHeight';

export const BG_COLOR_FIELD = 'bgColor';
export const LIST_STYLE_BULLET = 'listStyleBullet';

export const PRIMARY_COLOR_FIELD = 'primaryColor';
export const SECONDARY_COLOR_FIELD = 'secondaryColor';
export const DATE_FORMAT_FIELD = 'dateFormat';
export const DATE_DELIMITER_FIELD = 'dateDelimiter';
export const FORMATTING_PRESET_FIELD = 'formattingPreset';

// header
export const HEADER_SPACING_FIELD = 'headerSpacing';
export const HEADER_ALIGNMENT_FIELD = 'headerAlignment';
export const HEADER_TOP_SEPARATOR_FIELD = 'headerTopSeparator';
export const HEADER_MIDDLE_SEPARATOR_FIELD = 'headerMiddleSeparator';
export const HEADER_BOTTOM_SEPARATOR_FIELD = 'headerBottomSeparator';

// embroidery
export const HEADER_EMBROIDERY_STYLE_FIELD = 'headerEmbroideryStyle';
export const HEADER_EMBROIDERY_COL_BREAK_FIELD = 'headerEmbroideryColBreak';

// contact
export const CONTACT_STYLE_FIELD = 'contactStyle';
export const CONTACT_LIST_STYLE_BULLET_FIELD = 'contactListStyleBullet';
export const CONTACT_SPACING_FIELD = 'contactSpacing';
export const CONTACT_PREFIX_FIELD = 'contactPrefix';
// TODO: move into highlight data
export const CONTACT_BG_COLOR_FIELD = 'contactBgColor';
export const CONTACT_ORDER_FIELD = 'contactOrder';

// sections
export const SECTION_STYLE_FIELD = 'sectionStyle';
export const HEADING_ALIGNMENT_FIELD = 'headingAlignment';

export const SECTION_SPACING_FIELD = 'sectionSpacing';
export const SECTION_SEPARATOR_FIELD = 'sectionSeparator';
// TODO: move into highlight data
export const SECTION_TITLE_BG_COLOR_FIELD = 'sectionTitleBgColor';
export const HEADING_COL_WIDTH_FIELD = 'headingColWidth';
export const SPLIT_LEFT_COL_WIDTH = 'splitLeftColWidth';

export const SECTION_TITLE_DELIMITER_FIELD = 'sectionTitleDelimiter';
export const SECTION_TITLE_JUSTIFY_CONTENT_FIELD = 'sectionTitleJustifyContent';
export const SECTION_TITLE_COL_WIDTH_FIELD = 'sectionTitleColWidth';
export const SECTION_COL_ID = 'sectionColId';
export const SECTION_DETAILS_INDENT_FIELD = 'sectionDetailsIndent';
export const SECTION_CONTENT_INDENT_FIELD = 'sectionContentIndent';
export const SECTION_OVERRIDES_FIELD = 'sectionOverrides';
export const SECTION_HIDE_HEADINGS = 'hideHeadings';
export const SECTION_DETAILS_COL_COUNT = 'detailsColCount';

export const HD_FIELD = 'highlightData';
export const HD_WEIGHT_FIELD = 'highlightDataWeight';
export const HD_CASING_FIELD = 'highlightDataCasing';
export const HD_COLOR_FIELD = 'highlightDataColor';
export const HD_SIZE_FIELD = 'highlightDataFontSize';
export const HD_BG_COLOR_FIELD = 'highlightDataBgColor';
export const HD_BORDER_FIELD = 'highlightDataBorder';

export const BORDER_FIELD = 'border';
export const BORDER_LEFT_FIELD = 'borderLeft';
export const BORDER_TOP_FIELD = 'borderTop';
export const BORDER_RIGHT_FIELD = 'borderRight';
export const BORDER_BOTTOM_FIELD = 'borderBottom';
export const BORDER_COLOR_FIELD = 'borderColor';
export const BORDER_RADIUS_FIELD = 'borderRadius';

export const HD_SECTION_TITLE = 'sectionTitle';
export const HD_SECTION_TITLE1 = 'sectionTitle1';
export const HD_SECTION_TITLE2 = 'sectionTitle2';
export const HD_SECTION_SUBHEADER = 'sectionSubheader';
export const HD_SECTION_SUBHEADER1 = 'sectionSubheader1';
export const HD_SECTION_SUBHEADER2 = 'sectionSubheader2';
export const HD_HEADER = 'header';
export const HD_HEADING = 'heading';
export const HD_FIRST_NAME = 'firstName';
export const HD_LAST_NAME = 'lastName';
export const HD_EMBROIDERY = 'embroidery';
export const HD_CONTACT = 'contact';
