import App from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';

import { WINDOW_REDUX_KEY } from '@glass/web/components/App/constants';

import createStore from '@glass/shared/modules/store/createStore';

let globalRedux;
const getOrCreateStore = (reducer, initialState, helpers, options) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (__SERVER__) {
    return createStore(reducer, initialState, helpers, options);
  }

  const redux = (__BROWSER__ && window?.[WINDOW_REDUX_KEY]) || globalRedux;

  // Create store if unavailable on the client and set it on the window object
  if (!redux) {
    globalRedux = createStore(reducer, initialState, helpers, options);
    if (__BROWSER__ && window) {
      window[WINDOW_REDUX_KEY] = globalRedux;
      return window[WINDOW_REDUX_KEY];
    }
  }

  return globalRedux;
};

const withRedux =
  (reducer, { ssr = false, helpers = {}, ...options } = {}) =>
  (PageComponent) => {
    // eslint-disable-next-line react/prop-types
    function WithRedux({ initialReduxState, cookies, client, router, ...props }) {
      const { store, persistor } = getOrCreateStore(
        reducer,
        initialReduxState,
        { ...helpers, cookies, client, router },
        options,
      );
      return (
        <Provider store={store}>
          <PageComponent
            {...props}
            client={client}
            cookies={cookies}
            persistor={persistor}
            router={router}
            store={store}
          />
        </Provider>
      );
    }

    // Make sure people don't use this HOC on _app.js level
    if (__DEV__) {
      const isAppHoc = PageComponent === App || PageComponent.prototype instanceof App;
      if (isAppHoc) {
        throw new Error('The withRedux HOC only works with PageComponents');
      }
      // Set the correct displayName in development
      const displayName = PageComponent.displayName || PageComponent.name || 'Component';
      WithRedux.displayName = `withRedux(${displayName})`;
    }

    if (ssr || PageComponent.getInitialProps) {
      // We consider installing `withRedux({ ssr: true })` on global App level
      // as antipattern since it disables project wide Automatic Static Optimization.
      if (__DEV__) {
        if (!PageComponent.getInitialProps) {
          console.warn(
            'Warning: You have opted-out of Automatic Static Optimization due to `withApollo` in `pages/_app`.\n' +
              'Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n',
          );
        }
      }

      WithRedux.getInitialProps = async (context) => {
        const { client, cookies } = context;
        // Get or Create the store with `undefined` as initialState
        // This allows you to set a custom default initialState

        // Provide the store to getInitialProps of pages
        // eslint-disable-next-line no-param-reassign
        const { store, persistor } = getOrCreateStore(
          reducer,
          {},
          { ...helpers, client, cookies },
          options,
        );

        // eslint-disable-next-line no-param-reassign
        context.ctx.store = store;
        // eslint-disable-next-line no-param-reassign
        context.ctx.persistor = persistor;

        // Run getInitialProps from HOCed PageComponent
        const pageProps =
          typeof PageComponent.getInitialProps === 'function'
            ? await PageComponent.getInitialProps(context)
            : {};

        // Pass props to PageComponent
        return {
          ...pageProps,
          initialReduxState: store.getState(),
        };
      };
    }

    return WithRedux;
  };

export default withRedux;
