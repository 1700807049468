export const RETARGET_EMAIL_FREQUENCY_INTERVAL = 'RETARGET_EMAIL_FREQUENCY_INTERVAL';

export default {
  name: RETARGET_EMAIL_FREQUENCY_INTERVAL,
  variations: [
    {
      value: '16h',
      weight: 1,
    },
    {
      value: '1m',
      weight: 0,
    },
    {
      value: '5s',
      weight: 0,
    },
  ],
};
