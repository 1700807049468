export type ValueObject<T> = { value: T; weight?: number };

export type ValueType<T> = ValueObject<T> | T | string | number | (T | string | number)[];

export const getWeight = <T>(item: ValueType<T>): number => {
  if (item && typeof item === 'object' && 'weight' in item && typeof item.weight === 'number') {
    return item.weight;
  }

  return 1;
};

export const calcArrWeight = <T>(arr: ValueType<T>[]) =>
  arr.reduce<number>((agg, itm) => agg + getWeight(itm), 0);

export const getValue = <T>(item?: ValueType<T> | null): T => {
  if (item && typeof item === 'object' && 'value' in item) {
    return item.value;
  }
  return item as T;
};

export const getArrayItemFromFloat = <T>(
  arr: ValueType<T>[],
  float: number,
): ValueType<T> | undefined => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return undefined;
  }

  if (arr.length === 1) {
    return arr[0];
  }

  const totalWeight = calcArrWeight(arr);
  const cutoffWeight = float * totalWeight;
  let currentWeight = 0;
  return arr.find((itm) => {
    const weight = getWeight(itm);
    currentWeight += weight;
    return currentWeight >= cutoffWeight;
  });
};

const getArrayValueFromFloat = <T>(arr: ValueType<T>[], float: number) =>
  getValue(getArrayItemFromFloat<T>(arr, float));

export default getArrayValueFromFloat;
