import { GOOGLE_DISPLAY_ADS_COOKIE_NAME } from '@glass/web/modules/cookies/names';

import findCookieValue from '@glass/shared/helpers/findCookieValue';
import findObjectValue from '@glass/shared/helpers/findObjectValue';

const QUERY_PARAMS = ['dclid'];
const COOKIE_KEYS = [GOOGLE_DISPLAY_ADS_COOKIE_NAME];

const getGoogleDisplayId = ({ cookies, query = {} }) =>
  findObjectValue(query, QUERY_PARAMS) || findCookieValue(cookies, COOKIE_KEYS);

export default getGoogleDisplayId;
