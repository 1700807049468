import { RootState } from '@glass/web/modules/redux/types';
import type { PayloadAction } from '@glass/web/modules/redux/types';
import { BreakpointTypes } from '@glass/web/modules/theme/breakpointKeys';

export const OPEN_GLOBAL_DIALOG = 'OPEN_GLOBAL_DIALOG';
export const RESET_GLOBAL_DIALOG = 'RESET_GLOBAL_DIALOG';
export const EXTEND_GLOBAL_DIALOG = 'EXTEND_GLOBAL_DIALOG';

export const GLOBAL_DIALOG_REDUCER_KEY = 'globalDialog';

export type GlobalDialogState = {
  open: boolean;
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  confirmLoading?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  maxWidth?: BreakpointTypes;
  children: React.ReactNode;
};

const initialState: GlobalDialogState = {
  open: false,
  children: null,
};

export default function globalDialog(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: GlobalDialogState = initialState,
  action: PayloadAction<GlobalDialogState>,
): GlobalDialogState {
  switch (action.type) {
    case OPEN_GLOBAL_DIALOG:
      return {
        ...action.payload,
        open: true,
      };
    case RESET_GLOBAL_DIALOG:
      return {
        ...initialState,
      };
    case EXTEND_GLOBAL_DIALOG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const selectGlobalDialogProps = (state: RootState) => state[GLOBAL_DIALOG_REDUCER_KEY];
export const selectIsGlobalDialogOpen = (state: RootState) => selectGlobalDialogProps(state).open;
