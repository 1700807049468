import setAppLocaleAction from '@glass/web/modules/app/setAppLocaleAction';
import { AppDispatch, AppGetState, AppHelpers } from '@glass/web/modules/redux/types';

const initAppLocaleAction =
  () =>
  (dispatch: AppDispatch, getState: AppGetState, { cookies, router }: AppHelpers) => {
    const locale = router?.locale || cookies?.locale;
    return dispatch(setAppLocaleAction(locale));
  };

export default initAppLocaleAction;
