const getArrayReducerKeyDefaults = (
  key,
  {
    isUniq: isUniqProp,
    uniqKey: defaultUniqKey,
    maxItems: defaultMaxItems,
    itemSettings: {
      [key]: { uniqKey = defaultUniqKey, maxItems = defaultMaxItems, isUniq = isUniqProp } = {},
    } = {},
  } = {},
) => ({ uniqKey, maxItems, isUniq });

export default getArrayReducerKeyDefaults;
