import { ApolloProvider } from '@apollo/client';
import App from 'next/app';
import React from 'react';

import createApolloClient from '@glass/shared/modules/apollo/createApolloClient';

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {import('@apollo/client').NormalizedCacheObject} initialState
 * @param  {import('next').NextPageContext} ctx
 * @param  {Record<string,unknown>} options
 */
const initApolloClient = (initialState, ctx, options) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (__SERVER__) {
    return createApolloClient(initialState, ctx, options);
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(initialState, ctx, options);
  }

  return globalApolloClient;
};

/**
 * Installs the Apollo Client on NextPageContext
 * or NextAppContext. Useful if you want to use apolloClient
 * inside getStaticProps, getStaticPaths or getServerSideProps
 * @param {import('next').NextPageContext} ctx
 */
export const initOnContext = (ctx, options) => {
  const inAppContext = Boolean(ctx.ctx);

  // Initialize ApolloClient if not already done
  const apolloClient =
    ctx.client || initApolloClient(ctx.apolloState || {}, inAppContext ? ctx.ctx : ctx, options);

  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  apolloClient.toJSON = () => null;

  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.client = apolloClient;
  if (inAppContext) {
    ctx.ctx.client = apolloClient;
  }

  return ctx;
};

/**
 * Creates a withApollo HOC
 * that provides the apolloContext
 * to a next.js Page or AppTree.
 * @param  {Object} withApolloOptions
 * @param  {Boolean} [withApolloOptions.ssr=false]
 * @returns {(PageComponent: ReactNode) => ReactNode}
 */
const withApollo =
  ({ ssr = false, i18nConfig, memoryCacheOptions } = {}) =>
  (PageComponent) => {
    // eslint-disable-next-line react/prop-types
    function WithApollo({ client, apolloState, router, ...pageProps }) {
      let apolloClient;
      if (client) {
        // Happens on: getDataFromTree & next.js ssr
        apolloClient = client;
      } else {
        // Happens on: next.js csr
        apolloClient = initApolloClient(
          apolloState,
          { router },
          { i18nConfig, memoryCacheOptions },
        );
      }

      return (
        <ApolloProvider client={apolloClient}>
          <PageComponent {...pageProps} client={apolloClient} router={router} />
        </ApolloProvider>
      );
    }

    // Set the correct displayName in development
    if (__DEV__) {
      const displayName = PageComponent.displayName || PageComponent.name || 'Component';
      WithApollo.displayName = `withApollo(${displayName})`;
    }

    if (ssr || PageComponent.getInitialProps) {
      // We consider installing `withApollo({ ssr: true })` on global App level
      // as antipattern since it disables project wide Automatic Static Optimization.
      if (__DEV__) {
        if (!PageComponent.getInitialProps) {
          console.warn(
            'Warning: You have opted-out of Automatic Static Optimization due to `withApollo` in `pages/_app`.\n' +
              'Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n',
          );
        }
      }

      WithApollo.getInitialProps = async (ctx) => {
        const inAppContext = Boolean(ctx.ctx);
        const { client } = initOnContext(ctx, { i18nConfig });

        // Run wrapped getInitialProps methods
        let pageProps = {};
        if (PageComponent.getInitialProps) {
          pageProps = await PageComponent.getInitialProps(ctx);
        } else if (inAppContext) {
          pageProps = await App.getInitialProps(ctx);
        }

        // Only on the server:
        if (__SERVER__) {
          const { AppTree } = ctx;
          // When redirecting, the response is finished.
          // No point in continuing to render
          if (ctx.res?.finished) {
            return pageProps;
          }
          // Only if dataFromTree is enabled
          if (ssr && AppTree) {
            try {
              // Import `@apollo/react-ssr` dynamically.
              // We don't want to have this in our client bundle.
              const { getDataFromTree } = await import('@apollo/client/react/ssr');

              // Since AppComponents and PageComponents have different context types
              // we need to modify their props a little.
              let props;
              if (inAppContext) {
                props = { ...pageProps, client };
              } else {
                props = { pageProps: { ...pageProps, client } };
              }

              // Take the Next.js AppTree, determine which queries are needed to render,
              // and fetch them. This method can be pretty slow since it renders
              // your entire AppTree once for every query. Check out apollo fragments
              // if you want to reduce the number of rerenders.
              // https://www.apollographql.com/docs/react/data/fragments/
              await getDataFromTree(<AppTree {...props} />);
            } catch (error) {
              // Prevent Apollo Client GraphQL errors from crashing SSR.
              // Handle them in components via the data.error prop:
              // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
              console.error('Error while running `getDataFromTree`', error);
            }
          }
        }

        return {
          ...pageProps,
          // Extract query data from the Apollo store
          apolloState: client.cache.extract(),
          // Provide the client for ssr. As soon as this payload
          // gets JSON.stringified it will remove itself.
          apolloClient: ctx.apolloClient,
        };
      };
    }

    return WithApollo;
  };

export default withApollo;
