import { currentOrigin } from '@glass/env/modules/origins/constants';

const pathToURL = (path: string | null, parseQuery = false) => {
  const url = new URL(`${currentOrigin}${path ?? ''}`);

  let query = null;

  if (parseQuery) {
    query = [...url.searchParams].reduce(
      (params, [key, value]) => ({ ...params, [key]: value }),
      {},
    );
  }

  return { url, query };
};

export default pathToURL;
