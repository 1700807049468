const getArrayReducerKeyItems = (key, state, action) => {
  const { [key]: items } = action.payload;
  const { [key]: existingItems } = state;

  if (!Array.isArray(items) || !Array.isArray(existingItems)) {
    throw new Error(`invalid push items: ${JSON.stringify({ key, items })}`);
  }

  return {
    items,
    existingItems,
  };
};

export default getArrayReducerKeyItems;
