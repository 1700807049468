import PropTypes from 'prop-types';
import React from 'react';

import TrackingContext from '@glass/shared/components/App/context/TrackingContext';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  componentDidCatch(error) {
    const tracking = this.context;
    tracking.exception(error);
    if (__DEV__) {
      console.error(error);
    }
  }

  render() {
    const { fallback: Fallback, children, ...otherProps } = this.props;
    const { error } = this.state;
    if (!error) {
      return children;
    }
    if (!Fallback) {
      if (__DEV__) {
        return <pre>{JSON.stringify({ message: error.message, stack: error.stack }, null, 2)}</pre>;
      }
      return null;
    }
    return <Fallback {...otherProps} error={error} />;
  }
}

ErrorBoundary.contextType = TrackingContext;

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.elementType,
};

export default ErrorBoundary;
