import { uniq } from 'lodash';

import { CurrencyCode, CurrencyConfig } from '@glass/common/modules/payments/currencies/types';
import persistReducer from '@glass/web/modules/redux/persistReducer';
import type { PayloadAction, RootState } from '@glass/web/modules/redux/types';
import createSelectReducerRehydrated from '@glass/web/modules/store/createSelectReducerRehydrated';

export const CURRENCIES_REDUCER_KEY = 'currencies';

export const SET_DEFAULT_CURRENCY = 'SET_DEFAULT_CURRENCY';
export const SET_SELECTED_CURRENCY = 'SET_SELECTED_CURRENCY';
export const SET_SUPPORTED_CURRENCIES = 'SET_SUPPORTED_CURRENCIES';
export const ADD_ERROR_CURRENCIES = 'ADD_ERROR_CURRENCIES';
export const SET_EXCLUSIVE_CURRENCY = 'SET_EXCLUSIVE_CURRENCY';
export const ADD_PRIORITY_CURRENCIES = 'ADD_PRIORITY_CURRENCIES';

export type CurrencyState = {
  // this is when there is a currency that is exclusive to the user, don't show the currency selector
  exclusiveCurrency?: CurrencyCode | null;
  // this is the currency the user has selected
  selectedCurrency: CurrencyCode | null;
  // this is the locale's default currency
  defaultCurrency: CurrencyConfig | null;
  // these are currencies we think the user might be interested in
  priorityCurrencies: CurrencyCode[];
  // this is the currencies that we support
  supportedCurrencies: CurrencyConfig[];
  // these are currencies that have errored for the user
  errorCurrencies: CurrencyCode[];
  _persist?: {
    rehydrated: boolean;
  };
};

const initialState: CurrencyState = {
  exclusiveCurrency: null,
  defaultCurrency: null,
  priorityCurrencies: [],
  selectedCurrency: null,
  supportedCurrencies: [],
  errorCurrencies: [],
};

function currenciesReducer(
  state: CurrencyState = initialState,
  action: PayloadAction<CurrencyState>,
): CurrencyState {
  switch (action.type) {
    case SET_DEFAULT_CURRENCY:
      return {
        ...state,
        defaultCurrency: action.payload.defaultCurrency,
      };
    case ADD_PRIORITY_CURRENCIES:
      return {
        ...state,
        priorityCurrencies: uniq([
          ...action.payload.priorityCurrencies,
          ...state.priorityCurrencies,
        ]),
      };
    case SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency:
          !state.supportedCurrencies.length ||
          state.supportedCurrencies.some(({ code }) => code === action.payload.selectedCurrency)
            ? action.payload.selectedCurrency
            : null,
      };
    case SET_EXCLUSIVE_CURRENCY:
      return {
        ...state,
        exclusiveCurrency:
          !state.supportedCurrencies.length ||
          state.supportedCurrencies.some(({ code }) => code === action.payload.exclusiveCurrency)
            ? action.payload.exclusiveCurrency
            : null,
      };
    case SET_SUPPORTED_CURRENCIES:
      return {
        ...state,
        exclusiveCurrency: action.payload.supportedCurrencies.some(
          ({ code }) => code === state.exclusiveCurrency,
        )
          ? state.exclusiveCurrency
          : null,
        selectedCurrency: action.payload.supportedCurrencies.some(
          ({ code }) => code === state.selectedCurrency,
        )
          ? state.selectedCurrency
          : null,
        supportedCurrencies: [...action.payload.supportedCurrencies].sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      };
    case ADD_ERROR_CURRENCIES:
      return {
        ...state,
        priorityCurrencies: state.priorityCurrencies.filter(
          (c) => !action.payload.errorCurrencies.includes(c),
        ),
        supportedCurrencies: state.supportedCurrencies.filter(
          ({ code }) => !action.payload.errorCurrencies.includes(code),
        ),
        selectedCurrency:
          state.selectedCurrency && action.payload.errorCurrencies.includes(state.selectedCurrency)
            ? null
            : state.selectedCurrency,
        errorCurrencies: uniq([...action.payload.errorCurrencies, ...state.errorCurrencies]),
      };
    default:
      return state;
  }
}

export const selectIsCurrenciesReducerHydrated =
  createSelectReducerRehydrated(CURRENCIES_REDUCER_KEY);

export const selectSupportedCurrencies = (globalState: RootState) =>
  globalState[CURRENCIES_REDUCER_KEY].supportedCurrencies;

export const selectPriorityCurrencies = (globalState: RootState) =>
  globalState[CURRENCIES_REDUCER_KEY].priorityCurrencies;

export const selectExclusiveCurrency = (globalState: RootState) =>
  globalState[CURRENCIES_REDUCER_KEY].exclusiveCurrency;

export const selectCurrency = (globalState: RootState): CurrencyCode | null => {
  const { selectedCurrency, exclusiveCurrency, priorityCurrencies, supportedCurrencies } =
    globalState[CURRENCIES_REDUCER_KEY];

  if (!supportedCurrencies.length) {
    return null;
  }

  return (
    [exclusiveCurrency, selectedCurrency].find(Boolean) ||
    priorityCurrencies.find((cur) => cur && supportedCurrencies.some(({ code }) => code === cur)) ||
    null
  );
};

export const selectIsCurrencySelector = (globalState: RootState) => {
  const { exclusiveCurrency, selectedCurrency, supportedCurrencies } =
    globalState[CURRENCIES_REDUCER_KEY];

  if (exclusiveCurrency || supportedCurrencies.length < 2) {
    return false;
  }

  if (selectedCurrency) {
    return true;
  }

  const priorityCurrencies = selectPriorityCurrencies(globalState);
  if (!priorityCurrencies.length) {
    return false;
  }

  const currency = selectCurrency(globalState);
  if (!currency) {
    return false;
  }

  return priorityCurrencies.some((c) => c !== currency);
};

export const setSelectedCurrency = (selectedCurrency: CurrencyCode) => ({
  type: SET_SELECTED_CURRENCY,
  payload: { selectedCurrency },
});

export const setExclusiveCurrency = (exclusiveCurrency: CurrencyCode | null) => ({
  type: SET_EXCLUSIVE_CURRENCY,
  payload: { exclusiveCurrency },
});

export const setDefaultCurrency = (defaultCurrency: CurrencyConfig) => ({
  type: SET_DEFAULT_CURRENCY,
  payload: { defaultCurrency },
});

export const addPriorityCurrencies = (priorityCurrencies: CurrencyCode[]) => ({
  type: ADD_PRIORITY_CURRENCIES,
  payload: { priorityCurrencies },
});

export const setSupportedCurrencies = (supportedCurrencies: CurrencyConfig[]) => ({
  type: SET_SUPPORTED_CURRENCIES,
  payload: { supportedCurrencies },
});

export const addErrorCurrencies = (errorCurrencies: CurrencyCode[]) => ({
  type: ADD_ERROR_CURRENCIES,
  payload: { errorCurrencies },
});

const persistConfig = {
  key: CURRENCIES_REDUCER_KEY,
  version: 3,
  whitelist: ['selectedCurrency'],
};

export default persistReducer(persistConfig, currenciesReducer);
