import makeArray from '@glass/common/modules/utils/makeArray';

const removeObjKeys = <T extends Record<string, any>>(obj: T, keys: string[]) => {
  if (!keys || !obj) {
    return obj;
  }
  // make it an array
  const omitKey = (key: string, value: any) =>
    makeArray<string>(keys).includes(key) ? undefined : value;
  return JSON.parse(JSON.stringify(obj), omitKey) as Partial<T>;
};

export default removeObjKeys;
