import findState from '@glass/common/modules/geo/findState';
import getResumeContactState from '@glass/common/modules/resumes/selectors/getResumeContactState';
import { selectUserProfile } from '@glass/web/modules/auth';
import { selectResumeValue } from '@glass/web/modules/resumes/selectors';
import selectGeoSubdivision from '@glass/web/modules/userConfig/selectors/selectGeoSubdivision';

import selectPaymentFormState from '@glass/shared/modules/payments/selectors/selectPaymentFormState';
import selectUserCountryCode from '@glass/shared/modules/userConfig/selectors/selectUserCountryCode';

const selectUserStateCode = (reduxState) => {
  const countryCode = selectUserCountryCode(reduxState);

  const resume = selectResumeValue(reduxState);
  const resumeStateCode = findState(getResumeContactState(resume), countryCode)?.code;

  if (resumeStateCode) {
    return resumeStateCode;
  }

  const paymentState = selectPaymentFormState(reduxState);
  const paymentStateCode = findState(paymentState, countryCode)?.code;

  if (paymentStateCode) {
    return paymentStateCode;
  }

  const { state: profileState } = selectUserProfile(reduxState) || {};
  const profileStateCode = findState(profileState, countryCode)?.code;

  if (profileStateCode) {
    return profileState;
  }
  const geoStateCode = selectGeoSubdivision(reduxState)?.isoCode || null;

  return findState(geoStateCode, countryCode)?.code || null;
};

export default selectUserStateCode;
