import { createSelectAssignment, selectAssignments } from '@glass/common/modules/assignments/redux';
import { formatTitle } from '@glass/common/modules/utils/printTitle';
import { RootState } from '@glass/web/modules/redux/types';
import selectUserConfig from '@glass/web/modules/userConfig/selectors/selectUserConfig';

const logAssignments = (state: RootState) => {
  const assignments = selectAssignments(state);
  const keys = Object.keys(assignments);
  if (keys.length) {
    console.info(formatTitle('Client Assignments'));
    console.info(`assignmentId: ${selectUserConfig(state)?.assignmentId}`);
    keys.sort().forEach((name) => {
      console.info(`${name}: ${JSON.stringify(createSelectAssignment(name)(state))}`);
    });
  }
};

export default logAssignments;
