export const ROOT_STEP = '_root';
export const INTRO_STEP = 'intro';
export const EDIT_STEP = 'edit';
export const DETAILS_STEP = 'details';
export const SUMMARY_STEP = 'summary';

export type Step =
  | typeof INTRO_STEP
  | typeof EDIT_STEP
  | typeof DETAILS_STEP
  | typeof SUMMARY_STEP
  | typeof ROOT_STEP;
