import { COMMA_SPACE_DELIMITER } from '@glass/common/modules/strings/constants';
import isValue from '@glass/common/modules/utils/isValue';

const filterJoin = (arr: Array<unknown>, delimiter: string = COMMA_SPACE_DELIMITER): string =>
  arr.reduce((acc: string, val: unknown) => {
    if (!isValue(val) || (typeof val !== 'string' && typeof val !== 'number')) {
      return acc;
    }
    return `${acc}${acc ? delimiter : ''}${val}`;
  }, '');

export default filterJoin;
