import { MASTER, RELEASE } from '@glass/common/modules/environments/branches';
import {
  CHEETAH_BRAND,
  LAMBO_BRAND,
  RAPTOR_BRAND,
  ROCK_BRAND,
  TAXONOMY_BRAND,
} from '@glass/common/modules/environments/brands';
import createBrandOrigin from '@glass/env/modules/origins/createBrandOrigin';
import createVercelOrigin from '@glass/env/modules/origins/createVercelOrigin';

export const cheetahMasterOrigin = createBrandOrigin({ branch: MASTER, brand: CHEETAH_BRAND });
export const defaultProductionOrigin =
  createBrandOrigin({
    brand: ROCK_BRAND,
    branch: RELEASE,
  }) || '';

// this won't work if env vars aren't set
export const currentOrigin =
  process.env.NEXT_PUBLIC_ORIGIN ||
  createBrandOrigin() ||
  (process.env.NEXT_PUBLIC_VERCEL_URL
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : `http://localhost:${process.env.PORT || process.env.NEXT_PUBLIC_PORT || 3000}`);

export const rockOrigin =
  process.env.NEXT_PUBLIC_ROCK_ORIGIN ||
  process.env.ROCK_ORIGIN ||
  createBrandOrigin({ brand: ROCK_BRAND }) ||
  createVercelOrigin({ brand: ROCK_BRAND }) ||
  '';

export const lamboOrigin =
  process.env.NEXT_PUBLIC_LAMBO_ORIGIN ||
  process.env.LAMBO_ORIGIN ||
  createBrandOrigin({ brand: LAMBO_BRAND }) ||
  createVercelOrigin({ brand: LAMBO_BRAND }) ||
  '';

export const raptorOrigin =
  process.env.NEXT_PUBLIC_RAPTOR_ORIGIN ||
  process.env.RAPTOR_ORIGIN ||
  createBrandOrigin({ brand: RAPTOR_BRAND }) ||
  createVercelOrigin({ brand: RAPTOR_BRAND }) ||
  '';

export const cheetahOrigin =
  process.env.NEXT_PUBLIC_CHEETAH_ORIGIN ||
  process.env.CHEETAH_ORIGIN ||
  createBrandOrigin({ brand: CHEETAH_BRAND }) ||
  createVercelOrigin({ brand: CHEETAH_BRAND }) ||
  '';

export const taxonomyOrigin =
  process.env.NEXT_PUBLIC_TAXONOMY_ORIGIN ||
  process.env.TAXONOMY_ORIGIN ||
  createBrandOrigin({ brand: TAXONOMY_BRAND }) ||
  createVercelOrigin({ brand: CHEETAH_BRAND }) ||
  '';
