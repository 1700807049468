import pricing from '@glass/rock/modules/payments/pricings/configs/assignments.config';

import cancel from '@glass/common/modules/assignments/configs/cancel';
import contentApiV2 from '@glass/common/modules/assignments/configs/contentApiV2';
import currencySelector from '@glass/common/modules/assignments/configs/currencySelector';
import disableBuilderQueryCheck from '@glass/common/modules/assignments/configs/disableBuilderQueryCheck';
import retargetEmailFrequencyInterval from '@glass/common/modules/assignments/configs/retargetEmailFrequencyInterval';
import retargetEmailProvider from '@glass/common/modules/assignments/configs/retargetEmailProvider';
import retargetEmailSendTimeOptimization from '@glass/common/modules/assignments/configs/retargetEmailSendTimeOptimization';
import createAssignmentConfigs from '@glass/common/modules/assignments/createAssignmentConfigs';
import { AssignmentSetting } from '@glass/common/modules/assignments/types';

export const userAssignments: AssignmentSetting[] = [
  cancel,
  disableBuilderQueryCheck,
  ...pricing,
  retargetEmailProvider,
  retargetEmailFrequencyInterval,
  retargetEmailSendTimeOptimization,
  currencySelector,
  contentApiV2,
];

const configs = createAssignmentConfigs(userAssignments);

export default configs;
