import { RootState } from '@glass/web/modules/redux/types';
import selectGeoAccuracyRadius from '@glass/web/modules/userConfig/selectors/selectGeoAccuracyRadius';
import selectUserConfig from '@glass/web/modules/userConfig/selectors/selectUserConfig';

const CITY_ACCURACY_RADIUS_CUTOFF = 200;

const selectGeoCity = (state: RootState) => {
  const accuracyRadius = selectGeoAccuracyRadius(state);

  if (!accuracyRadius || accuracyRadius > CITY_ACCURACY_RADIUS_CUTOFF) {
    return null;
  }

  return selectUserConfig(state)?.geo?.city;
};

export default selectGeoCity;
