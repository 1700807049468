import { OPEN_GLOBAL_DIALOG } from '@glass/web/modules/globalDialog';
import { BreakpointTypes } from '@glass/web/modules/theme/breakpointKeys';

type Payload = {
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  maxWidth?: BreakpointTypes;
  children: React.ReactNode;
};

const openGlobalDialogAction = (payload: Payload) => ({
  type: OPEN_GLOBAL_DIALOG,
  payload,
});

export default openGlobalDialogAction;
