import { red, blue, amber } from '@glass/web/modules/theme/colors';
import commonTheme from '@glass/web/modules/theme/common';
import { ThemeProps } from '@glass/web/modules/theme/types';

export const SPACING_UNIT = 12;
const BUTTON_FONT_WEIGHT = 600;

const rockTheme: ThemeProps = {
  ...commonTheme,
  muiTheme: {
    ...commonTheme?.muiTheme,
    palette: {
      ...commonTheme?.muiTheme?.palette,
      primary: red,
      secondary: blue,
      accent: amber,
    },
    typography: {
      ...commonTheme?.muiTheme?.typography,
      h1: {
        fontWeight: 400,
        fontSize: '5.8rem',
      },
    },
    spacing: SPACING_UNIT,
    components: {
      ...commonTheme?.muiTheme?.components,
      MuiFab: {
        styleOverrides: {
          root: {
            zIndex: 'auto',
            fontWeight: BUTTON_FONT_WEIGHT,
          },
        },
      },
      MuiButton: {
        ...commonTheme?.muiTheme?.components?.MuiButton,
        styleOverrides: {
          root: {
            fontWeight: BUTTON_FONT_WEIGHT,
            // Fix for style color in Safari (v^14.1) when toggling button disabled/enabled state
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      },
      // MUI 4 compatibility
      // https://mui.com/material-ui/migration/v5-component-changes/#%E2%9C%85-update-default-underline-prop
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      // MUI 4 compatibility
      // https://mui.com/material-ui/migration/v5-component-changes/#change-dark-mode-background-opacity
      MuiPaper: {
        styleOverrides: { root: { backgroundImage: 'unset' } },
      },
    },
  },
  usePrefersDarkMode: false,
};

export default rockTheme;
