import setupRequestIdleCallbackShim from '@glass/web/modules/loading/setupRequestIdleCallbackShim';

export const PRIORITY_ASSETS_LOADED_EVENT = 'priority-assets-loaded';
export const PRIORITY_ASSETS_LOADED_WINDOW_KEY = '__priority_assets_loaded__';

const TIMEOUT = 30000;
const QUERY = 'img[fetchpriority="high"]';

setupRequestIdleCallbackShim();

// todo this only currently works with images with fetchpriority="high"
function checkPriorityAssets(timeout?: number): void {
  if (typeof window === 'undefined') {
    return;
  }

  let priorityAssets: NodeListOf<HTMLImageElement> = document.querySelectorAll(QUERY);
  let loadedAssetsCount: number = 0;
  let timeoutId: NodeJS.Timeout;

  const dispatchEvent = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // @ts-ignore
    window[PRIORITY_ASSETS_LOADED_WINDOW_KEY] = true;
    window.dispatchEvent(new CustomEvent(PRIORITY_ASSETS_LOADED_EVENT));
  };

  if (priorityAssets.length === 0) {
    // If there's no priority images, immediately dispatch the event
    dispatchEvent();
    return;
  }

  timeoutId = setTimeout(dispatchEvent, timeout ?? TIMEOUT);

  const handleLoad = (): void => {
    loadedAssetsCount++;

    if (loadedAssetsCount === priorityAssets.length) {
      // When all priority images are loaded, dispatch the event
      dispatchEvent();
    }
  };

  priorityAssets.forEach((element: HTMLImageElement) => {
    if (element.complete) {
      // The image is already loaded
      handleLoad();
    } else {
      // Listen for the load event
      element.addEventListener('load', handleLoad);
    }
  });

  if (process.env.NEXT_PUBLIC_DEBUG === 'priority-assets') {
    console.info(`${priorityAssets.length} priority assets setup`, priorityAssets);
  }
}

const setupHighPriorityAssetEvent = (timeout: number): void => {
  if (
    typeof window !== 'undefined' &&
    // @ts-ignore
    typeof window[PRIORITY_ASSETS_LOADED_WINDOW_KEY] === 'undefined'
  ) {
    // @ts-ignore
    window[PRIORITY_ASSETS_LOADED_WINDOW_KEY] = false;
    window.addEventListener('DOMContentLoaded', () => checkPriorityAssets(timeout));
  }
};

if (process.env.NEXT_PUBLIC_DEBUG === 'priority-assets' && typeof window !== 'undefined') {
  window.addEventListener(
    PRIORITY_ASSETS_LOADED_EVENT,
    () => {
      console.info('Priority assets loaded');
    },
    { once: true },
  );
}

export default setupHighPriorityAssetEvent;
