import Head from 'next/head';
import React from 'react';

const MS_THEME_COLOR = 'msapplication-TileColor';
const THEME_COLOR = 'theme-color';

type ThemeColorHeadProps = {
  color?: string;
};

/**
 * ThemeColorHead Component to set the theme color meta tags.
 * @param {string} color - The theme color
 * @returns ThemeColorHead Component
 */
export default function ThemeColorHead({ color }: ThemeColorHeadProps) {
  if (!color) return null;

  return (
    <Head>
      <meta key={MS_THEME_COLOR} name={MS_THEME_COLOR} content={color} />
      <meta key={THEME_COLOR} name={THEME_COLOR} content={color} />
    </Head>
  );
}
