import Tracking from '@glass/shared/modules/tracking/Tracking';

let globalTracking;

const WINDOW_TRACKING_KEY = '_TRACKING_';

const setTracking = (tracking) => {
  globalTracking = tracking;
  if (__BROWSER__ && window) {
    window[WINDOW_TRACKING_KEY] = globalTracking;
  }
  return globalTracking;
};

const getTracking = () => {
  const tracking = (__BROWSER__ && window?.[WINDOW_TRACKING_KEY]) || globalTracking;
  if (!tracking) {
    return setTracking(new Tracking());
  }
  return tracking;
};

export default getTracking();
