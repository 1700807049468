let intervalId: ReturnType<typeof setInterval> | null = null;

type CallbackFunction = (val: { oldValue: string; newValue: string; count: number }) => void;

type Settings = {
  interval?: number;
  timeout?: number;
};

let count: number = 0;

export function stopCookieChangePolling() {
  if (!intervalId) {
    return;
  }
  clearInterval(intervalId);
  intervalId = null;
  if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
    console.info('Cookie polling stopped');
  }
}

export function setupCookieChangePolling(
  callback: CallbackFunction,
  { interval = 1000, timeout }: Settings,
) {
  let lastCookie = document.cookie;

  intervalId = setInterval(() => {
    count += 1;
    const { cookie } = document;
    if (cookie !== lastCookie) {
      if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
        console.info('Cookie changed', { oldValue: lastCookie, newValue: cookie, count });
      }
      try {
        callback({ oldValue: lastCookie, newValue: cookie, count });
      } finally {
        lastCookie = cookie;
      }
    }
    if (timeout && interval * count > timeout) {
      stopCookieChangePolling();
    }
  }, interval);
  if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
    console.info('Cookie polling started');
  }
}
