export const ONE_SEC_MS = 1000;

export const ONE_MINUTE_SEC = 60;
export const ONE_MINUTE_MS = ONE_MINUTE_SEC * ONE_SEC_MS;

export const ONE_HOUR_SEC = 60 * ONE_MINUTE_SEC;
export const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;

export const ONE_DAY_SEC = 24 * ONE_HOUR_SEC;
export const ONE_DAY_MS = 24 * ONE_HOUR_MS;

export const ONE_WEEK_SEC = 7 * ONE_DAY_SEC;
export const ONE_WEEK_MS = 7 * ONE_DAY_MS;

export const ONE_MONTH_SEC = 30 * ONE_DAY_SEC;
export const ONE_MONTH_MS = 30 * ONE_DAY_MS;

export const ONE_YEAR_SEC = 365 * ONE_DAY_SEC;
export const ONE_YEAR_MS = 365 * ONE_DAY_MS;

export const MAX_COUNTDOWN_SEC = 2 * ONE_HOUR_SEC;
