import { HttpLink } from '@apollo/client';

const createHttpLink = ({ uri, headers, credentials = 'same-origin' } = {}) => {
  return new HttpLink({
    uri,
    credentials,
    fetch,
    headers,
  });
};

export default createHttpLink;
