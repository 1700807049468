export const SET_RESUME_BUILDER_BASE_PATH = 'SET_RESUME_BUILDER_BASE_PATH';

const setResumeBuilderBasePath = (resumeBuilderBasePath: string) => ({
  type: SET_RESUME_BUILDER_BASE_PATH,
  payload: {
    resumeBuilderBasePath,
  },
});

export default setResumeBuilderBasePath;
