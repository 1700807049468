import React from 'react';

import { selectIsGlobalDialogOpen } from '@glass/web/modules/globalDialog';
import openGlobalDialogAction from '@glass/web/modules/globalDialog/openGlobalDialogAction';
import { XS } from '@glass/web/modules/theme/breakpointKeys';

// eslint-disable-next-line import/no-cycle
import LazyAuthDialog from '@glass/shared/components/Auth/LazyAuthDialog';
import { selectAuthProps } from '@glass/shared/modules/authForm';
import extendAuthProps from '@glass/shared/modules/authForm/extendAuthProps';
import Tracking from '@glass/shared/modules/tracking/Tracking';

const promptAuthAction =
  ({ onExit, inDialog: optionsInDialog, ...otherOptions }) =>
  (dispatch, getState, { tracking }) => {
    const state = getState();
    const { inDialog } = selectAuthProps(state);
    const open = selectIsGlobalDialogOpen(state);
    const actualInDialog = optionsInDialog || inDialog;

    if (!open && actualInDialog) {
      tracking.auth({ action: Tracking.ACTIONS.ATTEMPT });
      dispatch(
        openGlobalDialogAction({
          maxWidth: XS,
          onClose: onExit,
          children: <LazyAuthDialog />,
        }),
      );
    }

    dispatch(
      extendAuthProps({
        onExit,
        inDialog: actualInDialog,
        toggleAuthWithinComponent: actualInDialog,
        ...otherOptions,
      }),
    );
  };

export default promptAuthAction;
