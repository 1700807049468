import { onError } from 'apollo-link-error';

import { API_VERSION, GRAPHQL_VALIDATION_FAILED } from '@glass/common/modules/errors/codes';
import isErrorType, { selectErrorCode } from '@glass/common/modules/errors/isErrorType';
import { RELOAD_REASON_QUERY, SNACK_BAR_QUERY } from '@glass/common/modules/pages/queryParams';

import tracking from '@glass/shared/modules/tracking';

const handleError = (error) => {
  const { operation, graphQLErrors, networkError } = error;

  if (process.env.NEXT_PUBLIC_DEBUG === 'graphql' || process.env.CI) {
    console.error(error);
  }

  let reloadReason = null;

  if (graphQLErrors?.length) {
    graphQLErrors.forEach((graphQLError) => {
      const { message, locations, path } = graphQLError;

      if (isErrorType(graphQLError, [API_VERSION])) {
        reloadReason = 'invalid_api_version';
      } else if (isErrorType(graphQLError, [GRAPHQL_VALIDATION_FAILED])) {
        reloadReason = 'graphql_validation';
      }

      tracking.apiOperation(operation, {
        error: graphQLError,
        message,
        path,
        locations,
        code: selectErrorCode(graphQLError),
        reloadReason,
      });
    });
  }

  if (networkError) {
    tracking.apiOperation(operation, { error: networkError });
  }

  if (reloadReason && typeof window !== 'undefined') {
    const newUrl = new URL(window.location.href);
    if (!newUrl.searchParams.has(RELOAD_REASON_QUERY)) {
      tracking.exception(new Error(`reloading page`), { reloadReason });
      newUrl.searchParams.set(RELOAD_REASON_QUERY, reloadReason);
      newUrl.searchParams.set(
        SNACK_BAR_QUERY,
        JSON.stringify({ title: 'We updated you to the latest version, please try again' }),
      );
      window.location = newUrl.href;
    } else {
      tracking.exception(new Error('reload reason already set'), { reloadReason });
    }
  }
};

export default onError(handleError);
