import isValue from '@glass/common/modules/utils/isValue';

const findCookieValue = (cookies, cookieKeys) => {
  if (!cookies || !cookieKeys.length) {
    return null;
  }
  const cookieKey = cookieKeys.find((key) => isValue(cookies.get(key)));
  if (cookieKey) {
    return cookies.get(cookieKey);
  }
  return null;
};

export default findCookieValue;
