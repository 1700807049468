import {
  SENDGRID_EMAIL_PROVIDER,
  MAILGUN_EMAIL_PROVIDER,
} from '@glass/common/modules/email/providers';

export const RETARGET_EMAIL_PROVIDER = 'RETARGET_EMAIL_PROVIDER';

export default {
  name: RETARGET_EMAIL_PROVIDER,
  variations: [
    {
      value: SENDGRID_EMAIL_PROVIDER,
      weight: 1,
    },
    {
      value: MAILGUN_EMAIL_PROVIDER,
      weight: 0,
    },
  ],
};
