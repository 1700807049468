import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';
import vercelEnvVars from '@glass/web/modules/environment/vercelEnvVars';
import { SET_USER_CONFIG } from '@glass/web/modules/userConfig/actions/setUserConfigAction';

const initialState = {
  clientDeployment: process.env.NEXT_PUBLIC_VERCEL_URL,
  clientBuildId: __BUILD__,
  ...vercelEnvVars,
  geo: {},
};

export default function userConfig(state = initialState, action) {
  switch (action.type) {
    case SET_USER_CONFIG:
      // eslint-disable-next-line no-case-declarations
      const newConfig = { ...removeEmptyKeys(action.payload.userConfig) };
      return {
        ...state,
        ...newConfig,
        geo: {
          ...state.geo,
          ...newConfig.geo,
        },
      };
    default:
      return state;
  }
}
