import { Dedupe, ExtraErrorData, ReportingObserver } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';

import defaultSentrySettings from '@glass/shared/modules/sentry/defaultSentrySettings';

const integrations = [
  new ExtraErrorData(),
  new Dedupe(),
  new ReportingObserver(),
  new BrowserTracing(),
];

const clientSentrySettings = {
  ...defaultSentrySettings,
  integrations,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
};

export default clientSentrySettings;
