import { SPACE_STRING } from '@glass/common/modules/strings/constants';

const parseDisplayName = (displayName: unknown) => {
  if (!displayName || typeof displayName !== 'string') {
    return {
      firstName: null,
      lastName: null,
    };
  }
  const [firstName, ...otherName] = displayName.split(SPACE_STRING) || [null];
  return {
    firstName: firstName || null,
    lastName: otherName.join(SPACE_STRING) || null,
  };
};

export default parseDisplayName;
