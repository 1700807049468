import { RootState } from '@glass/web/modules/redux/types';

const createSelectReducerRehydrated =
  (reducerKey: keyof RootState) =>
  (state: RootState): boolean =>
    // @ts-expect-error _persist doesn't exist on every reducer
    // eslint-disable-next-line no-underscore-dangle
    !!state?.[reducerKey]?._persist?.rehydrated || false;

export default createSelectReducerRehydrated;
