const { currentOrigin } = require('@glass/env/modules/origins/constants');
const {
  LOCALE_EN_CA,
  LOCALE_EN_US,
  DEFAULT_LOCALE,
  ALL_LOCALES,
} = require('@glass/env/modules/locales/constants');
const getLocaleOrigin = require('@glass/env/modules/locales/getLocaleOrigin').default;

const defaultLocale = DEFAULT_LOCALE;

const defaultLocaleOrigin =
  process.env.NODE_ENV === 'development' ? 'https://rocket-resume.com' : currentOrigin;

/** @type {import('next/dist/server/config-shared').I18NConfig} */
const i18nConfig = {
  locales: ALL_LOCALES,
  defaultLocale,
  // make this true when we're ready to launch canada
  localeDetection: false,
  // todo: generate this off of locales array and getLocaleOrigin
  domains: [
    {
      domain: new URL(defaultLocaleOrigin).hostname,
      defaultLocale: LOCALE_EN_US,
    },
    {
      domain: new URL(getLocaleOrigin(LOCALE_EN_CA, defaultLocaleOrigin)).hostname,
      defaultLocale: LOCALE_EN_CA,
    },
  ],
};

module.exports = { defaultLocale, i18nConfig };
