import getErrorActionName from '@glass/shared/modules/errors/getErrorActionName';

const handleErrorActions =
  (error, availableActions, ...args) =>
  (dispatch) => {
    if (availableActions && error?.graphQLErrors) {
      const actionName = getErrorActionName(error, availableActions);
      const action = availableActions[actionName];

      if (typeof action === 'function') {
        return Promise.resolve(dispatch(action(error, ...args)));
      }
    }
    return Promise.reject(error);
  };

export default handleErrorActions;
