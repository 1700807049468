import type { Operation, FetchResult } from '@apollo/client';

import { ONE_SEC_MS } from '@glass/common/modules/dates/constants';
import getResumeContact from '@glass/common/modules/resumes/selectors/getResumeContact';
import type { ResumeType } from '@glass/common/modules/resumes/types';
import {
  SUBSCRIPTION,
  RESUME,
  PRICING,
  AUTH,
} from '@glass/common/modules/tracking/constants/events';
import { MOST_RECENT_RESUME_ID } from '@glass/common/modules/tracking/constants/propertyKeys';
import { CONTACT_FIELD_V2, RESUME_V2_FIELD } from '@glass/web/modules/resumes/fieldKeys';

import { taxonomyApiMethods } from '@glass/shared/modules/tracking/taxonomyTracking';

// API OPERATIONS
export const MY_PRICINGS_V2_API_METHOD = 'myPricings';
export const CREATE_STRIPE_PURCHASE_V2_API_METHOD = 'createStripePurchase';
export const CANCEL_STRIPE_SUBSCRIPTION_API_METHOD = 'cancelStripeSubscription';
export const REACTIVATE_STRIPE_SUBSCRIPTION_API_METHOD = 'reactivateStripeSubscription';
export const PAUSE_STRIPE_SUBSCRIPTION_API_METHOD = 'pauseStripeSubscription';
export const UNPAUSE_STRIPE_SUBSCRIPTION_API_METHOD = 'unpauseStripeSubscription';
export const UPSERT_RESUME_API_METHOD = 'upsertResume';
export const DELETE_RESUME_API_METHOD = 'deleteResume';
export const DUPLICATE_RESUME_API_METHOD = 'duplicateResume';
export const RESUME_API_METHOD = 'resume';
export const PRICES_API_METHOD = 'prices';
export const AUTHENTICATE_USER_API_METHOD = 'authenticateUser';
export const LOGIN_USER_API_METHOD = 'loginUser';
export const LOGOUT_USER_API_METHOD = 'logoutUser';
export const CREATE_USER_API_METHOD = 'createUser';
export const AUTHENTICATE_EMAIL_API_METHOD = 'authenticateEmail';
export const CHANGE_PASSWORD_API_METHOD = 'changePassword';
export const TRACK_EVENT_API_METHOD = 'trackEvent';
export const CONTENT_TEMPLATES_API_METHOD = 'contentTemplates';
export const SEARCH_JOB_TITLES_API_METHOD = 'searchJobTitles';
export const SEARCH_INDUSTRIES_API_METHOD = 'searchIndustries';
export const RANDOM_RESUME_API_METHOD = 'randomResume';

export const SEARCH_SKILLS_API_METHOD = 'searchSkills';
export const SEARCH_SUMMARIES_API_METHOD = 'searchSummaries';
export const SEARCH_AFFILIATIONS_API_METHOD = 'searchAffiliations';
export const SEARCH_ACCOMPLISHMENTS_API_METHOD = 'searchAccomplishments';
export const SEARCH_CERTIFICATIONS_API_METHOD = 'searchCertifications';
export const SEARCH_WORK_EXPERIENCES_API_METHOD = 'searchWorkExperiences';
export const SEARCH_FIELD_OF_STUDIES_API_METHOD = 'searchFieldOfStudies';

export const JOB_TITLES_API_METHOD = 'jobTitles';
export const INDUSTRIES_API_METHOD = 'industries';
export const SKILLS_API_METHOD = 'skills';
export const SUMMARIES_API_METHOD = 'summaries';
export const AFFILIATIONS_API_METHOD = 'affiliations';
export const ACCOMPLISHMENTS_API_METHOD = 'accomplishments';
export const CERTIFICATIONS_API_METHOD = 'certifications';
export const WORK_EXPERIENCES_API_METHOD = 'workExperiences';

export const DEFAULT_ANONYMOUS_SAMPLE_RATE = 0.1;
export const DEFAULT_NAMED_SAMPLE_RATE = 1;
export const EXCESSIVE_DURATION = 4 * ONE_SEC_MS;

const getResumeTrackingProps = (operation: Operation, { data }: FetchResult = {}) => {
  const { [operation.operationName]: resumeData } = data || {};
  const resume = resumeData as ResumeType;
  if (!resume) {
    return null;
  }

  return {
    [MOST_RECENT_RESUME_ID]: resume.id,
    data: {
      resume: {
        id: resume.id,
        [RESUME_V2_FIELD]: {
          [CONTACT_FIELD_V2]: getResumeContact(resume),
        },
        updatedAt: resume.updatedAt,
        createdAt: resume.createdAt,
      },
    },
  };
};

const getSubscriptionTrackingProps = (operation: Operation, { data }: FetchResult = {}) => {
  const { [operation.operationName]: subscription } = data || {};
  if (!subscription) {
    return null;
  }

  return {
    data: {
      // TODO: Update type with Stripe Subscription type camelcase or create stripeSubscription.fragment.graphql type
      subscription: subscription as Record<string, unknown>,
    },
  };
};

const apiMethods = {
  [MY_PRICINGS_V2_API_METHOD]: {
    eventName: PRICING,
    getTrackingProps: getSubscriptionTrackingProps,
  },
  [CREATE_STRIPE_PURCHASE_V2_API_METHOD]: {
    eventName: SUBSCRIPTION,
    getTrackingProps: getSubscriptionTrackingProps,
  },
  [CANCEL_STRIPE_SUBSCRIPTION_API_METHOD]: {
    eventName: SUBSCRIPTION,
    getTrackingProps: getSubscriptionTrackingProps,
  },
  [REACTIVATE_STRIPE_SUBSCRIPTION_API_METHOD]: {
    eventName: SUBSCRIPTION,
    getTrackingProps: getSubscriptionTrackingProps,
  },
  [PAUSE_STRIPE_SUBSCRIPTION_API_METHOD]: {
    eventName: SUBSCRIPTION,
    getTrackingProps: getSubscriptionTrackingProps,
  },
  [UNPAUSE_STRIPE_SUBSCRIPTION_API_METHOD]: {
    eventName: SUBSCRIPTION,
    getTrackingProps: getSubscriptionTrackingProps,
  },
  [UPSERT_RESUME_API_METHOD]: {
    eventName: RESUME,
    getTrackingProps: getResumeTrackingProps,
  },
  [DELETE_RESUME_API_METHOD]: {
    eventName: RESUME,
  },
  [DUPLICATE_RESUME_API_METHOD]: {
    eventName: RESUME,
  },
  [RESUME_API_METHOD]: {
    eventName: RESUME,
    getTrackingProps: getResumeTrackingProps,
  },
  [PRICES_API_METHOD]: {
    eventName: PRICING,
  },
  [AUTHENTICATE_USER_API_METHOD]: {
    eventName: AUTH,
  },
  [LOGIN_USER_API_METHOD]: {
    eventName: AUTH,
  },
  [LOGOUT_USER_API_METHOD]: {
    eventName: AUTH,
  },
  [CREATE_USER_API_METHOD]: {
    eventName: AUTH,
  },
  [AUTHENTICATE_EMAIL_API_METHOD]: {
    eventName: AUTH,
  },
  [CHANGE_PASSWORD_API_METHOD]: {
    eventName: AUTH,
  },
  [TRACK_EVENT_API_METHOD]: {
    sampleRate: 0,
  },
  [CONTENT_TEMPLATES_API_METHOD]: {
    sampleRate: DEFAULT_ANONYMOUS_SAMPLE_RATE / 1.3,
  },
  // V1 API Methods to compare with taxonomy
  [RANDOM_RESUME_API_METHOD]: {
    sampleRate: 1,
  },
  [JOB_TITLES_API_METHOD]: {
    sampleRate: 1,
  },
  [INDUSTRIES_API_METHOD]: {
    sampleRate: 1,
  },
  [SKILLS_API_METHOD]: {
    sampleRate: 1,
  },
  [SUMMARIES_API_METHOD]: {
    sampleRate: 1,
  },
  [AFFILIATIONS_API_METHOD]: {
    sampleRate: 1,
  },
  [ACCOMPLISHMENTS_API_METHOD]: {
    sampleRate: 1,
  },
  [CERTIFICATIONS_API_METHOD]: {
    sampleRate: 1,
  },
  [WORK_EXPERIENCES_API_METHOD]: {
    sampleRate: 1,
  },
  // Search methods
  [SEARCH_JOB_TITLES_API_METHOD]: {
    sampleRate: 1,
  },
  [SEARCH_INDUSTRIES_API_METHOD]: {
    sampleRate: 1,
  },
  [SEARCH_ACCOMPLISHMENTS_API_METHOD]: {
    sampleRate: 1,
  },
  [SEARCH_AFFILIATIONS_API_METHOD]: {
    sampleRate: 1,
  },
  [SEARCH_CERTIFICATIONS_API_METHOD]: {
    sampleRate: 1,
  },
  [SEARCH_FIELD_OF_STUDIES_API_METHOD]: {
    sampleRate: 1,
  },
  [SEARCH_SKILLS_API_METHOD]: {
    sampleRate: 1,
  },
  [SEARCH_SUMMARIES_API_METHOD]: {
    sampleRate: 1,
  },
  [SEARCH_WORK_EXPERIENCES_API_METHOD]: {
    sampleRate: 1,
  },
  ...taxonomyApiMethods,
};

export default apiMethods;
