export const TOGGLE_USER_CONTENT_STEPPER = 'TOGGLE_USER_CONTENT_STEPPER';

const toggleUserContentStepperAction = ({ open }) => ({
  type: TOGGLE_USER_CONTENT_STEPPER,
  payload: {
    open,
  },
});

export default toggleUserContentStepperAction;
