import { PERIOD_DELIMITER } from '@glass/common/modules/strings/constants';
import isValue from '@glass/common/modules/utils/isValue';

type OptionsType = {
  keyPrefix?: string;
  maxDepth?: number;
};

const flattenObject = (
  obj: Record<string, unknown>,
  { keyPrefix, maxDepth }: OptionsType = {},
): Record<string, unknown> =>
  (obj ? Object.keys(obj) : []).reduce((acc: Record<string, unknown>, key: string) => {
    const val = obj[key];
    if ((typeof maxDepth === 'number' && maxDepth <= 0) || ![key, val].every(isValue)) {
      return acc;
    }

    const keyString = [keyPrefix, key].filter((a) => a).join(PERIOD_DELIMITER);
    if (typeof val === 'object') {
      return {
        ...acc,
        ...flattenObject(val as Record<string, unknown>, {
          keyPrefix: keyString,
          maxDepth: typeof maxDepth === 'number' ? maxDepth - 1 : undefined,
        }),
      };
    }
    return {
      ...acc,
      [keyString]: obj[key],
    };
  }, {});

export default flattenObject;
