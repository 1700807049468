import { WINDOW_REDUX_KEY } from '@glass/web/components/App/constants';
import promptInternalDialog from '@glass/web/modules/internal/promptInternalDialog';
import { RootState } from '@glass/web/modules/redux/types';
import selectUserIsInternal from '@glass/web/modules/userConfig/selectors/selectUserIsInternal';

type Command = {
  key: string;
  isShiftKey?: boolean;
  isCtrlKey?: boolean;
  isAltKey?: boolean;
  isMetaKey?: boolean;
};

type KeydownEvent = {
  isInternal?: boolean;
  action: () => void;
  commands: Command[];
};

// define window types for redux state this should be moved somewhere for global usage
declare global {
  interface Window {
    [WINDOW_REDUX_KEY]: {
      store: {
        getState: () => RootState;
        dispatch: (action: any) => void;
      };
    };
  }
}

const keydownEvents: KeydownEvent[] = [
  {
    isInternal: true,
    action: () => {
      window[WINDOW_REDUX_KEY].store.dispatch(promptInternalDialog());
    },
    // this equates to a meta+i+i key press
    commands: [
      {
        key: 'i',
        isCtrlKey: true,
      },
      {
        key: 'i',
        isCtrlKey: true,
      },
    ],
  },
];

let timeout: NodeJS.Timeout | null = null;

const KEYPRESS_TIMEOUT = 500;

const isEventCommandMatch = (event: KeyboardEvent, command: Command) => {
  const {
    key,
    isShiftKey = false,
    isCtrlKey = false,
    isAltKey = false,
    isMetaKey = false,
  } = command;
  const {
    shiftKey: isEventShiftKey = false,
    ctrlKey: isEventCtrlKey = false,
    altKey: isEventAltKey = false,
    metaKey: isEventMetaKey = false,
    key: eventKey,
  } = event;
  return (
    eventKey === key &&
    isEventShiftKey === isShiftKey &&
    isEventCtrlKey === isCtrlKey &&
    isEventAltKey === isAltKey &&
    isEventMetaKey === isMetaKey
  );
};

const isUserInternal = () => selectUserIsInternal(window[WINDOW_REDUX_KEY].store.getState());

const setupKeyboardEventListeners = (): void => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return;
  }

  let activeIndexes: number[];

  const resetAll = () => {
    activeIndexes = keydownEvents.map(() => 0);
  };

  resetAll();

  function handleKeyDownEvents(event: KeyboardEvent): void {
    const { key } = event;

    // exclude non-key letter presses
    if (!key || key.length !== 1) {
      return;
    }

    keydownEvents.forEach((keyDownEvent, keyDownIndex) => {
      const { action, isInternal: isInternalEvent, commands } = keyDownEvent;

      if (
        (isInternalEvent && !isUserInternal()) ||
        !isEventCommandMatch(event, commands[activeIndexes[keyDownIndex]])
      ) {
        activeIndexes[keyDownIndex] = 0;
        return;
      }

      if (activeIndexes[keyDownIndex] < commands.length - 1) {
        activeIndexes[keyDownIndex] += 1;
        return;
      }

      action();
      activeIndexes[keyDownIndex] = 0;
      return;
    });

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(resetAll, KEYPRESS_TIMEOUT);
  }

  document.addEventListener('keydown', handleKeyDownEvents);
};

export default setupKeyboardEventListeners;
