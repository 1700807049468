import type { Operation, FetchResult } from '@apollo/client';

export const TAXONOMY_SEARCH_CONTENTS_API_METHOD = 'searchContents';
export const TAXONOMY_SEARCH_CATEGORIES_API_METHOD = 'searchCategories';
export const TAXONOMY_RANDOM_RESUME_V2_API_METHOD = 'randomResumeV2';
export const TAXONOMY_FIND_RANDOM_JOB_TITLES_API_METHOD = 'findRandomJobTitles';
export const TAXONOMY_FIND_RANDOM_CONTENTS_API_METHOD = 'findRandomContents';
export const TAXONOMY_FIND_MANY_CATEGORY_API_METHOD = 'findManyCategory';

export type TaxonomyApiMethod =
  | typeof TAXONOMY_SEARCH_CONTENTS_API_METHOD
  | typeof TAXONOMY_SEARCH_CATEGORIES_API_METHOD
  | typeof TAXONOMY_RANDOM_RESUME_V2_API_METHOD
  | typeof TAXONOMY_FIND_RANDOM_JOB_TITLES_API_METHOD
  | typeof TAXONOMY_FIND_RANDOM_CONTENTS_API_METHOD
  | typeof TAXONOMY_FIND_MANY_CATEGORY_API_METHOD;

const createGetTaxonomyTrackingProps = (operation: Operation, { data }: FetchResult = {}) => {
  const { [operation.operationName]: response } = data || {};
  const trackingProps = { sampleRate: 1 };

  if (!response) {
    return {
      responseError: 'no response data',
      ...trackingProps,
    };
  }

  return {
    responseLength: (response as string)?.length,
    ...trackingProps,
  };
};

export const taxonomyApiMethods = {
  [TAXONOMY_SEARCH_CONTENTS_API_METHOD]: {
    getTrackingProps: createGetTaxonomyTrackingProps,
  },
  [TAXONOMY_SEARCH_CATEGORIES_API_METHOD]: {
    getTrackingProps: createGetTaxonomyTrackingProps,
  },
  [TAXONOMY_RANDOM_RESUME_V2_API_METHOD]: {
    getTrackingProps: createGetTaxonomyTrackingProps,
  },
  [TAXONOMY_FIND_RANDOM_JOB_TITLES_API_METHOD]: {
    getTrackingProps: createGetTaxonomyTrackingProps,
  },
  [TAXONOMY_FIND_RANDOM_CONTENTS_API_METHOD]: {
    getTrackingProps: createGetTaxonomyTrackingProps,
  },
  [TAXONOMY_FIND_MANY_CATEGORY_API_METHOD]: {
    getTrackingProps: createGetTaxonomyTrackingProps,
  },
};
