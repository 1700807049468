import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DEFAULT_RESUME_TEMPLATES_PATH } from '@glass/common/modules/pages/paths';
import {
  INDUSTRY_SLUG_QUERY,
  JOBTITLE_SLUG_QUERY,
  SEED_QUERY,
} from '@glass/common/modules/pages/queryParams';
import useSetDefaultResumeBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/useSetDefaultResumeBuilderBasePath';

import useDefaultWord from '@glass/shared/modules/content/staticVariations/useDefaultWord';
import {
  pushIndustriesInterest,
  pushJobTitlesInterest,
} from '@glass/shared/modules/interests/pushInterestAction';
import industryPropTypes from '@glass/shared/modules/jobTitles/industryPropTypes';
import jobTitlePropTypes from '@glass/shared/modules/jobTitles/jobtitlePropTypes';
import PageDataContext from '@glass/shared/modules/pageData/PageDataContext';
import { useTrackingPath } from '@glass/shared/modules/tracking/useTrackingProp';

const withPageData = () => (Component) => {
  function WithPageContext(props) {
    const { pageProps: { pageData } = {} } = props;

    const {
      words: {
        contentWords: contentWordsServer,
        verbWords: verbWordsServer,
        adjectiveWords: adjectiveWordsServer,
        resumeWords: resumeWordsServer,
      } = {},
      jobTitle,
      industry,
    } = pageData || {};

    useDefaultWord('contentWord', contentWordsServer);
    useDefaultWord('verbWord', verbWordsServer);
    useDefaultWord('adjectiveWord', adjectiveWordsServer);
    useDefaultWord('resumeWord', resumeWordsServer);
    const defaultResumeBuilderBasePath = useSetDefaultResumeBuilderBasePath();
    useTrackingPath('resumeBuilderBasePath', defaultResumeBuilderBasePath);

    const {
      query: {
        [INDUSTRY_SLUG_QUERY]: industrySlugQuery,
        [JOBTITLE_SLUG_QUERY]: jobTitleSlugQuery,
        [SEED_QUERY]: seed,
      },
    } = useRouter();

    const jobTitleSlug = jobTitle?.slug || jobTitleSlugQuery;
    const industrySlug = industry?.slug || industrySlugQuery;

    const pageDataWithDefaults = useMemo(() => {
      return {
        templatesBasePath: DEFAULT_RESUME_TEMPLATES_PATH,
        ...(pageData || {}),
        iSlug: industrySlug,
        jSlug: jobTitleSlug,
        seed,
      };
    }, [industrySlug, jobTitleSlug, pageData, seed]);

    const dispatch = useDispatch();

    useEffect(() => {
      if (industry) {
        dispatch(pushIndustriesInterest(industry));
      }
    }, [dispatch, industry]);

    useEffect(() => {
      if (jobTitle) {
        dispatch(pushJobTitlesInterest(jobTitle));
      }
    }, [dispatch, jobTitle]);

    return (
      <PageDataContext.Provider value={pageDataWithDefaults}>
        <Component {...props} />
      </PageDataContext.Provider>
    );
  }

  WithPageContext.propTypes = {
    pageProps: PropTypes.shape({
      pageData: PropTypes.shape({
        industry: PropTypes.shape(industryPropTypes),
        jobTitle: PropTypes.shape(jobTitlePropTypes),
        words: PropTypes.shape({
          adjectiveWords: PropTypes.shape({
            singular: PropTypes.string,
          }),
          contentWords: PropTypes.shape({
            singular: PropTypes.string,
          }),
          resumeWords: PropTypes.shape({
            singular: PropTypes.string,
          }),
          verbWords: PropTypes.shape({
            singular: PropTypes.string,
          }),
        }),
      }),
    }).isRequired,
  };

  WithPageContext.getInitialProps = Component.getInitialProps;

  return WithPageContext;
};

export default withPageData;
