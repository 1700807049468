import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';

import { EXTEND_AUTH_PROPS } from '@glass/shared/modules/authForm';

const extendAuthProps = (payload = {}) => ({
  type: EXTEND_AUTH_PROPS,
  payload: removeEmptyKeys(payload),
});

export default extendAuthProps;
