/**
 * Creates a new object containing only the key-value pairs from the input object
 * that have keys specified in the provided array.
 *
 * @param {Record<string, unknown>} obj - The input object.
 * @param {string[]} keys - An array of keys to include in the resulting object.
 * @returns {Record<string, unknown>} A new object with selected key-value pairs.
 */
const keepObjKeys = (obj: Record<string, unknown>, keys: string[]): Record<string, unknown> =>
  keys.reduce((result, key) => {
    if (typeof obj[key] !== 'undefined') {
      return {
        ...result,
        [key]: obj[key],
      };
    }
    return result;
  }, {});

export default keepObjKeys;
