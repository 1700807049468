import makeArray from '@glass/common/modules/utils/makeArray';

const removeStateItem = (existingItems, removeItems, { uniqKey } = {}) =>
  existingItems.filter((existingItem) =>
    makeArray(removeItems).every((removeItem) =>
      uniqKey ? removeItem[uniqKey] !== existingItem[uniqKey] : removeItem !== existingItem,
    ),
  );

export default removeStateItem;
