import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import assignments, { ASSIGNMENTS_REDUCER_KEY } from '@glass/common/modules/assignments/redux';
import app, { APP_REDUCER_KEY } from '@glass/web/modules/app';
import auth, { AUTH_REDUCER_KEY } from '@glass/web/modules/auth';
import { CONTENT_REDUCER_KEY } from '@glass/web/modules/content/reducer';
import globalDialog, { GLOBAL_DIALOG_REDUCER_KEY } from '@glass/web/modules/globalDialog';
import globalSnackbar, { GLOBAL_SNACKBAR_REDUCER_KEY } from '@glass/web/modules/globalSnackbar';
import currenciesReducer, {
  CURRENCIES_REDUCER_KEY,
} from '@glass/web/modules/payments/currenciesReducer';
import { INTERESTS_REDUCER_KEY } from '@glass/web/modules/redux/constants';
import { USER_CONFIG_REDUCER_KEY } from '@glass/web/modules/userConfig/deprecatedTypes';

import authForm, { AUTH_FORM_REDUCER_KEY } from '@glass/shared/modules/authForm';
import contentReducer from '@glass/shared/modules/content/contentReducer';
import eventHistoryReducer, {
  EVENT_HISTORY_REDUCER_KEY,
} from '@glass/shared/modules/eventHistory/eventHistoryReducer';
import interestsReducer from '@glass/shared/modules/interests/interestsReducer';
import resumesReducer, { RESUME_REDUCER_KEY } from '@glass/shared/modules/resumes/resumesReducer';
import userConfig from '@glass/shared/modules/userConfig';
import userContent, { USER_CONTENT_REDUCER_KEY } from '@glass/shared/modules/userContent';
import zendeskReducer, { ZENDESK_REDUCER_KEY } from '@glass/shared/modules/zendesk/zendeskReducer';

const reducer = combineReducers({
  [APP_REDUCER_KEY]: app,
  [AUTH_REDUCER_KEY]: auth,
  [AUTH_FORM_REDUCER_KEY]: authForm,
  [USER_CONFIG_REDUCER_KEY]: userConfig,
  [USER_CONTENT_REDUCER_KEY]: userContent,
  [GLOBAL_DIALOG_REDUCER_KEY]: globalDialog,
  [GLOBAL_SNACKBAR_REDUCER_KEY]: globalSnackbar,
  [RESUME_REDUCER_KEY]: resumesReducer,
  [ASSIGNMENTS_REDUCER_KEY]: assignments,
  [CONTENT_REDUCER_KEY]: contentReducer,
  [INTERESTS_REDUCER_KEY]: interestsReducer,
  [ZENDESK_REDUCER_KEY]: zendeskReducer,
  [EVENT_HISTORY_REDUCER_KEY]: eventHistoryReducer,
  [CURRENCIES_REDUCER_KEY]: currenciesReducer,
  form: formReducer,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
