import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createSelectDefaultWords,
  setDefaultWord,
} from '@glass/shared/modules/content/contentReducer';
import { useTrackingDefault } from '@glass/shared/modules/tracking/useTrackingProp';

const useDefaultWord = (key, newDefault) => {
  const dispatch = useDispatch();
  const selectCurrentDefaultWords = useMemo(() => createSelectDefaultWords(key), [key]);
  const currentDefaultWords = useSelector(selectCurrentDefaultWords);
  const defaultWords = newDefault || currentDefaultWords;
  useEffect(() => {
    if (defaultWords.singular !== currentDefaultWords?.singular) {
      dispatch(setDefaultWord(key, defaultWords));
    }
  }, [currentDefaultWords?.singular, defaultWords, key, dispatch]);
  useTrackingDefault(key, defaultWords.singular);
  return defaultWords;
};

export default useDefaultWord;
