import queueGlobalSnackbarAction from '@glass/web/modules/globalSnackbar/queueGlobalSnackbarAction';
import { AppDispatch } from '@glass/web/modules/redux/types';

const handleSnackbarQueryAction = (snackbar: unknown) => (dispatch: AppDispatch) => {
  if (typeof snackbar === 'string') {
    let snackbarOptions;
    try {
      snackbarOptions = JSON.parse(snackbar);
    } catch (e) {
      // unable to parse so it's a title string
    }
    dispatch(queueGlobalSnackbarAction(snackbarOptions || { title: snackbar }));
  }
};

export default handleSnackbarQueryAction;
