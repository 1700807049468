import { INTERNAL_QUERY, EMAIL_AUTH_QUERY } from '@glass/common/modules/pages/queryParams';
import { TRUE_STRING_VALUE } from '@glass/web/modules/cookies/configs';
import { INTERNAL_COOKIE_NAME, EMAIL_AUTH_COOKIE_NAME } from '@glass/web/modules/cookies/names';

export const QUERY_COOKIE_MAP = {
  [INTERNAL_QUERY]: INTERNAL_COOKIE_NAME,
  [EMAIL_AUTH_QUERY]: EMAIL_AUTH_COOKIE_NAME,
};

const COOKIE_VALUE_OVERRIDES = {
  [INTERNAL_COOKIE_NAME]: TRUE_STRING_VALUE,
};

const handleCookieQueryParamAction =
  (value, config) =>
  (d, s, { cookies }) => {
    const { queryParam } = config;
    const cookieId = QUERY_COOKIE_MAP[queryParam];
    if (cookieId) {
      const cookieValue = COOKIE_VALUE_OVERRIDES[cookieId] || value;
      cookies.set(cookieId, cookieValue);
    }
  };

export default handleCookieQueryParamAction;
