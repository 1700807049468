import React, { lazy } from 'react';

import openGlobalDialogAction from '@glass/web/modules/globalDialog/openGlobalDialogAction';
import { AppDispatch, AppGetState } from '@glass/web/modules/redux/types';
import { MD } from '@glass/web/modules/theme/breakpointKeys';
import selectUserIsInternal from '@glass/web/modules/userConfig/selectors/selectUserIsInternal';

const LazyInternalSettings = lazy(() => import('@glass/web/components/Internal/InternalSettings'));

const promptInternalDialog = () => (dispatch: AppDispatch, getState: AppGetState) => {
  if (!selectUserIsInternal(getState())) {
    return;
  }

  dispatch(
    openGlobalDialogAction({
      maxWidth: MD,
      children: <LazyInternalSettings />,
    }),
  );
};

export default promptInternalDialog;
