export const TEMPLATES_SECTION = 'templates';
export const CONTACT_SECTION = 'contact';

export const EXPERIENCE_SECTION = 'work-history';
export const EDUCATION_SECTION = 'education';
export const SKILLS_SECTION = 'skills';
export const SUMMARY_SECTION = 'summary';

export const DOWNLOAD_SECTION = 'download';
export const UPGRADE_SECTION = 'upgrade';
export const CHECKOUT_SECTION = 'checkout';

export const ACCOMPLISHMENT_SECTION = 'accomplishments';
export const AFFILIATION_SECTION = 'affiliations';
export const ADDITIONAL_SECTION = 'additional-information';
export const CERTIFICATIONS_SECTION = 'certifications';
export const MISCELLANEOUS_SECTION = 'miscellaneous';

export const ACCOMPLISHMENTS_LABEL = 'Accomplishments';
export const AFFILIATIONS_LABEL = 'Affiliations';
export const ADDITIONAL_INFO_LABEL = 'Additional Information';
export const CERTIFICATIONS_LABEL = 'Certifications';

type MiscSectionLabelType = {
  label: MiscSectionLabel;
  type: MiscSection;
};

export const miscSections: MiscSectionLabelType[] = [
  {
    label: ACCOMPLISHMENTS_LABEL,
    type: ACCOMPLISHMENT_SECTION,
  },
  {
    label: AFFILIATIONS_LABEL,
    type: AFFILIATION_SECTION,
  },
  {
    label: CERTIFICATIONS_LABEL,
    type: CERTIFICATIONS_SECTION,
  },
  {
    label: ADDITIONAL_INFO_LABEL,
    type: ADDITIONAL_SECTION,
  },
];

export type MiscSection =
  | typeof ACCOMPLISHMENT_SECTION
  | typeof AFFILIATION_SECTION
  | typeof CERTIFICATIONS_SECTION
  | typeof ADDITIONAL_SECTION;

export type Section =
  | typeof TEMPLATES_SECTION
  | typeof CONTACT_SECTION
  | typeof EXPERIENCE_SECTION
  | typeof EDUCATION_SECTION
  | typeof SKILLS_SECTION
  | typeof SUMMARY_SECTION
  | typeof MISCELLANEOUS_SECTION
  | typeof DOWNLOAD_SECTION
  | typeof UPGRADE_SECTION
  | typeof CHECKOUT_SECTION
  | MiscSection;

export type MiscSectionLabel =
  | typeof ACCOMPLISHMENTS_LABEL
  | typeof AFFILIATIONS_LABEL
  | typeof ADDITIONAL_INFO_LABEL
  | typeof CERTIFICATIONS_LABEL;
