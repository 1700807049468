const compatibilitySlug = (str: string) =>
  str.toLowerCase().replace(/\./, '').replace(/\W+/g, '-').replace(/^-|-$/g, '');

const DEFAULT_VERCEL_DOMAIN_POSTFIX = 'vercel.app';

export default function createVercelOrigin({
  brand = process.env.BRAND || process.env.NEXT_PUBLIC_BRAND || '',
  gitCommitRef = process.env.GIT_COMMIT_REF ||
    process.env.VERCEL_GIT_COMMIT_REF ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ||
    '', // the branch
  gitRepoOwner = process.env.GIT_REPO_OWNER ||
    process.env.VERCEL_GIT_REPO_OWNER ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER ||
    '',
  vercelDomainPostfix = process.env.VERCEL_DOMAIN_POSTFIX ||
    process.env.NEXT_PUBLIC_VERCEL_DOMAIN_POSTFIX ||
    DEFAULT_VERCEL_DOMAIN_POSTFIX,
} = {}) {
  if (!gitCommitRef || !brand || !gitRepoOwner || !vercelDomainPostfix) {
    return null;
  }

  // If we have a custom VERCEL_DOMAIN_POSTFIX, then the gitRepoOwner (scope) is not needed
  const scope =
    vercelDomainPostfix === DEFAULT_VERCEL_DOMAIN_POSTFIX
      ? `-${compatibilitySlug(gitRepoOwner)}`
      : '';

  return `https://${compatibilitySlug(brand)}-git-${compatibilitySlug(
    gitCommitRef,
  )}${scope}.${vercelDomainPostfix}`;
}
