const createAssignmentSeed = ({
  id,
  name,
  version,
}: {
  id: string;
  name: string;
  version: number;
}) => [id, name, version].join();

export default createAssignmentSeed;
