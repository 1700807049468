export const SECTION_ID = 'section';
export const CTA_BUTTON_ID = 'cta-button';
export const APP_ID = 'app';
export const REDUX_ID = 'redux';
export const FORM_ID = 'form';
export const HEADER_ID = 'header';
export const FOOTER_ID = 'footer';

// internal sections
export const SUPPORT_SECTION_ID = 'support';
export const SUPPORT_CANCEL_SECTION_ID = 'support-cancel';
export const SUPPORT_IS_FREE_SECTION_ID = 'support-is-free';
export const FAQ_SECTION_ID = 'faq';

// content sections
export const EDITABLE_RESUME_SECTION_ID = 'editable-resume';
export const CAROUSEL_SECTION_ID = 'carousel';
export const AUTH_SECTION_ID = 'auth';
export const EDUCATION_EXPERIENCE_SECTION_ID = 'education-experience';
export const WORK_EXPERIENCE_SECTION_ID = 'work-experience';
export const RANDOM_RESUME_SECTION_ID = 'random-resume';
export const CUSTOM_TEMPLATE_SECTION_ID = 'custom-template';
export const SALARY_SECTION_ID = 'salary';
export const RESUME_EXAMPLES_SECTION_ID = 'resume-examples';
export const RELATED_RESOURCES_SECTION_ID = 'related-resources';
export const RESOURCES_SECTION_ID = 'resources';
export const HERO_CAROUSEL_SECTION_ID = 'hero-carousel';
export const HERO_SEARCH_SECTION_ID = 'hero-search';
export const HERO_SECTION_ID = 'hero';
export const RELATED_JOB_TITLES_SECTION_ID = 'related-job-titles';
export const JOB_TITLES_SECTION_ID = 'job-titles';
export const INDUSTRIES_SECTION_ID = 'industries';
export const HOW_WE_GET_YOU_HIRED_SECTION_ID = 'how-we-get-you-hired';
export const TEMPLATE_SELECTOR_SECTION_ID = 'template-selector';
export const TESTIMONIAL_SECTION_ID = 'testimonial';

export const LANDING_PAGE_ID = 'landing';
export const LANDING_TEMPLATES_PAGE_ID = 'landing-templates';
export const DEFAULT_PAGE_ID = 'default';
export const HOME_PAGE_ID = 'home';
export const BROWSE_PAGE_ID = 'browse';
export const JOB_TITLE_PAGE_ID = 'job-title';
export const INDUSTRY_PAGE_ID = 'industry';
