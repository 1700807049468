import type { Reducer } from 'redux';

import createAssignmentTrackingObject from '@glass/common/modules/assignments/createAssignmentTrackingObject';
import {
  AssignmentConfig,
  AssignmentVariationValue,
} from '@glass/common/modules/assignments/types';
import { ACTIVE } from '@glass/common/modules/assignments/variations';
import keepObjKeys from '@glass/common/modules/utils/keepObjKeys';
import persistReducer from '@glass/web/modules/redux/persistReducer';
import type { PayloadAction, RootState } from '@glass/web/modules/redux/types';
import createSelectReducerRehydrated from '@glass/web/modules/store/createSelectReducerRehydrated';
import selectUserIsInternal from '@glass/web/modules/userConfig/selectors/selectUserIsInternal';

export const ASSIGNMENTS_REDUCER_KEY = 'assignments';

export const ADD_ASSIGNMENT_OVERRIDES = 'ADD_ASSIGNMENT_OVERRIDES';
export const ADD_ASSIGNMENTS = 'ADD_ASSIGNMENTS';

export type AssignmentsState = {
  init: boolean;
  configs: AssignmentConfig[];
  assignments: Record<string, unknown>;
  assignmentOverrides: Record<string, AssignmentVariationValue>;
  assignmentId: string | null;
  // TODO: Check this type
  assignmentTracking: ReturnType<typeof createAssignmentTrackingObject> | null;
  _persist?: {
    rehydrated: boolean;
  };
};

const initialState = {
  init: false,
  configs: [],
  assignments: {},
  assignmentOverrides: {},
  assignmentId: null,
  assignmentTracking: null,
};

const selectIsAssignmentInit = (globalState?: RootState) =>
  !!globalState?.[ASSIGNMENTS_REDUCER_KEY]?.init;

const selectIsAssignmentsRehydrated = createSelectReducerRehydrated(ASSIGNMENTS_REDUCER_KEY);

// we assume that only internal users can have an assignment override
export const selectIsAssignmentReady = (globalState?: RootState) => {
  if (!globalState) {
    return false;
  }
  const isInternal = selectUserIsInternal(globalState);
  const isInit = selectIsAssignmentInit(globalState);
  if (!isInternal) {
    return isInit;
  }
  // internal users could have an override
  return isInit && selectIsAssignmentsRehydrated(globalState);
};

export const selectAssignments = (globalState: RootState) =>
  globalState?.[ASSIGNMENTS_REDUCER_KEY].assignments;

export const selectAssignmentTracking = (globalState: RootState) =>
  globalState?.[ASSIGNMENTS_REDUCER_KEY]?.assignmentTracking;

export const selectAssignmentConfigs = (globalState: RootState) =>
  globalState?.[ASSIGNMENTS_REDUCER_KEY].configs;

export const selectAssignmentOverrides = (globalState: RootState) =>
  globalState?.[ASSIGNMENTS_REDUCER_KEY].assignmentOverrides || null;

export const selectCurrentUserAssignments = (globalState: RootState) => {
  const assignments = selectAssignments(globalState);
  const assignmentOverrides = selectAssignmentOverrides(globalState);

  return {
    ...assignments,
    ...assignmentOverrides,
  };
};

const createSelectAssignmentOverride = (name: string) => (globalState: RootState) =>
  selectAssignmentOverrides(globalState)?.[name];

const createSelectDefaultAssignment = (name: string) => (globalState: RootState) =>
  selectAssignments(globalState)[name];

export const createSelectAssignment = (name: string) => {
  const selectAssignmentOverride = createSelectAssignmentOverride(name);
  const selectAssignment = createSelectDefaultAssignment(name);
  return (globalState: RootState) =>
    selectAssignmentOverride(globalState) ?? selectAssignment(globalState);
};

export const createSelectAssignmentIsVariation = (
  name: string,
  variation: AssignmentVariationValue = ACTIVE,
) => {
  const selectAssignment = createSelectAssignment(name);
  return (globalState: RootState) => selectAssignment(globalState) === variation;
};

const addAssignmentTrackingState = (newState: AssignmentsState) => {
  const { configs, assignmentId, assignmentOverrides } = newState;

  if (!assignmentId || !configs?.length) {
    return { ...newState };
  }

  return {
    ...newState,
    assignmentTracking: createAssignmentTrackingObject(configs, assignmentId, {
      overrides: assignmentOverrides,
    }),
  };
};

export const assignmentsReducer: Reducer<AssignmentsState, PayloadAction<AssignmentsState>> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action,
): AssignmentsState => {
  switch (action.type) {
    // this should only be running with internal users
    case ADD_ASSIGNMENT_OVERRIDES:
      return addAssignmentTrackingState({
        ...state,
        configs: action.payload.configs || state.configs,
        assignmentOverrides: keepObjKeys(
          {
            ...(action.payload.assignmentOverrides === null ? {} : state.assignmentOverrides),
            ...action.payload.assignmentOverrides,
          },
          Object.keys(state.assignments),
        ) as Record<string, AssignmentVariationValue>,
      });
    case ADD_ASSIGNMENTS:
      return addAssignmentTrackingState({
        ...state,
        init: true,
        assignmentId: action.payload.assignmentId || state.assignmentId,
        configs: action.payload.configs || state.configs,
        assignments: {
          ...state.assignments,
          ...action.payload.assignments,
        },
      });
    default:
      return state;
  }
};

const persistConfig = {
  key: ASSIGNMENTS_REDUCER_KEY,
  whitelist: ['assignmentOverrides'],
};

export default persistReducer(persistConfig, assignmentsReducer);
