import isValue from '@glass/common/modules/utils/isValue';

const findObjectValue = (obj, objKeys) => {
  if (!obj || !objKeys.length) {
    return null;
  }
  const queryKey = objKeys.find((key) => isValue(obj[key]));
  if (queryKey) {
    return obj[queryKey];
  }
  return null;
};

export default findObjectValue;
