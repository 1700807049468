export const LOCALE_EN_US = 'en-US' as const;
export const LOCALE_EN = 'en' as const;
export const LOCALE_EN_CA = 'en-CA' as const;
export const LOCALE_FR_CA = 'fr-CA' as const;
// export const LOCALE_ES_MX = 'es-MX';

export type LocaleType = typeof LOCALE_EN_US | typeof LOCALE_EN_CA | typeof LOCALE_FR_CA;

// todo: derive from config
export const ALL_LOCALES: LocaleType[] = [LOCALE_EN_US, LOCALE_EN_CA];

export type LocaleSettingType = string;

export const validateLocale = (locale: unknown) => {
  if (typeof locale !== 'string' || !ALL_LOCALES.includes(locale as LocaleType)) {
    throw new Error(`Invalid locale ${locale || ''}`);
  }

  return locale as LocaleType;
};

export const DEFAULT_LOCALE = LOCALE_EN_US as LocaleType;
