import { LocaleType } from '@glass/env/modules/locales/constants';
import { SET_LOCALE } from '@glass/web/modules/app';

const setAppLocaleAction = (locale: LocaleType | null) => ({
  type: SET_LOCALE,
  payload: {
    locale: locale,
  },
});

export default setAppLocaleAction;
