import { formatTitle } from '@glass/common/modules/utils/printTitle';
import selectUserConfig from '@glass/web/modules/userConfig/selectors/selectUserConfig';

const logConfig = (state) => {
  console.info(formatTitle('Internal Only'));
  console.info(`Build Time: ${new Date(__BUILD__)}`);
  console.info(formatTitle('User Config'));
  console.info(selectUserConfig(state));
};

export default logConfig;
