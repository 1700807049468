import { createPersistedQueryLink as apolloCreatePersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

const createPersistedQueryLink = ({ useGETForHashedQueries = true } = {}) =>
  apolloCreatePersistedQueryLink({
    sha256,
    useGETForHashedQueries,
  });

export default createPersistedQueryLink;
