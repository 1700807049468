import { Section } from '@glass/web/modules/resumes/router/sections';
import stepData, { StepData, metaStepData } from '@glass/web/modules/resumes/router/stepData';

const iterateBuilderSections = (
  callback: (section: StepData, key: Section) => void,
  { firstSection = metaStepData.firstSection, lastSection = '' } = {},
) => {
  let key: Section | null = firstSection;
  let isLastSection;
  while (key && !isLastSection) {
    const section: StepData = stepData[key];
    callback(section, key);
    isLastSection = key === lastSection;
    key = section.nextSection;
  }
};

export default iterateBuilderSections;
