export const EDIT_VERBER_WORD = 'editor';
export const GENERATE_VERBER_WORD = 'generator';
export const CREATE_VERBER_WORD = 'creator';
export const BUILDER_VERBER_WORD = 'builder';

const verberWordVariations = [
  { value: BUILDER_VERBER_WORD, weight: 1 },
  { value: CREATE_VERBER_WORD, weight: 1 },
  { value: 'maker', weight: 1 },
  'writer',
  GENERATE_VERBER_WORD,
  EDIT_VERBER_WORD,
];

export default verberWordVariations;
