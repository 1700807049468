import filterObject from '@glass/common/modules/utils/filterObject';
import flattenObject from '@glass/common/modules/utils/flatten/flattenObject';

const isEvent = (evt: any) =>
  !!(evt && typeof evt === 'object' && (evt instanceof Event || evt.nativeEvent instanceof Event));

// we want to add order to our mixpanel props wile maintaining lists (in mixpanel)
const createTrackingProps = (props: Record<string, unknown>): Record<string, unknown> => {
  try {
    const [arraysObj] = filterObject(props, (key: any, value: any) => Array.isArray(value));
    const [filteredProps] = filterObject(props, (key: any, value: any) => !isEvent(value));

    return {
      ...(filteredProps && flattenObject(filteredProps)),
      ...arraysObj,
    } as Record<string, unknown>;
  } catch (e: unknown) {
    return {
      action: 'fail',
      originalAction: props.action,
      message: 'unable to create tracking props',
      label: e && typeof e === 'object' && 'message' in e ? (e as Error).message : 'unknown error',
      originalLabel: props.label,
    };
  }
};

export default createTrackingProps;
