import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import cleanTypename from '@glass/common/modules/utils/cleanTypename';
import getBrowserLanguage from '@glass/web/modules/locales/getBrowserLanguage';
import getBrowserTimezone from '@glass/web/modules/locales/getBrowserTimezone';
import { addPriorityCurrencies } from '@glass/web/modules/payments/currenciesReducer';
import useDispatch from '@glass/web/modules/redux/useDispatch';
import setUserConfigAction from '@glass/web/modules/userConfig/actions/setUserConfigAction';
import userConfigQuery from '@glass/web/modules/userConfig/queries/userConfig.graphql';
import { UserConfigType } from '@glass/web/modules/userConfig/types';

type UserConfigDataType = {
  userConfig: UserConfigType;
};

const useUserConfig = () => {
  const dispatch = useDispatch();

  const handleCompleted = useCallback(
    (response: UserConfigDataType) => {
      const { detectedCurrencies } = response.userConfig;
      dispatch(setUserConfigAction({ ...response.userConfig, asyncReady: true }));
      dispatch(addPriorityCurrencies(detectedCurrencies));
    },
    [dispatch],
  );

  const {
    data,
    loading: isLoading,
    ...other
  } = useQuery<UserConfigDataType>(userConfigQuery, {
    ssr: false,
    onCompleted: handleCompleted,
    variables: {
      browserLanguage: getBrowserLanguage(),
      browserTimezone: getBrowserTimezone(),
    },
  });

  const userConfig = useMemo(
    () => (data?.userConfig ? cleanTypename(data?.userConfig) : null),
    [data?.userConfig],
  );

  return {
    userConfig,
    isLoading,
    ...other,
  };
};

export default useUserConfig;
