const getErrorActionName = (error, availableActions) => {
  if (error?.graphQLErrors) {
    let actionName;
    error.graphQLErrors.some((graphQLError) => {
      const { action: errorActionName } = graphQLError?.extensions || {};
      if (errorActionName && (availableActions?.[errorActionName] || !availableActions)) {
        actionName = errorActionName;
        return actionName;
      }
      return false;
    });
    return actionName;
  }
  return null;
};

export default getErrorActionName;
