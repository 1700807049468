import { ResponseCookie } from 'next/dist/compiled/@edge-runtime/cookies';

import { ONE_DAY_SEC, ONE_MINUTE_SEC, ONE_YEAR_SEC } from '@glass/common/modules/dates/constants';
import { currentOrigin } from '@glass/env/modules/origins/constants';
import {
  ASSIGNMENT_COOKIE_NAME,
  DEVICE_COOKIE_NAME,
  GEO_COOKIE_NAME,
  INTERNAL_COOKIE_NAME,
  IP_COOKIE_NAME,
  SESSION_COOKIE_NAME,
  UA_COOKIE_NAME,
  NEXT_LOCALE_COOKIE_NAME,
} from '@glass/web/modules/cookies/names';

const SESSION_COOKIE_AGE = 30 * ONE_MINUTE_SEC;
const NEXT_LOCALE_COOKIE_AGE = 30 * ONE_DAY_SEC;
const MAX_COOKIE_AGE = 2 * ONE_YEAR_SEC;

export const TRUE_STRING_VALUE = '1';

type CookieConfig = Omit<ResponseCookie, 'value'>;

// TODO: Compute domain prop at runtime, unify this with shared/modules/cookies/cookieConfigs commonAttrs
const DEFAULT_CONFIG: Omit<CookieConfig, 'name'> = {
  path: '/',
  secure: process.env.NODE_ENV === 'production',
  domain: new URL(currentOrigin)?.hostname,
  sameSite: 'lax',
  httpOnly: false,
};

export const DEVICE_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: DEVICE_COOKIE_NAME,
  maxAge: MAX_COOKIE_AGE,
};

export const SESSION_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: SESSION_COOKIE_NAME,
  maxAge: SESSION_COOKIE_AGE,
};

export const ASSIGNMENT_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: ASSIGNMENT_COOKIE_NAME,
  maxAge: MAX_COOKIE_AGE,
};

export const INTERNAL_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: INTERNAL_COOKIE_NAME,
  maxAge: 30 * ONE_DAY_SEC,
};

export const GEO_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: GEO_COOKIE_NAME,
  maxAge: SESSION_COOKIE_AGE,
};

export const CF_GEO_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: GEO_COOKIE_NAME,
  maxAge: SESSION_COOKIE_AGE,
};

export const IP_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: IP_COOKIE_NAME,
  maxAge: SESSION_COOKIE_AGE,
};

export const UA_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: UA_COOKIE_NAME,
  maxAge: SESSION_COOKIE_AGE,
};

export const NEXT_LOCALE_COOKIE_CONFIG: CookieConfig = {
  ...DEFAULT_CONFIG,
  name: NEXT_LOCALE_COOKIE_NAME,
  maxAge: NEXT_LOCALE_COOKIE_AGE,
};
