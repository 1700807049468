import { SIGN_OUT } from '@glass/web/modules/auth';
import persistReducer from '@glass/web/modules/redux/persistReducer';
import generateSectionStepKey from '@glass/web/modules/resumes/router/generateSectionStepKey';
import createSelectReducerRehydrated from '@glass/web/modules/store/createSelectReducerRehydrated';

import createArrayReducer from '@glass/shared/modules/redux/arrayReducer/createArrayReducer';
import defaultStateReconciler from '@glass/shared/modules/store/defaultStateReconciler';

export const PUSH_RECENT_RESUME = 'PUSH_RECENT_RESUME';
export const COMPLETE_BUILDER_STEP = 'COMPLETE_BUILDER_STEP';
export const DECLINE_RECENT_RESUMES = 'DECLINE_RECENT_RESUMES';
export const PROMPT_SAVE_PROGRESS = 'PROMPT_SAVE_PROGRESS';
export const REMOVE_RESUME = 'REMOVE_RESUME';

export const RESUME_REDUCER_KEY = 'resumes';

const MAX_RECENT_ITEMS = 10;

const RECENT_RESUMES_KEY = 'recentResumes';
const DECLINED_RESUMES_KEY = 'declinedResumes';

const ARRAY_CONFIG = {
  keys: [RECENT_RESUMES_KEY, DECLINED_RESUMES_KEY],
  maxItems: MAX_RECENT_ITEMS,
  isUniq: true,
};

const {
  initialState: initialArrayState,
  pushItemsReducer,
  removeItemsReducer,
} = createArrayReducer(ARRAY_CONFIG);

const initialState = {
  completedSteps: {},
  saveProgress: {
    lastPromptDate: null,
    count: 0,
  },
  ...initialArrayState,
};

const NO_ID_KEY = 'NO_ID_KEY';

const removeResumeReducer = (state, action) => {
  if (!action.payload.resumeId) {
    throw new Error('resumeId required');
  }

  const { resumeId } = action.payload;
  const { [resumeId]: _, ...newCompletedSteps } = state;
  return removeItemsReducer(
    {
      ...state,
      completedSteps: newCompletedSteps,
    },
    { payload: { recentResumes: [resumeId] } },
  );
};

const completeBuilderStepReducer = (state, action) => {
  const { resumeId } = action.payload;

  const completedSteps = {
    ...state.completedSteps,
  };

  // if completed without an id we'll save it to current and then when we have an id we'll merge those in with the id
  completedSteps[resumeId || NO_ID_KEY] = {
    ...(state.completedSteps[NO_ID_KEY] || {}),
    ...(state.completedSteps[resumeId || NO_ID_KEY] || {}),
    [generateSectionStepKey(action.payload)]: true,
  };

  // merge in no id when we have a resume id
  if (resumeId && completedSteps[NO_ID_KEY]) {
    delete completedSteps[NO_ID_KEY];
  }

  const newState = {
    ...state,
    completedSteps,
  };

  if (resumeId) {
    // add to recent, remove from declined
    return pushItemsReducer(
      removeItemsReducer(newState, { payload: { [DECLINED_RESUMES_KEY]: [resumeId] } }),
      { payload: { [RECENT_RESUMES_KEY]: [resumeId] } },
    );
  }

  return newState;
};

const promptSaveProgressReducer = (state, action) => ({
  ...state,
  saveProgress: {
    ...state.saveProgress,
    ...(action.payload?.reason
      ? {
          [action.payload.reason]:
            state.saveProgress[action.payload.reason] > 0
              ? state.saveProgress[action.payload.reason] + 1
              : 1,
        }
      : {}),
    count: state.saveProgress.count + 1,
    lastPromptDate: new Date(),
  },
});

const resumesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_RESUME:
      return removeResumeReducer(state, action);
    case PUSH_RECENT_RESUME:
      return pushItemsReducer(state, action);
    case DECLINE_RECENT_RESUMES:
      return pushItemsReducer(state, { payload: { declinedResumes: state.recentResumes } });
    case COMPLETE_BUILDER_STEP:
      return completeBuilderStepReducer(state, action);
    case PROMPT_SAVE_PROGRESS:
      return promptSaveProgressReducer(state, action);
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

const persistConfig = {
  key: RESUME_REDUCER_KEY,
  version: 2,
  stateReconciler: defaultStateReconciler,
};

export default persistReducer(persistConfig, resumesReducer);
export const createSelectResumeCompletedSteps =
  (resumeId = NO_ID_KEY) =>
  (state) =>
    state[RESUME_REDUCER_KEY].completedSteps[resumeId];

export const selectRecentResumes = (state) => state?.[RESUME_REDUCER_KEY]?.[RECENT_RESUMES_KEY];
export const selectDeclinedResumes = (state) => state[RESUME_REDUCER_KEY][DECLINED_RESUMES_KEY];
export const selectSaveProgress = (state) => state[RESUME_REDUCER_KEY].saveProgress;
export const selectMostRecentResumeId = (state) => selectRecentResumes(state)?.[0];
export const selectMostRecentResumesCount = (state) => selectRecentResumes(state).length;
export const selectIsResumesReducerHydrated = createSelectReducerRehydrated(RESUME_REDUCER_KEY);

export const createSelectShouldPromptActiveResume = (currentResumeId) => (state) => {
  const mostRecentResumeId = selectMostRecentResumeId(state);
  const declinedResumes = selectDeclinedResumes(state);

  return (
    mostRecentResumeId &&
    mostRecentResumeId !== currentResumeId &&
    !declinedResumes.includes(mostRecentResumeId)
  );
};

export const declineRecentResumesAction = () => ({
  type: DECLINE_RECENT_RESUMES,
});
