import { MASTER, RELEASE } from '@glass/common/modules/environments/branches';
import { ROCK_BRAND } from '@glass/common/modules/environments/brands';

// this implements our current brand origin convention of qa-$brand.domain.com for master and $brand.domain.com for release
// todo: support additional domains
const createBrandOrigin = ({
  domain = 'rocket-resume.com',
  brand = process.env.BRAND || process.env.NEXT_PUBLIC_BRAND || '',
  branch = process.env.GIT_COMMIT_REF ||
    process.env.VERCEL_GIT_COMMIT_REF ||
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ||
    '',
} = {}) => {
  if (!brand || ![MASTER, RELEASE].includes(branch)) {
    return null;
  }

  let subdomain = `qa-${brand}`;

  if (branch === RELEASE) {
    subdomain = brand === ROCK_BRAND ? '' : brand;
  } else if (branch === MASTER && brand === ROCK_BRAND) {
    subdomain = 'qa';
  }

  return `https://${subdomain ? `${subdomain}.` : ''}${domain}`;
};

export default createBrandOrigin;
