import { SET_APP_LOADING } from '@glass/web/modules/app';

const setAppLoadingAction = (loading = true) => ({
  type: SET_APP_LOADING,
  payload: {
    loading,
  },
});

export default setAppLoadingAction;
