import { LocaleType } from '@glass/env/modules/locales/constants';

type DomainConfigType = {
  defaultLocale: string;
  locales?: string[];
};

export type LocalePathType = {
  defaultLocale: string;
  domains?: DomainConfigType[];
};

const SLASH = '/';

// this is for testing only
export const getProductionLocalePath = (
  locale: LocaleType,
  { domains }: LocalePathType,
  { isTrailingSlash = false }: { isTrailingSlash?: boolean } = {},
) => {
  const trailingStr: string = isTrailingSlash ? SLASH : '';

  if (!domains) {
    return trailingStr;
  }

  return (
    domains.reduce((path: string | null, { defaultLocale: domainDefaultLocale, locales }) => {
      if (typeof path === 'string') {
        return path;
      }

      // here we always return a value to short circuit, we then convert it to an empty string
      if (domainDefaultLocale === locale) {
        return trailingStr;
      }

      if (locales?.includes(locale)) {
        return `/${locale}${trailingStr}`;
      }

      return path;
    }, null) || trailingStr
  );
};

// note: this func is dangerous in that it returns an empty string and null which are both
// falsey so you need to explicitly check if its null or not
const getLocalePath = (
  locale: LocaleType,
  { defaultLocale, domains }: LocalePathType,
  { isTrailingSlash = false }: { isTrailingSlash?: boolean } = {},
): string => {
  const trailingStr: string = isTrailingSlash ? SLASH : '';

  if (process.env.NODE_ENV !== 'production') {
    return locale === defaultLocale ? trailingStr : `/${locale}${trailingStr}`;
  }

  return getProductionLocalePath(locale, { defaultLocale, domains }, { isTrailingSlash });
};

export default getLocalePath;
