import isValue from '@glass/common/modules/utils/isValue';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';

export const reFileExtension = /(?:\.([^.]+))?$/;

const EQUAL_DELIMITER = '=';
const SETTING_DELIMITER = '&';

type Value = string | number | boolean | undefined;
type ObjectType = Record<string, Value>;

export const parseObjectSlug = (
  slug: string,
  { settingsDelimiter = SETTING_DELIMITER, equalDelimiter = EQUAL_DELIMITER } = {},
) =>
  slug.split(settingsDelimiter).reduce((parsed, kv) => {
    const [key, value] = kv.split(equalDelimiter);
    if (key && value) {
      return {
        ...parsed,
        [decodeURIComponent(key)]: decodeURIComponent(value),
      };
    }
    return parsed;
  }, {});

export const createObjectSlug = (
  object: ObjectType,
  {
    settingsDelimiter = SETTING_DELIMITER,
    equalDelimiter = EQUAL_DELIMITER,
    isEncodeUri = true,
  } = {},
) =>
  Object.keys(removeEmptyKeys(object))
    .sort()
    .reduce((slug, key) => {
      const val = object[key];
      if (key && val && isValue(key) && isValue(val)) {
        return `${slug}${slug ? settingsDelimiter : ''}${
          isEncodeUri ? encodeURIComponent(key) : key
        }${equalDelimiter}${isEncodeUri ? encodeURIComponent(val) : val}`;
      }
      return slug;
    }, '')
    .toLowerCase();
