// Extracts keys from T where the value is not null, undefined, or an empty string
import isValue from '@glass/common/modules/utils/isValue';

type NonEmptyKeys<T> = {
  [K in keyof T]: T[K] extends null | undefined | '' ? never : K;
}[keyof T];

// Constructs a new type by picking only the NonEmptyKeys and excluding null, undefined, and ''
type CleanedObject<T> = {
  [K in NonEmptyKeys<T>]: Exclude<T[K], null | undefined | ''>;
};

// Type guard to check if a key is a NonEmptyKey
function isNonEmptyKey<T>(obj: T, key: keyof T): key is NonEmptyKeys<T> {
  return isValue(obj[key]);
}

function removeEmptyKeys<T extends object>(obj: T): CleanedObject<T> {
  const result = {} as CleanedObject<T>;

  Object.keys(obj).forEach((key) => {
    const typedKey = key as keyof T;
    if (isNonEmptyKey(obj, typedKey)) {
      // At this point, TypeScript knows that typedKey is NonEmptyKeys<T>
      result[typedKey] = obj[typedKey] as Exclude<T[typeof typedKey], null | undefined | ''>;
    }
  });

  return result;
}

export default removeEmptyKeys;
