import JsCookies, { CookieAttributes } from 'js-cookie';

import { ONE_SEC_MS } from '@glass/common/modules/dates/constants';
import cookieConfigs, {
  computeCommonCookieAttributes,
  CookieConfigs,
} from '@glass/web/modules/cookies/cookieConfigs';
import { ICookies, SetType } from '@glass/web/modules/cookies/types';

const options: Record<string, CookieAttributes> = {};
const settings: Record<string, CookieConfigs> = {};

export default class Cookies implements ICookies {
  public domain: string;

  constructor({ domain }: { domain: string }) {
    this.domain = domain;
  }

  getCookieSettings(name: string) {
    if (!settings[name]) {
      settings[name] = {
        ...computeCommonCookieAttributes({ domain: this.domain }),
        ...cookieConfigs[name],
      };
    }
    return settings[name];
  }

  getCookieOptions(name: string) {
    if (!options[name]) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { maxAge, path, secure, sameSite, httpOnly } = this.getCookieSettings(name);

      const cookieOptions: CookieAttributes = {
        path,
        secure,
        domain: this.domain,
        httpOnly,
        sameSite,
      };
      if (typeof maxAge !== 'undefined') {
        cookieOptions.expires = new Date(maxAge * ONE_SEC_MS + Date.now());
      }
      options[name] = cookieOptions;
    }
    return options[name];
  }

  get(name: string) {
    const value = JsCookies.get(name);
    if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
      console.info('cookies get', name, value, this.getCookieOptions(name));
    }
    if (!value) {
      return '';
    }
    const { isJson } = this.getCookieSettings(name);
    if (isJson) {
      try {
        return JSON.parse(decodeURIComponent(value));
      } catch (err) {
        this.remove(name);
        return '';
      }
    }

    return value;
  }

  remove(name: string) {
    if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
      console.info('cookies remove', name, this.getCookieOptions(name));
    }
    JsCookies.remove(name, this.getCookieOptions(name));
    return;
  }

  set(name: string, value: SetType) {
    if (!cookieConfigs[name]) {
      throw new Error(`invalid cookie ${name}`);
    }

    let setValue = typeof value === 'function' ? value() : value;

    const { isJson } = this.getCookieSettings(name);
    if (isJson) {
      setValue = JSON.stringify(setValue);
    }
    if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
      console.info('cookies set', name, setValue, this.getCookieOptions(name));
    }

    if (typeof setValue !== 'string') {
      throw new Error(`invalid cookie value ${setValue}`);
    }

    JsCookies.set(name, setValue, this.getCookieOptions(name));
    return setValue;
  }

  upsert(name: string, defaultValue: string) {
    return this.get(name) || this.set(name, defaultValue);
  }
}
