import {
  LOCALE_EN_US,
  LOCALE_EN_CA,
  LOCALE_FR_CA,
  LocaleType,
  LOCALE_EN,
} from '@glass/env/modules/locales/constants';
import getLocalePath, { LocalePathType } from '@glass/env/modules/locales/getLocalePath';
import { currentOrigin } from '@glass/env/modules/origins/constants';

const CANADA_ENDING = '.ca';

/**
 * Creates a locale-specific origin from a given origin and locale.
 *
 * @param origin
 * @param locale
 * @returns
 */
const getLocaleOrigin = (locale?: LocaleType | string | null, origin = currentOrigin) => {
  if (!locale) {
    throw new Error('locale is required');
  }

  switch (locale) {
    case LOCALE_EN_CA:
    case LOCALE_FR_CA:
      return origin.replace(/\.com$/, CANADA_ENDING);
    case LOCALE_EN:
    case LOCALE_EN_US:
      return origin;
    default:
      throw new Error(`invalid locale: ${locale || 'none'}`);
  }
};

export const getLocaleOriginPath = (
  locale: LocaleType,
  { defaultLocale, domains }: LocalePathType,
  origin = currentOrigin,
  { isTrailingSlash = false }: { isTrailingSlash?: boolean } = {},
) =>
  `${getLocaleOrigin(locale, origin)}${
    getLocalePath(locale, { defaultLocale, domains }, { isTrailingSlash }) || ''
  }`;

export default getLocaleOrigin;
