export type ParseSearchQueryOptions = {
  keyPrefix?: string;
};

const parseSearchQuery = (search: string, { keyPrefix }: ParseSearchQueryOptions = {}) => {
  const paramArray = [...new URLSearchParams(search)];

  return paramArray.reduce(
    (params, [key, value]) => ({ ...params, [`${keyPrefix ?? ''}${key}`]: value }),
    {},
  );
};

export default parseSearchQuery;
