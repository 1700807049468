import { LOW_DASH_STRING } from '@glass/common/modules/strings/constants';

const prefix = `${process.env.NEXT_PUBLIC_BRAND?.substring(0, 2)}${LOW_DASH_STRING}${
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? ''
    : `${(process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV)?.substring(
        0,
        3,
      )}${LOW_DASH_STRING}`
}`;

export const AUTH_COOKIE_NAME = `${prefix}a3`;
export const AUTH2_COOKIE_NAME = `${prefix}a4`;
export const EMAIL_AUTH_COOKIE_NAME = `${prefix}e`;
export const DEVICE_COOKIE_NAME = `${prefix}d`;
export const SESSION_COOKIE_NAME = `${prefix}s`;
export const INTERNAL_COOKIE_NAME = `${prefix}i`;
export const ASSIGNMENT_COOKIE_NAME = `${prefix}as`;
export const GOOGLE_CLICK_COOKIE_NAME = `${prefix}gc`;
export const GOOGLE_DISPLAY_COOKIE_NAME = `${prefix}gd`;
export const UTM_COOKIE_NAME = `${prefix}ut`;
export const PACKAGES_COOKIE_NAME = `${prefix}p`;
export const SUBSCRIPTION_TRACKING_COOKIE_NAME = `${prefix}st`;

// new cookies
export const GEO_COOKIE_NAME = `${prefix}geo`;
export const IP_COOKIE_NAME = `${prefix}ip`;
export const UA_COOKIE_NAME = `${prefix}ua`;
export const NEXT_LOCALE_COOKIE_NAME = `NEXT_LOCALE`;

// third-party cookies
export const GOOGLE_CLIENT_COOKIE_NAME = '_ga';
export const GOOGLE_AD_WORDS_COOKIE_NAME = '_gcl_aw';
export const GOOGLE_AD_WORDS_COOKIE_BETA_NAME = '_gcl_gb';
export const GOOGLE_DISPLAY_ADS_COOKIE_NAME = '_gcl_dc';
export const FB_USER_PIXEL_COOKIE_NAME = '_fbp';
export const FB_USER_CLICK_COOKIE_NAME = '_fbc';
