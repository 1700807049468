import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useTracking from '@glass/shared/modules/tracking/useTracking';

const PATH_REGEX = /\?.*$/;

export const useTrackingPath = (key, value = null) => {
  const tracking = useTracking();
  const { isReady, asPath } = useRouter();
  useEffect(() => {
    if (isReady && asPath) {
      const pathname = asPath.replace(PATH_REGEX, '');
      tracking.addPathProp(pathname, key, value);
    }
  }, [value, key, tracking, isReady, asPath]);
};

export const useTrackingDefault = (key, value = null) => {
  const tracking = useTracking();
  useEffect(() => {
    tracking.addDefaultProp(key, value);
  }, [value, key, tracking]);
};

export const PATH_TYPE = 'path';
export const DEFAULT_TYPE = 'default';

export const useTrackingProps = (props, type = PATH_TYPE) => {
  const tracking = useTracking();
  const { isReady, asPath } = useRouter();
  useEffect(() => {
    if (!isReady || !props) {
      return;
    }

    if (type === PATH_TYPE && asPath) {
      const pathname = asPath.replace(PATH_REGEX, '');
      tracking.addPathProps(pathname, props);
    } else if (type === DEFAULT_TYPE) {
      tracking.addDefaultProps(props);
    }
  }, [asPath, isReady, props, tracking, type]);
};
