const createCustomPersistMerge =
  (customMerge) =>
  (inboundState, originalState, reducedState, { debug } = {}) => {
    const newState = { ...reducedState };
    // only rehydrate if inboundState exists and is an object

    if (inboundState && typeof inboundState === 'object') {
      Object.keys(inboundState).forEach((key) => {
        // ignore _persist data
        if (key === '_persist') return;

        if (typeof customMerge === 'function') {
          const result = customMerge(key, reducedState[key], inboundState[key]);
          if (typeof result !== 'undefined') {
            newState[key] = result;
            return;
          }
        }

        // modified
        if (originalState[key] !== reducedState[key]) {
          if (process.env.NODE_ENV !== 'production' && debug)
            console.info(
              'redux-persist/stateReconciler: sub state for key `%s` modified, skipping.',
              key,
            );
          return;
        }
        // otherwise hard set the new value
        newState[key] = inboundState[key];
      });
    }

    if (
      process.env.NODE_ENV !== 'production' &&
      debug &&
      inboundState &&
      typeof inboundState === 'object'
    ) {
      console.info(
        `redux-persist/stateReconciler: rehydrated keys '${Object.keys(inboundState).join(', ')}'`,
      );
    }

    return newState;
  };

export default createCustomPersistMerge;
