import { CurrencyCode } from '@glass/common/modules/payments/currencies/types';

export const PROMPT_RECOVER_ACCOUNT = 'PROMPT_RECOVER_ACCOUNT' as const;
export const PROMPT_LOGIN = 'PROMPT_LOGIN' as const;
export const PROMPT_LOGOUT = 'PROMPT_LOGOUT' as const;
export const PROMPT_SIGNUP = 'PROMPT_SIGNUP' as const;
export const PROMPT_PASSWORD = 'PROMPT_PASSWORD' as const;
export const RETRY_CURRENCY = 'RETRY_CURRENCY' as const;
export const REFETCH_PRICING = 'REFETCH_PRICING' as const;
export const INVALID_ARGS = 'INVALID_ARGS' as const;
export const EXISTING_SUBSCRIPTION = 'EXISTING_SUBSCRIPTION' as const;

export type ActionCode =
  | typeof PROMPT_RECOVER_ACCOUNT
  | typeof PROMPT_LOGIN
  | typeof PROMPT_LOGOUT
  | typeof PROMPT_SIGNUP
  | typeof PROMPT_PASSWORD
  | typeof INVALID_ARGS
  | typeof REFETCH_PRICING
  | typeof RETRY_CURRENCY
  | typeof EXISTING_SUBSCRIPTION;

export type ErrorProperties =
  | {
      action:
        | typeof PROMPT_RECOVER_ACCOUNT
        | typeof PROMPT_LOGIN
        | typeof PROMPT_LOGOUT
        | typeof PROMPT_SIGNUP
        | typeof PROMPT_PASSWORD
        | typeof INVALID_ARGS;
      data?: Record<string, string>;
      invalidArgs?: string[];
    }
  | {
      action: typeof REFETCH_PRICING;
    }
  | {
      action: typeof RETRY_CURRENCY;
      data: {
        invalidCurrency: CurrencyCode;
        retryCurrency: CurrencyCode;
      };
    }
  | {
      action: typeof EXISTING_SUBSCRIPTION;
      data: {
        subscriptionId: string;
      };
    };
