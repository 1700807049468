import createId from '@glass/common/modules/id/createId';
import { FACEBOOK_CLICK_ID_QUERY } from '@glass/common/modules/pages/queryParams';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';
import {
  ASSIGNMENT_COOKIE_NAME,
  DEVICE_COOKIE_NAME,
  FB_USER_CLICK_COOKIE_NAME,
  FB_USER_PIXEL_COOKIE_NAME,
  GOOGLE_CLIENT_COOKIE_NAME,
  INTERNAL_COOKIE_NAME,
  SESSION_COOKIE_NAME,
} from '@glass/web/modules/cookies/names';
import {
  setupCookieChangePolling,
  stopCookieChangePolling,
} from '@glass/web/modules/loading/setupCookieChangePolling';
import parseBrowserQuery from '@glass/web/modules/url/parseBrowserQuery';
import setUserConfigAction from '@glass/web/modules/userConfig/actions/setUserConfigAction';

import getSessionDate from '@glass/shared/modules/datetime/getSessionDate';
import Tracking from '@glass/shared/modules/tracking/Tracking';

const getPuppeteerChecks = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const userAgent = window.navigator && navigator.userAgent.includes('HeadlessChrome');
  const chromeAppInstalled =
    window.chrome && window.chrome.app && window.chrome.app.isInstalled === undefined;
  const webdriver = !!window?.navigator.webdriver;
  const errorStack = new Error().stack.toString().includes('puppeteer');

  if ([userAgent, chromeAppInstalled, webdriver, errorStack].every((v) => !v)) {
    return null;
  }

  return { userAgent, chromeAppInstalled, webdriver, errorStack };
};

const initCookieAction =
  () =>
  (dispatch, getState, { cookies }) => {
    const sessionDate = getSessionDate();
    // these time ids are just a fallback and should never be used assuming middleware is working and setting a crypto id
    const deviceId = cookies.upsert(DEVICE_COOKIE_NAME, createId({ nowDate: sessionDate }));
    const sessionId = cookies.upsert(SESSION_COOKIE_NAME, createId({ nowDate: sessionDate }));
    const assignmentId = cookies.upsert(ASSIGNMENT_COOKIE_NAME, deviceId);

    const googleClientCookie = cookies.get(GOOGLE_CLIENT_COOKIE_NAME);
    const fbp = cookies.get(FB_USER_PIXEL_COOKIE_NAME);
    const fbc =
      cookies.get(FB_USER_CLICK_COOKIE_NAME) ||
      Tracking.normalizeFbclid(parseBrowserQuery()?.[FACEBOOK_CLICK_ID_QUERY]);

    const internal = process.env.NODE_ENV !== 'production' || !!cookies.get(INTERNAL_COOKIE_NAME);
    dispatch(
      setUserConfigAction(
        removeEmptyKeys({
          deviceId,
          assignmentId,
          sessionStart: sessionDate.toISOString(),
          googleClientCookie,
          internal,
          referrer: document.referrer,
          sessionId,
          fbc,
          fbp,
          puppet: getPuppeteerChecks(),
        }),
      ),
    );
  };

const handleCookieChangeAction = () => (dispatch) => {
  dispatch(initCookieAction());
  stopCookieChangePolling();
};

const initSessionAction = () => (dispatch) => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    import('@glass/shared/modules/fingerprint/botDetector')
      .then((module) => module.default)
      .then((botDetector) => dispatch(setUserConfigAction({ botDetector })));
  }

  dispatch(initCookieAction());
  setupCookieChangePolling((response) => dispatch(handleCookieChangeAction(response)), {
    interval: 3000,
    timeout: 18000,
  });
};

export default initSessionAction;
