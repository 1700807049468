import Tracking from '@glass/shared/modules/tracking/Tracking';

export const TOGGLE_USER_CONTENT = 'TOGGLE_USER_CONTENT';

const toggleUserContentAction =
  ({ id, section, text }) =>
  (dispatch, getState, { tracking }) => {
    tracking.click({
      action: Tracking.ACTIONS.TOGGLE_CONTENT,
      contentId: id,
      text,
      label: section,
    });
    return dispatch({
      type: TOGGLE_USER_CONTENT,
      payload: {
        id,
        section,
        text,
      },
    });
  };

export default toggleUserContentAction;
