export const CANCEL = 'CANCEL';

// important: all churnkey assignments much start with the churnkey prefix
export const CHURNKEY_PREFIX = 'CHURNKEY';
export const CHURNKEY_DEFAULT = `${CHURNKEY_PREFIX}_DEFAULT`;
export const INTERNAL = 'INTERNAL';

export default {
  name: CANCEL,
  version: 9,
  variations: [
    {
      value: INTERNAL,
      weight: 0,
    },
    {
      value: CHURNKEY_DEFAULT,
    },
  ],
};
