const getBrowserLanguage = () => {
  if (typeof window === 'undefined' || typeof window?.navigator === 'undefined') {
    return null;
  }

  return (
    ((window.navigator.language ||
      // @ts-expect-error navigator is different in different browsers
      window.navigator.browserLanguage ||
      // @ts-expect-error navigator is different in different browsers
      window.navigator.userLanguage ||
      // @ts-expect-error navigator is different in different browsers
      window.navigator.systemLanguage ||
      window.navigator.languages[0]) as string) || null
  );
};

export default getBrowserLanguage;
