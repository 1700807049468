import type { ResumeType } from '@glass/common/modules/resumes/types';
import {
  CONTACT_FIELD_V2,
  LAST_NAME_FIELD_V2,
  RESUME_V2_FIELD,
} from '@glass/web/modules/resumes/fieldKeys';

const getResumeContactLastName = (resume?: ResumeType) =>
  resume?.[RESUME_V2_FIELD]?.[CONTACT_FIELD_V2]?.[LAST_NAME_FIELD_V2];

export default getResumeContactLastName;
