const zendeskClient = (...args) => {
  if (process.env.NEXT_PUBLIC_ZENDESK_KEY) {
    if (__BROWSER__ && typeof window?.zE === 'function') {
      window.zE(...args);
    } else if (process.env.NODE_ENV !== 'production' && __BROWSER__) {
      console.error('zendesk command run without function available', ...args);
    }
  }
};

export default zendeskClient;
