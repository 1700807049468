import getResumeContactDisplayName from '@glass/common/modules/resumes/selectors/getResumeContactDisplayName';
import { selectUserProfile } from '@glass/web/modules/auth';
import { selectResumeValue } from '@glass/web/modules/resumes/selectors';

import selectPaymentFormDisplayName from '@glass/shared/modules/payments/selectors/selectPaymentFormDisplayName';

const selectUserDisplayName = (state) => {
  const resume = selectResumeValue(state);
  const resumeDisplayName = getResumeContactDisplayName(resume) || null;

  if (resumeDisplayName) {
    return resumeDisplayName;
  }

  const paymentDisplayName = selectPaymentFormDisplayName(state);

  if (paymentDisplayName) {
    return paymentDisplayName;
  }

  const { displayName } = selectUserProfile(state) || {};

  return displayName || null;
};

export default selectUserDisplayName;
