import PropTypes from 'prop-types';

import industryPropTypes from '@glass/shared/modules/jobTitles/industryPropTypes';

const jobTitlePropTypes = {
  id: PropTypes.string,
  slug: PropTypes.string,
  name: PropTypes.string,
  industries: PropTypes.arrayOf(PropTypes.shape(industryPropTypes)),
};

export default jobTitlePropTypes;
