import randomFromSeed from '@glass/common/modules/random/randomFromSeed';
import {
  ValueType,
  getArrayItemFromFloat,
  getValue,
} from '@glass/common/modules/utils/getArrayValueFromFloat';

export const randomFromArraySeedItem = <T>(arr: ValueType<T>[], seed: string) =>
  getArrayItemFromFloat(arr, randomFromSeed(seed));

const randomFromArraySeed = <T>(arr: ValueType<T>[], seed: string) => {
  const item = randomFromArraySeedItem(arr, seed);
  return item !== undefined && item !== null ? getValue<T>(item) : undefined;
};

export default randomFromArraySeed;
