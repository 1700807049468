import modifyObjectKeyValues from '@glass/common/modules/utils/modifyObjectKeyValues';
import { SIGN_OUT } from '@glass/web/modules/auth';
import {
  INDUSTRIES_KEY,
  JOB_TITLES_KEY,
  RESOURCE_TAGS_KEY,
  PUSH_INTERESTS,
  REMOVE_INTERESTS,
  INTERESTS_REDUCER_KEY,
} from '@glass/web/modules/redux/constants';
import persistReducer from '@glass/web/modules/redux/persistReducer';

import createArrayReducer from '@glass/shared/modules/redux/arrayReducer/createArrayReducer';
import getArrayReducerKeyDefaults from '@glass/shared/modules/redux/arrayReducer/getArrayReducerKeyDefaults';
import defaultStateReconciler from '@glass/shared/modules/store/defaultStateReconciler';

const ARRAY_CONFIG = {
  keys: [JOB_TITLES_KEY, INDUSTRIES_KEY, RESOURCE_TAGS_KEY],
  uniqKey: 'slug',
  maxItems: 10,
  itemSettings: {
    [RESOURCE_TAGS_KEY]: {
      uniqKey: null,
      isUniq: true,
    },
  },
};

const { initialState, pushItemsReducer, removeItemsReducer } = createArrayReducer(ARRAY_CONFIG);

const interestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_INTERESTS:
      return pushItemsReducer(state, action);
    case REMOVE_INTERESTS:
      return removeItemsReducer(state, action);
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default persistReducer(
  {
    key: INTERESTS_REDUCER_KEY,
    version: 1,
    stateReconciler: defaultStateReconciler,
  },
  interestsReducer,
);

export const selectInterests = (state) => state?.[INTERESTS_REDUCER_KEY];
export const selectNormalizedInterests = (state) => {
  if (!state) {
    return null;
  }
  const interests = selectInterests(state);
  return modifyObjectKeyValues(interests, (key, value) => {
    const newKey = `${INTERESTS_REDUCER_KEY}.${key}`;
    if (value?.length) {
      const { uniqKey } = getArrayReducerKeyDefaults(key, ARRAY_CONFIG);
      if (uniqKey) {
        return [newKey, value.map((itm) => itm?.[uniqKey] || itm)];
      }
    }
    if (initialState[key]) {
      return [newKey];
    }
    return [null];
  });
};
export const selectRecentJobTitles = (state) => state[INTERESTS_REDUCER_KEY][JOB_TITLES_KEY];
export const selectMostRecentJobTitle = (state) => selectRecentJobTitles(state)[0];
