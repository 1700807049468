import getArrayReducerKeyDefaults from '@glass/shared/modules/redux/arrayReducer/getArrayReducerKeyDefaults';
import getArrayReducerKeyItems from '@glass/shared/modules/redux/arrayReducer/getArrayReducerKeyItems';
import removeStateItem from '@glass/shared/modules/redux/arrayReducer/removeStateItem';

const createRemoveItemsReducer = (config) => {
  const removeReducer = (key, state, action) => {
    const { uniqKey, maxItems, isUniq } = getArrayReducerKeyDefaults(key, config);
    const { items, existingItems } = getArrayReducerKeyItems(key, state, action);
    return removeStateItem(existingItems, items, { uniqKey, maxItems, isUniq });
  };

  return (state, action) =>
    Object.keys(action.payload).reduce(
      (agg, key) => {
        return { ...agg, [key]: removeReducer(key, state, action) };
      },
      { ...state },
    );
};

export default createRemoveItemsReducer;
