import { CountryOption } from '@glass/common/modules/geo/data/countries';

const countryCache: Record<string, CountryOption | null> = {};

const cacheCountry = (key: keyof typeof countryCache, value: CountryOption | null) => {
  if (typeof countryCache[key] === 'undefined') {
    countryCache[key] = value ?? null;
  }
  return countryCache[key];
};

const standardizeSearch = (search: string) => search.toUpperCase();

// must be uppercase and 2 letters and in our array
export const isValidCountryCode = (countryCode: unknown, countries: CountryOption[]): boolean => {
  if (typeof countryCode !== 'string') {
    return false;
  }

  if (countryCode.length !== 2) {
    return false;
  }

  if (countryCode !== countryCode.toUpperCase()) {
    return false;
  }

  if (typeof countryCache[countryCode] !== 'undefined') {
    return !!countryCache[countryCode];
  }

  if (
    countries.some((country) => {
      const { label, code } = country;
      // cache both results for future / other lookups
      cacheCountry(code, country);
      cacheCountry(standardizeSearch(label), country);
      return code === countryCode;
    })
  ) {
    return true;
  }

  cacheCountry(countryCode, null);
  return false;
};

// searches for countries matching country code or labels with caching, this needs to be unknown since we use it in js code
const findCountry = (countrySearch: unknown, countries: CountryOption[]): CountryOption | null => {
  if (typeof countrySearch !== 'string' || !countrySearch) {
    return null;
  }

  // standardize search country to upper case
  const standardCountrySearch = standardizeSearch(countrySearch.trim());

  // check cache
  if (typeof countryCache[standardCountrySearch] !== 'undefined') {
    return countryCache[standardCountrySearch];
  }

  // short circuit
  if (standardCountrySearch?.length < 2) {
    return cacheCountry(standardCountrySearch, null);
  }

  // search country names
  return (
    countries.find((country) => {
      const { label, code } = country;
      const upperLabel = standardizeSearch(label);
      cacheCountry(upperLabel, country);
      cacheCountry(code, country);
      return standardCountrySearch === code || upperLabel === standardCountrySearch;
    }) || null
  );
};

export default findCountry;
