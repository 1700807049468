import { AssignmentConfig, AssignmentSetting } from '@glass/common/modules/assignments/types';
import { ACTIVE, INACTIVE } from '@glass/common/modules/assignments/variations';

const DEFAULT_WEIGHT = 1;

const createAssignmentConfig = ({
  name,
  variations,
  rollOut,
  version = 1,
  description,
  indexedOrder,
  value,
  contexts,
}: AssignmentSetting) =>
  ({
    name,
    variations: Array.isArray(variations)
      ? variations
      : [
          { value: ACTIVE, weight: typeof rollOut === 'number' ? rollOut : DEFAULT_WEIGHT },
          {
            value: INACTIVE,
            weight: typeof rollOut === 'number' ? 100 - rollOut : DEFAULT_WEIGHT,
          },
        ],
    rollOut,
    version,
    description,
    indexedOrder,
    value,
    contexts,
  } as AssignmentConfig);

const createAssignmentConfigs = (assignmentConfigs: AssignmentSetting[]) =>
  assignmentConfigs.map(createAssignmentConfig);

export default createAssignmentConfigs;
