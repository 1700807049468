import { TRUE_STRING_VALUE } from '@glass/web/modules/cookies/configs';
import { INTERNAL_COOKIE_NAME } from '@glass/web/modules/cookies/names';
import { AppDispatch, AppGetState } from '@glass/web/modules/redux/types';
import { UserConfigState } from '@glass/web/modules/userConfig/deprecatedTypes';
import selectUserIsInternal from '@glass/web/modules/userConfig/selectors/selectUserIsInternal';

export const SET_USER_CONFIG = 'SET_USER_CONFIG';

// test cases:
// 1. current: undefined, userConfig.internal: true   | isInternal: true, sideEffects: true
// 1. current: undefined, userConfig.internal: false  | isInternal: false, sideEffects: false
// 2. current: false, userConfig.internal: true       | isInternal: true, sideEffects: true
// 2. current: false, userConfig.internal: undefined  | isInternal: false, sideEffects: false
// 2. current: true, userConfig.internal: undefined   | isInternal: true, sideEffects: false
// 3. current: true, userConfig.internal: true        | isInternal: true, sideEffects: false
// 4. current: true, userConfig.internal: false       | isInternal: true, sideEffects: false

const setUserConfigAction =
  (userConfig: UserConfigState) =>
  // @ts-ignore
  (dispatch: AppDispatch, getState: AppGetState, { cookies, tracking }) => {
    const isCurrentInternal = selectUserIsInternal(getState());
    const isInternal = userConfig.internal || isCurrentInternal;

    // side effects
    if (isInternal && isCurrentInternal !== isInternal) {
      tracking.setInternal(isInternal);
      cookies.set(INTERNAL_COOKIE_NAME, TRUE_STRING_VALUE);
    }

    return dispatch({
      type: SET_USER_CONFIG,
      payload: {
        userConfig: {
          ...userConfig,
          internal: isInternal, // doing this to ensure that internal can't be set to false after being set to true
        },
      },
    });
  };

export default setUserConfigAction;
