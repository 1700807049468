import getResumeContactZip from '@glass/common/modules/resumes/selectors/getResumeContactZip';
import { selectUserProfile } from '@glass/web/modules/auth';
import { selectResumeValue } from '@glass/web/modules/resumes/selectors';
import selectGeoPostalCode from '@glass/web/modules/userConfig/selectors/selectGeoPostalCode';

import isValidPostalCode from '@glass/shared/helpers/isValidPostalCode';
import selectPaymentFormZip from '@glass/shared/modules/payments/selectors/selectPaymentFormZip';

const selectUserPostalCode = (state) =>
  [
    getResumeContactZip(selectResumeValue(state)),
    selectPaymentFormZip(state),
    selectUserProfile(state)?.zip,
    selectGeoPostalCode(state),
  ].find(isValidPostalCode) || null;

export default selectUserPostalCode;
