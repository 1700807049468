// todo: remove lodash on client
import merge from 'lodash/merge';

const mergeArraysByKey = (obj, src, key, { concatMismatches = true } = {}) => {
  const srcCopy = src.slice();
  const mergedObj = obj.map((objValue) => {
    const { [key]: objKeyValue, ...otherObj } = objValue;
    const srcIndex = srcCopy.findIndex((srcItem) => srcItem[key] === objKeyValue);
    if (srcIndex > -1) {
      const srcData = srcCopy.splice(srcIndex, 1)[0];
      return merge(otherObj, srcData);
    }
    return objValue;
  });
  if (!concatMismatches) {
    return mergedObj;
  }
  return [...mergedObj, ...srcCopy];
};

export default mergeArraysByKey;
