// standard links
import { RAPTOR_BRAND } from '@glass/common/modules/environments/brands';
import {
  BASE_PATH_1_QUERY,
  BASE_PATH_2_QUERY,
  BASE_PATH_3_QUERY,
} from '@glass/common/modules/pages/queryParams';

export const SIGNIN_PATHNAME = '/sign-in';
export const CREATE_ACCOUNT_PATHNAME = '/create-account';
export const RESET_PASSWORD_PATHNAME = '/reset-password';
export const ROCKET_LINK_PATHNAME = '/rocket-link';

export const SUPPORT_PATHNAME = '/support';
export const HOME_PATHNAME = '/';
export const PROFILE_PATHNAME =
  process.env.NEXT_PUBLIC_BRAND === RAPTOR_BRAND ? HOME_PATHNAME : '/profile';

export const PROFILE_CHANGE_PASSWORD_PATHNAME = `${
  PROFILE_PATHNAME === HOME_PATHNAME ? '' : PROFILE_PATHNAME
}/change-password`;
export const PROFILE_EDIT_PATHNAME = `${
  PROFILE_PATHNAME === HOME_PATHNAME ? '' : PROFILE_PATHNAME
}/edit`;

export const INTERNAL_PATHNAME = `/_internal`;
export const HTML_PATHNAME = `/_html`;
export const INTERNAL_HTML_PATH = `${INTERNAL_PATHNAME}${HTML_PATHNAME}`;
export const RESUME_VISUAL_TEST_STRING = 'resume-visual-regression-test';
export const RESUME_VISUAL_TEST_PATHNAME = `/${RESUME_VISUAL_TEST_STRING}`;

export const RESOURCES_PATHNAME = '/resources';

// next dynamic links - use compile dynamic link
export const BASE_PATH_1_PATHNAME = `/[${BASE_PATH_1_QUERY}]`;
export const INDUSTRY_PATHNAME = `${BASE_PATH_1_PATHNAME}/[${BASE_PATH_2_QUERY}]`;
export const JOB_TITLE_PATHNAME = `${INDUSTRY_PATHNAME}/[${BASE_PATH_3_QUERY}]`;
export const RESOURCE_POST_PATHNAME = `${RESOURCES_PATHNAME}/[slug]`;
export const RESOURCE_TAG_PATHNAME = `${RESOURCES_PATHNAME}/tags/[tag]`;
export const SITEMAP_PATHNAME = '/sitemap';

export const CV_BASE_PATH = 'cvs';
export const CURRICULUM_VITAE_BASE_PATH = 'curricula-vitae';
export const RESUME_BASE_PATH = 'resumes';
export const DEFAULT_BROWSE_PATHNAME = `/${RESUME_BASE_PATH}`;

export const BROWSE_BASE_PATHS = [RESUME_BASE_PATH, CV_BASE_PATH, CURRICULUM_VITAE_BASE_PATH];
export const CANONICAL_BASE_PATHS = [RESUME_BASE_PATH];
export const DEPRECATED_BASE_PATHS_MAPPING: Record<string, string> = {
  // TODO Taxonomy: Readd these for taxonomy second deployment
  // [DEPRECATED_CVS_BASE_PATH]: CV_BASE_PATH,
  // [DEPRECATED_CURRICULUM_VITAE_BASE_PATH]: CV_BASE_PATH,
};

export const DEPRECATED_BROWSE_BASE_PATHS = Object.keys(DEPRECATED_BASE_PATHS_MAPPING);

export const RESUME_BUILDER_INTRO_PATHNAME = `${INDUSTRY_PATHNAME}/intro`; // '/[basePath1]/[basePath2]/intro'

// dynamic regex links - use compile regex link
export const RESUME_BUILDER_REDIRECT_PATHNAME = '/r';

// default paths
export const DEFAULT_RESUME_BUILDER_BASE_PATH = 'resume-builder';
export const FREE_RESUME_BUILDER_BASE_PATH = 'free-resume-builder';
export const DEFAULT_RESUME_TEMPLATES_PATH = '/resume-templates';

export const LEGAL_PATHNAME = '/legal/[...sections]';
export const LEGAL_ARCHIVE_PAGE_TYPE = 'archive';

export const LEGAL_TERMS_SECTION = 'terms';
export const LEGAL_PRIVACY_SECTION = 'privacy';
export const LEGAL_COOKIES_SECTION = 'cookies';
export const LEGAL_DISCLAIMER_SECTION = 'disclaimer';

export const API_BASE_PATH = '/api';
export const API_GENERATE_PATH = `${API_BASE_PATH}/generate`;
export const API_GRAPHQL_PATH = `${API_BASE_PATH}/graphql`;
export const API_DOCS_GENERATE_PATH = `${API_BASE_PATH}/docs/generate`;
export const INTERNAL_AUTHORIZATION_PATH = `${API_BASE_PATH}${INTERNAL_PATHNAME}/authorize`;

export const CUSTOMERS_PATH = '/customers';
export const CUSTOMER_DETAIL_PATHNAME = `${CUSTOMERS_PATH}/[customerId]`;
export const USERS_PATH = '/users';
export const USER_DETAIL_PATHNAME = `${USERS_PATH}/[userId]`;
export const RESUMES_PATH = '/resumes';
export const RESUME_DETAIL_PATHNAME = `${RESUMES_PATH}/[resumeId]`;
