const getScreen = () => {
  const clientWidth = __BROWSER__
    ? window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    : undefined;
  const clientHeight = __BROWSER__
    ? window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    : undefined;

  const { pageXOffset: scrollXOffset, pageYOffset: scrollYOffset } = __BROWSER__ ? window : {};
  return {
    clientWidth,
    clientHeight,
    scrollXOffset,
    scrollYOffset,
  };
};

export default getScreen;
