import React from 'react';

const Cookies = __SERVER__
  ? require('@glass/web/modules/cookies/server').default
  : require('@glass/web/modules/cookies/browser').default;

// cookies are only available inside getInitialProps on the server and in the browser
const withCookies =
  ({ ssr = false } = {}) =>
  (PageComponent) => {
    function WithCookies(props) {
      // eslint-disable-next-line react/prop-types
      const { locale } = props?.router || {};
      return (
        <PageComponent {...props} cookies={__BROWSER__ ? new Cookies({ locale }) : undefined} />
      );
    }

    // Set the correct displayName in development
    if (__DEV__) {
      const displayName = PageComponent.displayName || PageComponent.name || 'Component';
      WithCookies.displayName = `withCookies(${displayName})`;
    }

    if (ssr || PageComponent.getInitialProps) {
      if (__DEV__) {
        if (!PageComponent.getInitialProps) {
          console.warn(
            'Warning: You have opted-out of Automatic Static Optimization due to `withCookies` in `pages/_app`.\n' +
              'Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n',
          );
        }
      }

      WithCookies.getInitialProps = async (context) => {
        const cookies = new Cookies(context.ctx);

        // eslint-disable-next-line no-param-reassign
        context.ctx.cookies = cookies;
        // eslint-disable-next-line no-param-reassign
        context.cookies = cookies;

        let initialProps = {};
        if (typeof PageComponent.getInitialProps === 'function') {
          initialProps = await PageComponent.getInitialProps(context);
        }

        return initialProps;
      };
    }

    return WithCookies;
  };

export default withCookies;
