import { ApolloLink } from '@apollo/client';

import tracking from '@glass/shared/modules/tracking';

const trackingLink = new ApolloLink((operation, forward) => {
  operation.setContext({ startTime: new Date() });
  return forward(operation).map((result) => {
    tracking.apiOperation(operation, { result });
    return result;
  });
});

export default trackingLink;
