import { BASE_PATH_2_QUERY, BASE_PATH_3_QUERY } from '@glass/common/modules/pages/queryParams';
import { LOW_DASH_STRING } from '@glass/common/modules/strings/constants';
import stepData from '@glass/web/modules/resumes/router/stepData';
import { ROOT_STEP } from '@glass/web/modules/resumes/steps';

const getBuilderSectionStepParams = ({
  [BASE_PATH_2_QUERY]: section,
  skip = [],
  priority = [],
  ...params
}) => {
  if (params.section || params.step) {
    throw new Error(`invalid props being passed ${JSON.stringify(params)}`);
  }

  if (!section) {
    console.error('NO SECTION');
    return {};
  }

  const key = section.split(LOW_DASH_STRING)[0];
  if (!stepData[key]) {
    throw new Error(`no step data for ${section}`);
  }
  const { steps } = stepData[key];
  let firstSectionStep = priority.find((pri) => steps.includes(pri));
  if (!firstSectionStep) {
    firstSectionStep = steps?.find((step) => !skip.includes(step));
  }

  const step = firstSectionStep === ROOT_STEP ? undefined : firstSectionStep;
  return {
    ...params,
    [BASE_PATH_2_QUERY]: section,
    [BASE_PATH_3_QUERY]: step,
  };
};

export default getBuilderSectionStepParams;
