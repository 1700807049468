import { ERROR_SEVERITY, SeverityType } from '@glass/common/modules/constants/severities';

export const QUEUE_SNACKBAR = 'QUEUE_SNACKBAR';
export const PROCESS_SNACKBAR = 'PROCESS_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const GLOBAL_SNACKBAR_REDUCER_KEY = 'globalSnackbar';

export type SnackbarOptionsType =
  | {
      title: string;
      message?: string;
      severity?: SeverityType;
      autoHideDuration?: number;
    }
  | {
      title?: string;
      message: string;
      severity?: SeverityType;
      autoHideDuration?: number;
    }
  | {
      title?: string;
      message?: string;
      severity: typeof ERROR_SEVERITY;
      autoHideDuration?: number;
    };

export type SnackbarPayloadType = SnackbarOptionsType & {
  key: number;
};

export type GlobalSnackbarState = {
  open: boolean;
  queue: SnackbarPayloadType[];
  messageInfo?: SnackbarPayloadType | null;
};

const initialState: GlobalSnackbarState = {
  open: false,
  queue: [],
  messageInfo: null,
};

const processQueue = (queue: any, state: any) => {
  if (queue.length > 0) {
    return {
      open: true,
      messageInfo: queue[0],
      queue: [...queue.slice(1)],
    };
  }
  return state;
};

// eslint-disable-next-line default-param-last
export default function globalSnackbar(
  state = initialState,
  action: {
    type: string;
    payload?: SnackbarPayloadType;
  },
) {
  let newQueue;
  switch (action.type) {
    case QUEUE_SNACKBAR:
      // a message or title are required
      if (!action.payload?.message && !action.payload?.title) {
        return state;
      }
      newQueue = [...state.queue, action.payload];
      if (state.open) {
        return {
          ...state,
          queue: newQueue,
        };
      }
      return processQueue(newQueue, state);
    case PROCESS_SNACKBAR:
      return processQueue(state.queue, state);
    case CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}

export const selectSnackbarMessage = (state: { [x: string]: GlobalSnackbarState }) =>
  state[GLOBAL_SNACKBAR_REDUCER_KEY].messageInfo;

export const selectIsSnackbarOpen = (state: { [x: string]: GlobalSnackbarState }) =>
  state[GLOBAL_SNACKBAR_REDUCER_KEY].open;
