import isValue from '@glass/common/modules/utils/isValue';
import {
  GOOGLE_AD_WORDS_COOKIE_BETA_NAME,
  GOOGLE_AD_WORDS_COOKIE_NAME,
} from '@glass/web/modules/cookies/names';
import parseBrowserQuery from '@glass/web/modules/url/parseBrowserQuery';

import findCookieValue from '@glass/shared/helpers/findCookieValue';
import findObjectValue from '@glass/shared/helpers/findObjectValue';

const QUERY_PARAMS = ['gclid', 'gbraid', 'wbraid'];
const COOKIE_KEYS = [GOOGLE_AD_WORDS_COOKIE_NAME, GOOGLE_AD_WORDS_COOKIE_BETA_NAME];

const getGoogleClickId = ({ query: queryParams, cookies }) => {
  let value = null;
  const query = queryParams ?? parseBrowserQuery();

  if (query) {
    value = findObjectValue(query, QUERY_PARAMS);
  }
  if (!isValue(value) && cookies) {
    value = findCookieValue(cookies, COOKIE_KEYS);
  }
  return value;
};

export default getGoogleClickId;
