// todo: remove lodash on client
import startCase from 'lodash/startCase';

import throwBrowser from '@glass/common/modules/errors/throwBrowser';
import makeWorder from '@glass/common/modules/strings/makeWorder';
import postFixWord from '@glass/common/modules/strings/postFixWord';

export const SINGULAR_KEY = 'singular';
export const PLURAL_KEY = 'plural';
export const CAPITALIZED_KEY = 'capitalized';
export const ABBREVIATED_KEY = 'abbreviated';
export const ABBREVIATED_CAPITALIZED_KEY = 'abbreviatedCapitalized';
export const PLURAL_ABBREVIATED_KEY = 'pluralAbbreviated';
export const PLURAL_CAPITALIZED_KEY = 'pluralCapitalized';
export const PLURAL_ABBREVIATED_CAPITALIZED_KEY = 'pluralAbbreviatedCapitalized';
export const WORDER_KEY = 'worder';
export const WORDER_CAPITALIZED_KEY = 'worderCapitalized';
export const WORDING_KEY = 'wording';
export const WORDING_CAPITALIZED_KEY = 'wordingCapitalized';
export const PAST_KEY = 'past';
export const PAST_CAPITALIZED_KEY = 'pastCapitalized';
export const ADVERB_KEY = 'adverb';
export const ADVERB_CAPITALIZED_KEY = 'adverbCapitalized';

export type VariationKey =
  | typeof SINGULAR_KEY
  | typeof PLURAL_KEY
  | typeof CAPITALIZED_KEY
  | typeof ABBREVIATED_KEY
  | typeof ABBREVIATED_CAPITALIZED_KEY
  | typeof PLURAL_ABBREVIATED_KEY
  | typeof PLURAL_CAPITALIZED_KEY
  | typeof PLURAL_ABBREVIATED_CAPITALIZED_KEY
  | typeof WORDER_KEY
  | typeof WORDER_CAPITALIZED_KEY
  | typeof WORDING_KEY
  | typeof WORDING_CAPITALIZED_KEY
  | typeof PAST_KEY
  | typeof PAST_CAPITALIZED_KEY
  | typeof ADVERB_KEY
  | typeof ADVERB_CAPITALIZED_KEY;

export type StringObject = {
  [key in VariationKey]?: string;
};

export const createWorderWordFn =
  (key: VariationKey, ending?: string) =>
  ({ [key]: word }: StringObject) =>
    makeWorder(word || '', ending);

export const createStartCaseWordFn =
  (key: VariationKey) =>
  ({ [key]: word }: StringObject) =>
    startCase(word);

export const createPostFixWordFn =
  (key: VariationKey, postfix?: string) =>
  ({ [key]: word }: StringObject) =>
    postFixWord(word || '', postfix);

export const DEFAULT_CONTENT_CREATE_FUNCTIONS = {
  [CAPITALIZED_KEY]: createStartCaseWordFn(SINGULAR_KEY),
};

export type CreateWordVariationsOptions = {
  isBrowserSafe?: boolean;
  defaultKey?: VariationKey;
};

export const createWordVariations = (
  defaults: StringObject,
  keys: Array<VariationKey>,
  createContentWordFns: { [key: string]: (words: StringObject) => string },
  { isBrowserSafe = false, defaultKey = SINGULAR_KEY }: CreateWordVariationsOptions = {},
) => {
  if (!isBrowserSafe) {
    throwBrowser('createContentVariations');
  }

  return (keys || []).reduce((words: StringObject, key: VariationKey): StringObject => {
    const word = words[key] || createContentWordFns[key]?.(words) || words[defaultKey] || null;

    if (!word) {
      return words;
    }

    return {
      ...words,
      [key]: word,
    };
  }, defaults || {});
};

export default createWordVariations;
