const vercelEnvVars = {
  vercelEnv: process.env.NEXT_PUBLIC_VERCEL_ENV,
  vercelUrl: process.env.NEXT_PUBLIC_VERCEL_URL,
  vercelGitProvider: process.env.NEXT_PUBLIC_VERCEL_GIT_PROVIDER,
  vercelGitRepoSlug: process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG,
  vercelGitRepoId: process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_ID,
  vercelGitCommitRef: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
  vercelGitCommitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  vercelGitPullRequestId: process.env.NEXT_PUBLIC_VERCEL_GIT_PULL_REQUEST_ID,
};

export default vercelEnvVars;
