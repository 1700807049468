import isValidAssignmentVariation from '@glass/common/modules/assignments/isValidAssignmentVariation';
import logAssignments from '@glass/common/modules/assignments/logAssignments';
import {
  ADD_ASSIGNMENT_OVERRIDES,
  selectAssignmentConfigs,
  selectAssignments,
} from '@glass/common/modules/assignments/redux';
import {
  AssignmentOverrides,
  AssignmentVariationValue,
} from '@glass/common/modules/assignments/types';
import filterObject from '@glass/common/modules/utils/filterObject';
import isValue from '@glass/common/modules/utils/isValue';
import { AppDispatch, AppGetState } from '@glass/web/modules/redux/types';

const addAssignmentOverridesAction =
  (assignmentOverrides: AssignmentOverrides | null) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    if (process.env.NODE_ENV === 'development' && typeof window === 'undefined') {
      throw new Error('addAssignmentOverrides should not run on the server');
    }

    if (assignmentOverrides === null) {
      dispatch({
        type: ADD_ASSIGNMENT_OVERRIDES,
        payload: {
          assignmentOverrides: null,
        },
      });
    } else {
      const state = getState();
      const assignments = selectAssignments(state);
      const assignmentConfigs = selectAssignmentConfigs(state);

      const [overrides, invalids] = filterObject(
        assignmentOverrides,
        (name, value) =>
          isValue(assignments[name]) &&
          isValidAssignmentVariation(assignmentConfigs, name, value as AssignmentVariationValue),
      );

      Object.keys(invalids || []).forEach((key) => {
        throw new Error(`invalid assignment override: ${key} ${invalids?.[key]}`);
      });

      dispatch({
        type: ADD_ASSIGNMENT_OVERRIDES,
        payload: {
          assignmentOverrides: overrides,
        },
      });
    }

    logAssignments(getState());

    return null;
  };

export default addAssignmentOverridesAction;
