import addAssignmentOverridesAction from '@glass/common/modules/assignments/addAssignmentOverridesAction';
import createAssignments from '@glass/common/modules/assignments/createAssignments';
import {
  ADD_ASSIGNMENTS,
  selectIsAssignmentReady,
  selectAssignments,
} from '@glass/common/modules/assignments/redux';
import { ASSIGNMENT_OVERRIDES_QUERY } from '@glass/common/modules/pages/queryParams';
import { parseObjectSlug } from '@glass/common/modules/utils/objectSlug';
import { selectIsUser } from '@glass/web/modules/auth';
import { ASSIGNMENT_COOKIE_NAME } from '@glass/web/modules/cookies/names';
import promptInternalDialog from '@glass/web/modules/internal/promptInternalDialog';
import setUserConfigAction from '@glass/web/modules/userConfig/actions/setUserConfigAction';
import selectUserAssignmentId from '@glass/web/modules/userConfig/selectors/selectUserAssignmentId';
import selectUserConfig from '@glass/web/modules/userConfig/selectors/selectUserConfig';
import selectUserIsInternal from '@glass/web/modules/userConfig/selectors/selectUserIsInternal';

const INTERNAL_WINDOW_KEY = '_INTERNAL';

const addAssignmentsAction =
  (assignmentIdProp) =>
  (dispatch, getState, { assignmentConfigs, tracking }) => {
    const globalState = getState();
    const assignmentIsReady = selectIsAssignmentReady(globalState);
    const assignmentId = assignmentIdProp || selectUserAssignmentId(globalState);
    const assignments = createAssignments(assignmentConfigs, assignmentId);

    dispatch({
      type: ADD_ASSIGNMENTS,
      payload: {
        configs: assignmentConfigs,
        assignments,
        assignmentId,
      },
    });

    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      const overrideQuery = searchParams.get(ASSIGNMENT_OVERRIDES_QUERY);
      if (overrideQuery) {
        if (overrideQuery === 'pick') {
          dispatch(
            setUserConfigAction({
              internal: true,
            }),
          );
          dispatch(promptInternalDialog());
        } else {
          const queryAssignmentOverrides = parseObjectSlug(overrideQuery, {
            settingsDelimiter: ',',
            equalDelimiter: ':',
          });
          if (Object.keys(queryAssignmentOverrides).length > 0) {
            dispatch(
              setUserConfigAction({
                internal: true,
              }),
            );
            dispatch(addAssignmentOverridesAction(queryAssignmentOverrides));
          }
        }
      }
    }

    // this needs to occur after ADD_ASSIGNMENTS action
    tracking.assignments({ previouslyInitialized: assignmentIsReady });
  };

const initAssignmentsAction =
  (assignmentIdProp) =>
  (dispatch, getState, { assignmentConfigs, cookies }) => {
    if (!assignmentConfigs) {
      if (__DEV__) {
        console.warn('no assignment configs');
      }
      return null;
    }

    const globalState = getState();

    if (__SERVER__ && !selectIsUser(globalState)) {
      throw new Error('initAssignmentsAction should not run on the server without a user');
    }

    const userConfigAssignmentId = selectUserConfig(globalState)?.assignmentId;
    const assignmentId = assignmentIdProp || selectUserAssignmentId(globalState);

    // it's possible that user config is out of sync if a user logged in so store in user config if changed
    if (assignmentId !== userConfigAssignmentId) {
      dispatch(setUserConfigAction({ assignmentId }));
      cookies.set(ASSIGNMENT_COOKIE_NAME, assignmentId);
    }

    dispatch(addAssignmentsAction(assignmentId));

    if (__BROWSER__ && window && selectUserIsInternal(globalState)) {
      const assignments = selectAssignments(globalState);
      window[INTERNAL_WINDOW_KEY] = {
        ...(window[INTERNAL_WINDOW_KEY] || {}),
        assignments,
        actions: {
          ...(window[INTERNAL_WINDOW_KEY]?.actions || {}),
          addAssignmentOverride: (name, variation) =>
            dispatch(addAssignmentOverridesAction({ [name]: variation })),
        },
      };
    }

    return null;
  };

export default initAssignmentsAction;
