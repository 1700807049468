import iterateBuilderSections from '@glass/common/modules/resumes/iterateBuilderSections';
import { Section } from '@glass/web/modules/resumes/router/sections';
import { StepData } from '@glass/web/modules/resumes/router/stepData';
import { Step } from '@glass/web/modules/resumes/router/steps';

const iterateBuilderSteps = (
  callback: (step: Step, section: Section, sectionData: StepData) => void,
  options = {},
) => {
  iterateBuilderSections((sectionData: StepData, section: Section) => {
    const { steps = [] } = sectionData;
    steps.forEach((step) => callback(step, section, sectionData));
  }, options);
};

export default iterateBuilderSteps;
