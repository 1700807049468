import NextApp from 'next/app';
import React from 'react';

import setAppLoadingAction from '@glass/web/modules/app/setAppLoadingAction';

import ErrorBoundary from '@glass/shared/components/Error/ErrorBoundary';
import ErrorMessageBox from '@glass/shared/components/Error/ErrorMessageBox';
import Tracking from '@glass/shared/modules/tracking/Tracking';
import appTracking from '@glass/shared/modules/tracking/appTracking';

const handleErrorEvent = ({ error, message }) => {
  appTracking.exception(error, {
    message,
    action: 'handleErrorEvent',
    label: error?.name,
  });
};

class App extends NextApp {
  constructor(props) {
    super(props);
    this.handleRouteChangeStart = this.handleRouteChangeStart.bind(this);
    this.handlePageChangeComplete = this.handlePageChangeComplete.bind(this);
    this.handleRouteChangeError = this.handleRouteChangeError.bind(this);
  }

  handleRouteChangeStart(path, { shallow }) {
    const { store } = this.props;
    if (__BROWSER__ && process.env.NEXT_PUBLIC_DEBUG === 'router') {
      console.info(`[route start]: ${path}, ${shallow}`);
    }
    store.dispatch(setAppLoadingAction());
  }

  handlePageChangeComplete(path, { shallow }) {
    const { store } = this.props;
    if (__BROWSER__ && process.env.NEXT_PUBLIC_DEBUG === 'router') {
      console.info(`[route complete]: ${path}, ${shallow}`);
    }
    store.dispatch(setAppLoadingAction(false));
    appTracking.pageView({ shallow });
  }

  handleRouteChangeError() {
    const { store } = this.props;
    if (__BROWSER__ && process.env.NEXT_PUBLIC_DEBUG === 'router') {
      console.info(`[route error]`);
    }
    store.dispatch(setAppLoadingAction(false));
  }

  componentWillUnmount() {
    const { router } = this.props;
    router.events.off('routeChangeStart', this.handleRouteChangeStart);
    router.events.off('routeChangeComplete', this.handlePageChangeComplete);
    router.events.off('routeChangeError', this.handleRouteChangeError);
    window.removeEventListener('error', handleErrorEvent);
  }

  componentDidMount() {
    const { router } = this.props;
    appTracking.pageView({ action: Tracking.ACTIONS.START });
    router.events.on('routeChangeStart', this.handleRouteChangeStart);
    router.events.on('routeChangeComplete', this.handlePageChangeComplete);
    router.events.on('routeChangeError', this.handleRouteChangeError);
    window.addEventListener('error', handleErrorEvent);
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <ErrorBoundary fallback={ErrorMessageBox} p={1}>
        <Component {...pageProps} />
      </ErrorBoundary>
    );
  }
}

export default App;
