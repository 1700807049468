import { LG, MD, SM, XL, XS } from '@glass/web/modules/theme/breakpointKeys';
import { grey } from '@glass/web/modules/theme/colors';
import type { ThemeProps } from '@glass/web/modules/theme/types';

export const SPACING_UNIT = 12;

const commonTheme: ThemeProps = {
  muiTheme: {
    palette: {
      background: {
        default: grey[100],
      },
      grey: {
        main: grey[300],
        dark: grey[400],
      },
    },
    typography: {
      fontSize: 16,
      fontFamily:
        'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Noto Sans","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    spacing: SPACING_UNIT,
  },
  responsiveFontSizeOptions: {
    breakpoints: [XS, SM, MD, LG, XL],
    factor: 3,
  },
  usePrefersDarkMode: true,
};

export default commonTheme;
