import { AUTH_COOKIE_NAME } from '@glass/web/modules/cookies/names';

import setUserAuthAction from '@glass/shared/modules/auth/setUserAuthAction';

const setAuthAction =
  (auth) =>
  (dispatch, getState, { cookies, tracking }) => {
    const { user, token } = auth || {};
    if (token) {
      cookies.set(AUTH_COOKIE_NAME, token);
    }
    dispatch(setUserAuthAction({ user, token }));
    // must be after setUserAuthAction
    tracking.registerUser(user);
    return user;
  };

export default setAuthAction;
