import { INFO_SEVERITY } from '@glass/common/modules/constants/severities';
import { QUEUE_SNACKBAR, SnackbarOptionsType } from '@glass/web/modules/globalSnackbar';

const queueGlobalSnackbarAction = ({
  title,
  message,
  severity = INFO_SEVERITY,
  autoHideDuration,
}: SnackbarOptionsType) => ({
  type: QUEUE_SNACKBAR,
  payload: {
    title,
    message,
    severity,
    autoHideDuration,
    key: new Date().getTime(),
  },
});

export default queueGlobalSnackbarAction;
