import Router from 'next/router';

import { PRICING_PACKAGE_QUERY, SNACK_BAR_QUERY } from '@glass/common/modules/pages/queryParams';
import createUrl from '@glass/common/modules/url/createUrl';
import pathToURL from '@glass/common/modules/url/pathToURL';
import isValue from '@glass/common/modules/utils/isValue';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';
import handleSnackbarQueryAction from '@glass/web/modules/globalSnackbar/handleSnackbarQueryAction';

import handlePackageQueryAction from '@glass/shared/modules/packages/handlePackageQueryAction';
import tracking from '@glass/shared/modules/tracking';
import handleCookieQueryParamAction, {
  QUERY_COOKIE_MAP,
} from '@glass/shared/modules/userConfig/handleCookieQueryParamAction';
import waitForCondition from '@glass/shared/modules/userConfig/waitForCondition';

const ACTIONS_CONFIGS = [
  {
    queryParam: PRICING_PACKAGE_QUERY,
    action: handlePackageQueryAction,
  },
  {
    queryParam: SNACK_BAR_QUERY,
    action: handleSnackbarQueryAction,
  },
  ...Object.keys(QUERY_COOKIE_MAP).map((key) => ({
    queryParam: key,
    action: handleCookieQueryParamAction,
  })),
];

const initQueryParamsAction = () => async (dispatch) => {
  await waitForCondition(() => Router.isReady).catch(tracking.exception);
  const initialPath = Router.asPath;
  const {
    query,
    url: { pathname },
  } = pathToURL(Router.asPath, true);

  return Promise.all(
    ACTIONS_CONFIGS.map((config) => {
      const { queryParam, redirect: isRedirect = true, action } = config;
      const queryParamValue = query[queryParam];
      if (isValue(queryParamValue) && typeof action === 'function') {
        return Promise.resolve(dispatch(action(queryParamValue, config, query))).then((result) => {
          const { _newQuery: newQuery } = result || {};
          if (newQuery) {
            return newQuery;
          }
          if (isRedirect) {
            return {
              [queryParam]: null,
            };
          }
          return null;
        });
      }
      return null;
    }),
  ).then((queryParams) => {
    const queryRedirect = queryParams.reduce(
      (agg, result) => ({
        ...agg,
        ...result,
      }),
      query,
    );
    const path = createUrl({ pathname, query: removeEmptyKeys(queryRedirect) });
    if (path !== initialPath) {
      return Router.replace(path, undefined, { shallow: true, scroll: false });
    }
    return null;
  });
};

export default initQueryParamsAction;
