export const EVENT_HISTORY_REDUCER_KEY = 'eventHistory';
export const ADD_EVENT_HISTORY = 'ADD_EVENT_HISTORY';

const initialState = {};

const NO_ID = 'NO_ID';

const eventHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EVENT_HISTORY:
      return {
        ...state,
        [action.payload.event]: {
          ...(state[action.payload.event] || null),
          [action.payload.id]: {
            createdAt: action.payload.createdAt,
          },
        },
      };
    default:
      return state;
  }
};

export default eventHistoryReducer;

export const selectEventCreatedAt =
  ({ event, id = NO_ID }) =>
  (state) =>
    state[EVENT_HISTORY_REDUCER_KEY][event]?.[id]?.createdAt;

export const selectEventCreatedAge =
  ({ event, id = NO_ID, now = Date.now() }) =>
  (state) => {
    const createdAt = selectEventCreatedAt({ event, id })(state);

    if (!createdAt) {
      return null;
    }

    const age = now - createdAt;

    if (typeof age !== 'number' || age < 0) {
      return null;
    }

    return age;
  };

export const addEventAction = ({ event, id = NO_ID, createdAt = Date.now() }) => {
  if (typeof event !== 'string') {
    throw new Error('event is required');
  }
  if (typeof id !== 'string') {
    throw new Error('id is required');
  }
  return {
    type: ADD_EVENT_HISTORY,
    payload: {
      event,
      id,
      createdAt,
    },
  };
};
