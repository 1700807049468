import getResumeContactFirstName from '@glass/common/modules/resumes/selectors/getResumeContactFirstName';
import getResumeContactLastName from '@glass/common/modules/resumes/selectors/getResumeContactLastName';
import type { ResumeType } from '@glass/common/modules/resumes/types';
import { SPACE_STRING } from '@glass/common/modules/strings/constants';
import filterJoin from '@glass/common/modules/strings/filterJoin';

const getResumeContactDisplayName = (resume: ResumeType) =>
  filterJoin([getResumeContactFirstName(resume), getResumeContactLastName(resume)], SPACE_STRING);

export default getResumeContactDisplayName;
