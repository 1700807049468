import cleanInput from '@glass/common/modules/utils/cleanInput';
import { selectUser } from '@glass/web/modules/auth';
import { selectResumeValueSelector } from '@glass/web/modules/resumes/selectors';

import { selectAuthProps } from '@glass/shared/modules/authForm';
import contactPath from '@glass/shared/modules/resumes/paths/contactPath';

const selectUserContactEmail = (state) =>
  cleanInput(selectUser(state)?.email) ||
  cleanInput(selectResumeValueSelector(state, contactPath)?.email) ||
  cleanInput(selectAuthProps(state)?.email);

export default selectUserContactEmail;
