export const SUCCESS_SEVERITY = 'success';
export const WARNING_SEVERITY = 'warning';
export const ERROR_SEVERITY = 'error';
export const INFO_SEVERITY = 'info';

export type SeverityType =
  | typeof SUCCESS_SEVERITY
  | typeof ERROR_SEVERITY
  | typeof INFO_SEVERITY
  | typeof WARNING_SEVERITY;
