import logAssignments from '@glass/common/modules/assignments/logAssignments';
import { selectIsAssignmentReady } from '@glass/common/modules/assignments/redux';
import selectUserAssignmentId from '@glass/web/modules/userConfig/selectors/selectUserAssignmentId';
import selectUserIsInternal from '@glass/web/modules/userConfig/selectors/selectUserIsInternal';

import initAssignmentsAction from '@glass/shared/modules/assignments/initAssignmentsAction';
import logConfig from '@glass/shared/modules/userConfig/logConfig';
import selectReady from '@glass/shared/modules/userConfig/selectors/selectReady';

let logged = false;

const subscribe = (prevState, currentState, { dispatch }) => {
  const currentIsInternal = selectUserIsInternal(currentState);
  const currentAssignmentId = selectUserAssignmentId(currentState);

  if (__BROWSER__ && currentIsInternal && !logged && selectReady(currentState)) {
    logged = true;
    logConfig(currentState);
    logAssignments(currentState);
  }

  // potential state changes below the logging above
  // assignmentId changed
  if (
    currentAssignmentId !== selectUserAssignmentId(prevState) ||
    (currentIsInternal &&
      !selectIsAssignmentReady(prevState) &&
      selectIsAssignmentReady(currentState))
  ) {
    if (currentAssignmentId) {
      dispatch(initAssignmentsAction(currentAssignmentId));
    } else if (process.env.NODE_ENV !== 'production') {
      throw new Error('assignment id changed to invalid value');
    }
  }
};

export default subscribe;
