import { selectIsAssignmentReady } from '@glass/common/modules/assignments/redux';
import { INTERESTS_REDUCER_KEY } from '@glass/web/modules/redux/constants';
import { RootState } from '@glass/web/modules/redux/types';
import createSelectReducerRehydrated from '@glass/web/modules/store/createSelectReducerRehydrated';
import selectUserConfigReady from '@glass/web/modules/userConfig/selectors/selectUserConfigReady';

import { selectIsResumesReducerHydrated } from '@glass/shared/modules/resumes/resumesReducer';

const selectIsInterestReducerHydrated = createSelectReducerRehydrated(INTERESTS_REDUCER_KEY);

const selectReady = (state: RootState) => {
  if (process.env.NEXT_PUBLIC_BRAND === 'engine') {
    return selectUserConfigReady(state);
  }

  return (
    selectIsAssignmentReady(state) &&
    selectUserConfigReady(state) &&
    selectIsInterestReducerHydrated(state) &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    selectIsResumesReducerHydrated(state)
  );
};

export default selectReady;
