import uniqBy from 'lodash/uniqBy';

import makeArray from '@glass/common/modules/utils/makeArray';

const pushStateItem = (existingItems, pushItemsProp, { isUniq, uniqKey, maxItems } = {}) => {
  let pushItems = makeArray(pushItemsProp);

  if (typeof pushItems?.[0] === 'object') {
    pushItems = pushItems.map((pushItem) => {
      if (typeof pushItem !== 'object') {
        throw new Error('inconsistent item type');
      }
      return {
        ...pushItem,
        timestamp: new Date().toISOString(),
      };
    });
  }

  let newItems = [...pushItems, ...existingItems];

  if (isUniq || !!uniqKey) {
    // null is equivalent to not using a uniqKey but needs to be undefined for uniqBy
    newItems = uniqBy(newItems, uniqKey ?? undefined);
  }
  return newItems.slice(0, maxItems ?? undefined);
};

export default pushStateItem;
