import { DASH_STRING } from '@glass/common/modules/strings/constants';

export const repeatStr = (str: string, count: number) => {
  let result = '';
  for (let i = 0; i < count; i += 1) {
    result += str;
  }
  return result;
};

export const formatTitle = (title: string, targetLength = 40) => {
  const numberOfDashes = Math.max(3, Math.floor((targetLength - title.length - 2) / 2));
  const dashes = repeatStr(DASH_STRING, numberOfDashes);
  let result = `${dashes} ${title} ${dashes}`;
  if (result.length < targetLength) {
    result += repeatStr(DASH_STRING, targetLength - result.length);
  }
  return result;
};
