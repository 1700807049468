import { SIGN_OUT } from '@glass/web/modules/auth';
import { AUTH_COOKIE_NAME, EMAIL_AUTH_COOKIE_NAME } from '@glass/web/modules/cookies/names';

const clearAuthAction =
  () =>
  (dispatch, getState, { cookies }) => {
    cookies.remove(EMAIL_AUTH_COOKIE_NAME);
    cookies.remove(AUTH_COOKIE_NAME);
    dispatch({ type: SIGN_OUT });
  };

export default clearAuthAction;
