import initAppLocaleAction from '@glass/web/modules/app/initAppLocaleAction';

import initAttributionCookieAction from '@glass/shared/modules/userConfig/initAttributionCookieAction';
import initAuthAction from '@glass/shared/modules/userConfig/initAuthAction';
import initQueryParamsAction from '@glass/shared/modules/userConfig/initQueryParamsAction';
import initSessionAction from '@glass/shared/modules/userConfig/initSessionAction';
import initTrackingAction from '@glass/shared/modules/userConfig/initTrackingAction';

const initUserConfigAction = () => async (dispatch) => {
  // WARNING only dispatch state that won't affect rendering, if it does assume the server html is cached
  // (ie assignments) the rendering must be prevented (ie use Assignment Gate component)

  if (!__BROWSER__) {
    return null;
  }

  const promises = [];
  promises.push(dispatch(initAppLocaleAction()));
  promises.push(dispatch(initTrackingAction()));
  promises.push(dispatch(initSessionAction()));
  promises.push(dispatch(initQueryParamsAction()).then(() => dispatch(initAuthAction())));
  promises.push(dispatch(initAttributionCookieAction()));
  return Promise.all(promises);
};

export default initUserConfigAction;
