import isValue from '@glass/common/modules/utils/isValue';

/**
 * createSearchString polyfill as the k6 Go environment does not detect/has the native URLSearchParams
 * @param queryObj
 * @returns
 */
const createSearchString = (queryObj?: Record<string, unknown> | null) => {
  if (!queryObj) {
    return '';
  }

  const searchParams: string[] = [];

  Object.keys(queryObj).forEach((key) => {
    const value = queryObj[key];
    if (!isValue(value)) {
      return;
    }
    const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
    searchParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(stringValue)}`);
  });

  if (searchParams.length === 0) {
    return '';
  }

  return `?${searchParams.join('&')}`;
};

export default createSearchString;
