import throwNotBrowser from '@glass/common/modules/errors/throwNotBrowser';
import { ROCKET_LINK_PATHNAME } from '@glass/common/modules/pages/paths';
import {
  USER_AUTH_QUERY,
  FAIL_QUERY,
  MESSAGE_QUERY,
  NEXT_QUERY,
  DEPRECATED_KLAVIYO_EMAIL_AUTH_QUERY,
  DEPRECATED_KLAVIYO_IDENTIFIER_QUERY,
} from '@glass/common/modules/pages/queryParams';
import filterJoin from '@glass/common/modules/strings/filterJoin';
import { selectUser } from '@glass/web/modules/auth';
import { AUTH_COOKIE_NAME } from '@glass/web/modules/cookies/names';
import { PROMPT_LOGIN } from '@glass/web/modules/graphql/types';
import getBrowserLanguage from '@glass/web/modules/locales/getBrowserLanguage';
import getBrowserTimezone from '@glass/web/modules/locales/getBrowserTimezone';
import parseBrowserQuery from '@glass/web/modules/url/parseBrowserQuery';
import setUserConfigAction from '@glass/web/modules/userConfig/actions/setUserConfigAction';
import { AUTH_READY_KEY } from '@glass/web/modules/userConfig/selectors/selectIsAuthReady';

import { ROCKET_LINK_ACTION } from '@glass/shared/modules/auth/authActionTypes';
import clearAuthAction from '@glass/shared/modules/auth/clearAuthAction';
import authenticateEmailMutation from '@glass/shared/modules/auth/queries/authenticateEmail.graphql';
import authenticateUserMutation from '@glass/shared/modules/auth/queries/authenticateUser.graphql';
import setAuthAction from '@glass/shared/modules/auth/setAuthAction';
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import { EMAIL_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';
import handleErrorActions from '@glass/shared/modules/errors/handleErrorActions';
import redirect from '@glass/shared/modules/router/redirect';

const REDIRECT_PARAMS = { replace: true, shallow: true, scroll: false };

const handleFailedEmailAuthAction =
  (error, { failPath, url, next }) =>
  (dispatch, getState, { tracking }) => {
    if (error) {
      tracking.exception(error);
    }
    const title = 'That link has expired';
    const message = 'Please enter your email to try again';
    if (failPath === ROCKET_LINK_PATHNAME && !selectUser(getState())) {
      return redirect(undefined, url.toString(), REDIRECT_PARAMS).then(() =>
        dispatch(
          promptAuthAction({
            title,
            actionType: ROCKET_LINK_ACTION,
            view: EMAIL_AUTH_VIEW,
            message,
            next,
            inDialog: true,
          }),
        ),
      );
    }

    const failSearch = new URLSearchParams();
    failSearch.append(MESSAGE_QUERY, message);
    failSearch.append(NEXT_QUERY, next);
    const nextFailPath = `${failPath}?${failSearch.toString()}`;
    return redirect(undefined, nextFailPath, REDIRECT_PARAMS);
  };

const initEmailAuthAction =
  () =>
  async (dispatch, getState, { client }) => {
    const url = new URL(window.location.href);
    const emailToken = url.searchParams.get(USER_AUTH_QUERY);

    if (!emailToken) {
      return null;
    }

    const failPath = url.searchParams.get(FAIL_QUERY) || ROCKET_LINK_PATHNAME;
    url.searchParams.delete(USER_AUTH_QUERY);
    url.searchParams.delete(FAIL_QUERY);
    const next = filterJoin([url.pathname, url.searchParams.toString()], '?');

    const success = () => redirect(undefined, next, REDIRECT_PARAMS);

    return client
      .mutate({
        mutation: authenticateEmailMutation,
        variables: {
          token: emailToken,
          browserLanguage: getBrowserLanguage(),
          browserTimezone: getBrowserTimezone(),
        },
      })
      .then(({ data }) => dispatch(setAuthAction(data.authenticateEmail)))
      .then(success)
      .catch((err) => {
        return dispatch(handleFailedEmailAuthAction(err, { failPath, url, next }));
      });
  };

// eslint-disable-next-line camelcase
const DEPRECATED_initKlaviyoEmailAuthAction = () => async (dispatch) => {
  const url = new URL(window.location.href);
  const emailToken = url.searchParams.get(DEPRECATED_KLAVIYO_EMAIL_AUTH_QUERY);

  if (!emailToken) {
    return null;
  }

  const failPath = url.searchParams.get(FAIL_QUERY) || ROCKET_LINK_PATHNAME;
  url.searchParams.delete(DEPRECATED_KLAVIYO_EMAIL_AUTH_QUERY);
  url.searchParams.delete(FAIL_QUERY);
  url.searchParams.delete(DEPRECATED_KLAVIYO_IDENTIFIER_QUERY);
  const next = filterJoin([url.pathname, url.searchParams.toString()], '?');
  return dispatch(handleFailedEmailAuthAction(null, { failPath, url, next }));
};

const initUserAuthAction =
  () =>
  (dispatch, getState, { client, cookies }) => {
    const authToken = cookies.get(AUTH_COOKIE_NAME);
    if (!authToken) {
      return null;
    }

    return client
      .mutate({
        mutation: authenticateUserMutation,
        variables: {
          browserLanguage: getBrowserLanguage(),
          browserTimezone: getBrowserTimezone(),
        },
      })
      .then(({ data }) => dispatch(setAuthAction(data.authenticateUser)))
      .catch((error) =>
        dispatch(
          handleErrorActions(error, {
            [PROMPT_LOGIN]: clearAuthAction,
          }),
        ),
      );
  };

const initAuthAction =
  () =>
  async (dispatch, getState, { tracking }) => {
    throwNotBrowser('initAuthAction');
    try {
      const query = parseBrowserQuery();

      if (query?.[USER_AUTH_QUERY]) {
        await dispatch(initEmailAuthAction());
      } else if (query?.[DEPRECATED_KLAVIYO_EMAIL_AUTH_QUERY]) {
        await dispatch(DEPRECATED_initKlaviyoEmailAuthAction());
      }

      if (!selectUser(getState())) {
        await dispatch(initUserAuthAction());
      }
    } catch (error) {
      tracking.exception(error);
    }
    return dispatch(setUserConfigAction({ [AUTH_READY_KEY]: true }));
  };

export default initAuthAction;
