import PropTypes from 'prop-types';
import React from 'react';

import Box from '@glass/web/components/base/Box';
import Typography from '@glass/web/components/base/Typography';

function ErrorMessageBox({ message, statusCode, ...props }) {
  return (
    <Box {...props}>
      <Typography gutterBottom variant="h6">
        {statusCode ? `${statusCode} | ${message}` : message}
      </Typography>
      <Typography>
        We apologize for the inconvenience, our team has been notified and is investigating a
        resolution as soon as possible. Please try again.
      </Typography>
    </Box>
  );
}

ErrorMessageBox.propTypes = {
  details: PropTypes.string,
  message: PropTypes.string,
  statusCode: PropTypes.number,
};

ErrorMessageBox.defaultProps = {
  message: 'Oops, something went wrong!',
};

export default ErrorMessageBox;
