export const RULES_TYPE_ERROR = 'rules must be an Object';
export const VERSION_MISSING_ERROR = `version is missing in rules`;
export const INVALID_VERSION_ERROR = `rules version is invalid`;
export const NO_USER_ERROR = 'User is missing required auth for applying package';
export const EXPIRATION_DATE_TYPE_ERROR = 'expirationDate must be a Date';
export const EXPIRATION_TIME_TYPE_ERROR = 'expirationTime must be a String';
export const REF_DATE_TYPE_ERROR = 'refDate must be a Date';
export const CLIENT_IDENTIFIER_REQUIREMENT_ERROR = 'Package clientIdentifier is required';
export const DEFAULT_USER_ERROR =
  'An unexpected error occurred, please try again or contact support';

export type ErrorType = {
  message: string;
};
