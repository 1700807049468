import { DEFAULT_RESUME_BUILDER_BASE_PATH } from '@glass/common/modules/pages/paths';
import {
  BASE_PATH_1_QUERY,
  BASE_PATH_2_QUERY,
  BASE_PATH_3_QUERY,
  RESUME_ID_QUERY,
} from '@glass/common/modules/pages/queryParams';
import iterateBuilderSteps from '@glass/common/modules/resumes/iterateBuilderSteps';
import {
  RESUME_METADATA_BASE_PATH_FIELD,
  RESUME_METADATA_COMPLETED_STEPS_FIELD,
  RESUME_METADATA_FIELD,
} from '@glass/web/modules/resumes/fieldKeys';
import generateSectionStepKey from '@glass/web/modules/resumes/router/generateSectionStepKey';
import {
  DOWNLOAD_SECTION,
  MISCELLANEOUS_SECTION,
} from '@glass/web/modules/resumes/router/sections';
import { INTRO_STEP } from '@glass/web/modules/resumes/steps';

import getBuilderSectionStepParams from '@glass/shared/components/Link/ResumeBuilderLink/getBuilderSectionStepParams';
import { selectResumeBuilderBasePath } from '@glass/shared/modules/content/contentReducer';
import { createSelectResumeCompletedSteps } from '@glass/shared/modules/resumes/resumesReducer';

const getNextBuilderCompletedStep = (completedSteps) => {
  let lastCompletedSection;
  let lastCompletedStep;
  if (completedSteps) {
    iterateBuilderSteps((step, section) => {
      const key = generateSectionStepKey({
        [BASE_PATH_2_QUERY]: section,
        [BASE_PATH_3_QUERY]: step,
      });
      if (completedSteps[key]) {
        lastCompletedSection = section;
        lastCompletedStep = step;
      }
    });
  }

  const section =
    !lastCompletedSection || lastCompletedSection === MISCELLANEOUS_SECTION
      ? DOWNLOAD_SECTION
      : lastCompletedSection;

  return getBuilderSectionStepParams({
    [BASE_PATH_2_QUERY]: section,
    priority: lastCompletedStep && section !== DOWNLOAD_SECTION ? [lastCompletedStep] : undefined,
    skip: section === DOWNLOAD_SECTION ? [INTRO_STEP] : undefined,
  });
};

const createSelectNextResumeBuilderParams = (resume) => (state) => {
  if (!resume?.id) {
    return null;
  }

  const completedSteps = {
    ...(state ? createSelectResumeCompletedSteps(resume.id)(state) || null : null),
    ...(resume?.[RESUME_METADATA_FIELD]?.[RESUME_METADATA_COMPLETED_STEPS_FIELD] || null),
  };

  const { [BASE_PATH_2_QUERY]: section, [BASE_PATH_3_QUERY]: step } =
    getNextBuilderCompletedStep(completedSteps);

  return {
    [BASE_PATH_1_QUERY]:
      resume?.[RESUME_METADATA_FIELD]?.[RESUME_METADATA_BASE_PATH_FIELD] ||
      (state ? selectResumeBuilderBasePath(state) : null) ||
      DEFAULT_RESUME_BUILDER_BASE_PATH,
    [BASE_PATH_2_QUERY]: section,
    [BASE_PATH_3_QUERY]: step,
    [RESUME_ID_QUERY]: resume.id,
  };
};

export default createSelectNextResumeBuilderParams;
