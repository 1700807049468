import parseDisplayName from '@glass/common/modules/resumes/parseDisplayName';
import getResumeContactFirstName from '@glass/common/modules/resumes/selectors/getResumeContactFirstName';
import { selectUserProfile } from '@glass/web/modules/auth';
import { selectResumeValue } from '@glass/web/modules/resumes/selectors';

import selectPaymentFormDisplayName from '@glass/shared/modules/payments/selectors/selectPaymentFormDisplayName';

const selectUserFirstName = (state) => {
  const resume = selectResumeValue(state);
  const resumeFirstName = getResumeContactFirstName(resume);
  if (resumeFirstName) {
    return resumeFirstName;
  }

  const { displayName: paymentDisplayName } = selectPaymentFormDisplayName(state) || {};
  const paymentFormFirstName = parseDisplayName(paymentDisplayName)?.firstName || null;

  if (paymentFormFirstName) {
    return paymentFormFirstName;
  }

  const { displayName } = selectUserProfile(state) || {};
  return parseDisplayName(displayName)?.firstName || null;
};

export default selectUserFirstName;
