// Returns true if the value is not undefined, null, or an EMPTY string.
// Examples: false and 0 return true.
/**
 * Type guard that returns true if the value is not undefined, null, or an empty string.
 * Examples: false and 0 return true.
 *
 * @param val - The value to check.
 * @returns True if val is not undefined, null, or an empty string.
 */
function isValue<T>(val: T): val is Exclude<T, undefined | null | ''> {
  return val !== undefined && val !== null && val !== '' && !Number.isNaN(val);
}

export default isValue;
