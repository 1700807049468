export const AC_COUNTRY_CODE = 'AC';
export const AF_COUNTRY_CODE = 'AF';
export const AX_COUNTRY_CODE = 'AX';
export const AL_COUNTRY_CODE = 'AL';
export const DZ_COUNTRY_CODE = 'DZ';
export const AS_COUNTRY_CODE = 'AS';
export const AD_COUNTRY_CODE = 'AD';
export const AO_COUNTRY_CODE = 'AO';
export const AI_COUNTRY_CODE = 'AI';
export const AQ_COUNTRY_CODE = 'AQ';
export const AG_COUNTRY_CODE = 'AG';
export const AR_COUNTRY_CODE = 'AR';
export const AM_COUNTRY_CODE = 'AM';
export const AW_COUNTRY_CODE = 'AW';
export const AU_COUNTRY_CODE = 'AU';
export const AT_COUNTRY_CODE = 'AT';
export const AZ_COUNTRY_CODE = 'AZ';
export const BS_COUNTRY_CODE = 'BS';
export const BH_COUNTRY_CODE = 'BH';
export const BD_COUNTRY_CODE = 'BD';
export const BB_COUNTRY_CODE = 'BB';
export const BY_COUNTRY_CODE = 'BY';
export const BE_COUNTRY_CODE = 'BE';
export const BZ_COUNTRY_CODE = 'BZ';
export const BJ_COUNTRY_CODE = 'BJ';
export const BM_COUNTRY_CODE = 'BM';
export const BT_COUNTRY_CODE = 'BT';
export const BO_COUNTRY_CODE = 'BO';
export const BA_COUNTRY_CODE = 'BA';
export const BW_COUNTRY_CODE = 'BW';
export const BV_COUNTRY_CODE = 'BV';
export const BR_COUNTRY_CODE = 'BR';
export const IO_COUNTRY_CODE = 'IO';
export const BN_COUNTRY_CODE = 'BN';
export const BG_COUNTRY_CODE = 'BG';
export const BF_COUNTRY_CODE = 'BF';
export const BI_COUNTRY_CODE = 'BI';
export const CV_COUNTRY_CODE = 'CV';
export const KH_COUNTRY_CODE = 'KH';
export const CM_COUNTRY_CODE = 'CM';
export const CA_COUNTRY_CODE = 'CA';
export const BQ_COUNTRY_CODE = 'BQ';
export const KY_COUNTRY_CODE = 'KY';
export const CF_COUNTRY_CODE = 'CF';
export const TD_COUNTRY_CODE = 'TD';
export const CL_COUNTRY_CODE = 'CL';
export const CN_COUNTRY_CODE = 'CN';
export const CX_COUNTRY_CODE = 'CX';
export const CC_COUNTRY_CODE = 'CC';
export const CO_COUNTRY_CODE = 'CO';
export const KM_COUNTRY_CODE = 'KM';
export const CK_COUNTRY_CODE = 'CK';
export const CR_COUNTRY_CODE = 'CR';
export const HR_COUNTRY_CODE = 'HR';
export const CU_COUNTRY_CODE = 'CU';
export const CW_COUNTRY_CODE = 'CW';
export const CY_COUNTRY_CODE = 'CY';
export const CZ_COUNTRY_CODE = 'CZ';
export const CD_COUNTRY_CODE = 'CD';
export const DK_COUNTRY_CODE = 'DK';
export const DJ_COUNTRY_CODE = 'DJ';
export const DM_COUNTRY_CODE = 'DM';
export const DO_COUNTRY_CODE = 'DO';
export const EC_COUNTRY_CODE = 'EC';
export const EG_COUNTRY_CODE = 'EG';
export const SV_COUNTRY_CODE = 'SV';
export const GQ_COUNTRY_CODE = 'GQ';
export const ER_COUNTRY_CODE = 'ER';
export const EE_COUNTRY_CODE = 'EE';
export const EU_COUNTRY_CODE = 'EU';
export const SZ_COUNTRY_CODE = 'SZ';
export const ET_COUNTRY_CODE = 'ET';
export const FK_COUNTRY_CODE = 'FK';
export const FO_COUNTRY_CODE = 'FO';
export const FJ_COUNTRY_CODE = 'FJ';
export const FI_COUNTRY_CODE = 'FI';
export const FR_COUNTRY_CODE = 'FR';
export const GF_COUNTRY_CODE = 'GF';
export const PF_COUNTRY_CODE = 'PF';
export const TF_COUNTRY_CODE = 'TF';
export const GA_COUNTRY_CODE = 'GA';
export const GM_COUNTRY_CODE = 'GM';
export const GE_COUNTRY_CODE = 'GE';
export const DE_COUNTRY_CODE = 'DE';
export const GH_COUNTRY_CODE = 'GH';
export const GI_COUNTRY_CODE = 'GI';
export const GR_COUNTRY_CODE = 'GR';
export const GL_COUNTRY_CODE = 'GL';
export const GD_COUNTRY_CODE = 'GD';
export const GP_COUNTRY_CODE = 'GP';
export const GU_COUNTRY_CODE = 'GU';
export const GT_COUNTRY_CODE = 'GT';
export const GG_COUNTRY_CODE = 'GG';
export const GN_COUNTRY_CODE = 'GN';
export const GW_COUNTRY_CODE = 'GW';
export const GY_COUNTRY_CODE = 'GY';
export const HT_COUNTRY_CODE = 'HT';
export const HM_COUNTRY_CODE = 'HM';
export const VA_COUNTRY_CODE = 'VA';
export const HN_COUNTRY_CODE = 'HN';
export const HK_COUNTRY_CODE = 'HK';
export const HU_COUNTRY_CODE = 'HU';
export const IS_COUNTRY_CODE = 'IS';
export const IN_COUNTRY_CODE = 'IN';
export const ID_COUNTRY_CODE = 'ID';
export const IR_COUNTRY_CODE = 'IR';
export const IQ_COUNTRY_CODE = 'IQ';
export const IE_COUNTRY_CODE = 'IE';
export const IM_COUNTRY_CODE = 'IM';
export const IL_COUNTRY_CODE = 'IL';
export const IT_COUNTRY_CODE = 'IT';
export const CI_COUNTRY_CODE = 'CI';
export const JM_COUNTRY_CODE = 'JM';
export const JP_COUNTRY_CODE = 'JP';
export const JE_COUNTRY_CODE = 'JE';
export const JO_COUNTRY_CODE = 'JO';
export const KZ_COUNTRY_CODE = 'KZ';
export const KE_COUNTRY_CODE = 'KE';
export const KI_COUNTRY_CODE = 'KI';
export const KW_COUNTRY_CODE = 'KW';
export const KG_COUNTRY_CODE = 'KG';
export const LA_COUNTRY_CODE = 'LA';
export const LV_COUNTRY_CODE = 'LV';
export const LB_COUNTRY_CODE = 'LB';
export const LS_COUNTRY_CODE = 'LS';
export const LR_COUNTRY_CODE = 'LR';
export const LY_COUNTRY_CODE = 'LY';
export const LI_COUNTRY_CODE = 'LI';
export const LT_COUNTRY_CODE = 'LT';
export const LU_COUNTRY_CODE = 'LU';
export const MO_COUNTRY_CODE = 'MO';
export const MG_COUNTRY_CODE = 'MG';
export const MW_COUNTRY_CODE = 'MW';
export const MY_COUNTRY_CODE = 'MY';
export const MV_COUNTRY_CODE = 'MV';
export const ML_COUNTRY_CODE = 'ML';
export const MT_COUNTRY_CODE = 'MT';
export const MH_COUNTRY_CODE = 'MH';
export const MQ_COUNTRY_CODE = 'MQ';
export const MR_COUNTRY_CODE = 'MR';
export const MU_COUNTRY_CODE = 'MU';
export const YT_COUNTRY_CODE = 'YT';
export const MX_COUNTRY_CODE = 'MX';
export const FM_COUNTRY_CODE = 'FM';
export const MD_COUNTRY_CODE = 'MD';
export const MC_COUNTRY_CODE = 'MC';
export const MN_COUNTRY_CODE = 'MN';
export const ME_COUNTRY_CODE = 'ME';
export const MS_COUNTRY_CODE = 'MS';
export const MA_COUNTRY_CODE = 'MA';
export const MZ_COUNTRY_CODE = 'MZ';
export const MM_COUNTRY_CODE = 'MM';
export const NA_COUNTRY_CODE = 'NA';
export const NR_COUNTRY_CODE = 'NR';
export const NP_COUNTRY_CODE = 'NP';
export const NL_COUNTRY_CODE = 'NL';
export const NC_COUNTRY_CODE = 'NC';
export const NZ_COUNTRY_CODE = 'NZ';
export const NI_COUNTRY_CODE = 'NI';
export const NE_COUNTRY_CODE = 'NE';
export const NG_COUNTRY_CODE = 'NG';
export const NU_COUNTRY_CODE = 'NU';
export const NF_COUNTRY_CODE = 'NF';
export const KP_COUNTRY_CODE = 'KP';
export const MK_COUNTRY_CODE = 'MK';
export const MP_COUNTRY_CODE = 'MP';
export const NO_COUNTRY_CODE = 'NO';
export const OM_COUNTRY_CODE = 'OM';
export const PK_COUNTRY_CODE = 'PK';
export const PW_COUNTRY_CODE = 'PW';
export const PS_COUNTRY_CODE = 'PS';
export const PA_COUNTRY_CODE = 'PA';
export const PG_COUNTRY_CODE = 'PG';
export const PY_COUNTRY_CODE = 'PY';
export const PE_COUNTRY_CODE = 'PE';
export const PH_COUNTRY_CODE = 'PH';
export const PN_COUNTRY_CODE = 'PN';
export const PL_COUNTRY_CODE = 'PL';
export const PT_COUNTRY_CODE = 'PT';
export const PR_COUNTRY_CODE = 'PR';
export const QA_COUNTRY_CODE = 'QA';
export const CG_COUNTRY_CODE = 'CG';
export const RE_COUNTRY_CODE = 'RE';
export const RO_COUNTRY_CODE = 'RO';
export const RU_COUNTRY_CODE = 'RU';
export const RW_COUNTRY_CODE = 'RW';
export const BL_COUNTRY_CODE = 'BL';
export const SH_COUNTRY_CODE = 'SH';
export const KN_COUNTRY_CODE = 'KN';
export const LC_COUNTRY_CODE = 'LC';
export const MF_COUNTRY_CODE = 'MF';
export const PM_COUNTRY_CODE = 'PM';
export const VC_COUNTRY_CODE = 'VC';
export const WS_COUNTRY_CODE = 'WS';
export const SM_COUNTRY_CODE = 'SM';
export const ST_COUNTRY_CODE = 'ST';
export const SA_COUNTRY_CODE = 'SA';
export const SN_COUNTRY_CODE = 'SN';
export const RS_COUNTRY_CODE = 'RS';
export const SC_COUNTRY_CODE = 'SC';
export const SL_COUNTRY_CODE = 'SL';
export const SG_COUNTRY_CODE = 'SG';
export const SX_COUNTRY_CODE = 'SX';
export const SK_COUNTRY_CODE = 'SK';
export const SI_COUNTRY_CODE = 'SI';
export const SB_COUNTRY_CODE = 'SB';
export const SO_COUNTRY_CODE = 'SO';
export const ZA_COUNTRY_CODE = 'ZA';
export const GS_COUNTRY_CODE = 'GS';
export const KR_COUNTRY_CODE = 'KR';
export const SS_COUNTRY_CODE = 'SS';
export const ES_COUNTRY_CODE = 'ES';
export const LK_COUNTRY_CODE = 'LK';
export const SD_COUNTRY_CODE = 'SD';
export const SR_COUNTRY_CODE = 'SR';
export const SJ_COUNTRY_CODE = 'SJ';
export const SE_COUNTRY_CODE = 'SE';
export const CH_COUNTRY_CODE = 'CH';
export const SY_COUNTRY_CODE = 'SY';
export const TW_COUNTRY_CODE = 'TW';
export const TJ_COUNTRY_CODE = 'TJ';
export const TZ_COUNTRY_CODE = 'TZ';
export const TH_COUNTRY_CODE = 'TH';
export const TL_COUNTRY_CODE = 'TL';
export const TG_COUNTRY_CODE = 'TG';
export const TK_COUNTRY_CODE = 'TK';
export const TO_COUNTRY_CODE = 'TO';
export const TT_COUNTRY_CODE = 'TT';
export const TN_COUNTRY_CODE = 'TN';
export const TR_COUNTRY_CODE = 'TR';
export const TM_COUNTRY_CODE = 'TM';
export const TC_COUNTRY_CODE = 'TC';
export const TV_COUNTRY_CODE = 'TV';
export const UG_COUNTRY_CODE = 'UG';
export const UA_COUNTRY_CODE = 'UA';
export const AE_COUNTRY_CODE = 'AE';
export const GB_COUNTRY_CODE = 'GB';
export const UM_COUNTRY_CODE = 'UM';
export const US_COUNTRY_CODE = 'US';
export const UY_COUNTRY_CODE = 'UY';
export const UZ_COUNTRY_CODE = 'UZ';
export const VU_COUNTRY_CODE = 'VU';
export const VE_COUNTRY_CODE = 'VE';
export const VN_COUNTRY_CODE = 'VN';
export const VG_COUNTRY_CODE = 'VG';
export const VI_COUNTRY_CODE = 'VI';
export const WF_COUNTRY_CODE = 'WF';
export const EH_COUNTRY_CODE = 'EH';
export const YE_COUNTRY_CODE = 'YE';
export const ZM_COUNTRY_CODE = 'ZM';
export const ZW_COUNTRY_CODE = 'ZW';

export type CountryCode =
  | typeof AC_COUNTRY_CODE
  | typeof AF_COUNTRY_CODE
  | typeof AX_COUNTRY_CODE
  | typeof AL_COUNTRY_CODE
  | typeof DZ_COUNTRY_CODE
  | typeof AS_COUNTRY_CODE
  | typeof AD_COUNTRY_CODE
  | typeof AO_COUNTRY_CODE
  | typeof AI_COUNTRY_CODE
  | typeof AQ_COUNTRY_CODE
  | typeof AG_COUNTRY_CODE
  | typeof AR_COUNTRY_CODE
  | typeof AM_COUNTRY_CODE
  | typeof AW_COUNTRY_CODE
  | typeof AU_COUNTRY_CODE
  | typeof AT_COUNTRY_CODE
  | typeof AZ_COUNTRY_CODE
  | typeof BS_COUNTRY_CODE
  | typeof BH_COUNTRY_CODE
  | typeof BD_COUNTRY_CODE
  | typeof BB_COUNTRY_CODE
  | typeof BY_COUNTRY_CODE
  | typeof BE_COUNTRY_CODE
  | typeof BZ_COUNTRY_CODE
  | typeof BJ_COUNTRY_CODE
  | typeof BM_COUNTRY_CODE
  | typeof BT_COUNTRY_CODE
  | typeof BO_COUNTRY_CODE
  | typeof BA_COUNTRY_CODE
  | typeof BW_COUNTRY_CODE
  | typeof BV_COUNTRY_CODE
  | typeof BR_COUNTRY_CODE
  | typeof IO_COUNTRY_CODE
  | typeof BN_COUNTRY_CODE
  | typeof BG_COUNTRY_CODE
  | typeof BF_COUNTRY_CODE
  | typeof BI_COUNTRY_CODE
  | typeof CV_COUNTRY_CODE
  | typeof KH_COUNTRY_CODE
  | typeof CM_COUNTRY_CODE
  | typeof CA_COUNTRY_CODE
  | typeof BQ_COUNTRY_CODE
  | typeof KY_COUNTRY_CODE
  | typeof CF_COUNTRY_CODE
  | typeof TD_COUNTRY_CODE
  | typeof CL_COUNTRY_CODE
  | typeof CN_COUNTRY_CODE
  | typeof CX_COUNTRY_CODE
  | typeof CC_COUNTRY_CODE
  | typeof CO_COUNTRY_CODE
  | typeof KM_COUNTRY_CODE
  | typeof CK_COUNTRY_CODE
  | typeof CR_COUNTRY_CODE
  | typeof HR_COUNTRY_CODE
  | typeof CU_COUNTRY_CODE
  | typeof CW_COUNTRY_CODE
  | typeof CY_COUNTRY_CODE
  | typeof CZ_COUNTRY_CODE
  | typeof CD_COUNTRY_CODE
  | typeof DK_COUNTRY_CODE
  | typeof DJ_COUNTRY_CODE
  | typeof DM_COUNTRY_CODE
  | typeof DO_COUNTRY_CODE
  | typeof EC_COUNTRY_CODE
  | typeof EG_COUNTRY_CODE
  | typeof EU_COUNTRY_CODE
  | typeof SV_COUNTRY_CODE
  | typeof GQ_COUNTRY_CODE
  | typeof ER_COUNTRY_CODE
  | typeof EE_COUNTRY_CODE
  | typeof SZ_COUNTRY_CODE
  | typeof ET_COUNTRY_CODE
  | typeof FK_COUNTRY_CODE
  | typeof FO_COUNTRY_CODE
  | typeof FJ_COUNTRY_CODE
  | typeof FI_COUNTRY_CODE
  | typeof FR_COUNTRY_CODE
  | typeof GF_COUNTRY_CODE
  | typeof PF_COUNTRY_CODE
  | typeof TF_COUNTRY_CODE
  | typeof GA_COUNTRY_CODE
  | typeof GM_COUNTRY_CODE
  | typeof GE_COUNTRY_CODE
  | typeof DE_COUNTRY_CODE
  | typeof GH_COUNTRY_CODE
  | typeof GI_COUNTRY_CODE
  | typeof GR_COUNTRY_CODE
  | typeof GL_COUNTRY_CODE
  | typeof GD_COUNTRY_CODE
  | typeof GP_COUNTRY_CODE
  | typeof GU_COUNTRY_CODE
  | typeof GT_COUNTRY_CODE
  | typeof GG_COUNTRY_CODE
  | typeof GN_COUNTRY_CODE
  | typeof GW_COUNTRY_CODE
  | typeof GY_COUNTRY_CODE
  | typeof HT_COUNTRY_CODE
  | typeof HM_COUNTRY_CODE
  | typeof VA_COUNTRY_CODE
  | typeof HN_COUNTRY_CODE
  | typeof HK_COUNTRY_CODE
  | typeof HU_COUNTRY_CODE
  | typeof IS_COUNTRY_CODE
  | typeof IN_COUNTRY_CODE
  | typeof ID_COUNTRY_CODE
  | typeof IR_COUNTRY_CODE
  | typeof IQ_COUNTRY_CODE
  | typeof IE_COUNTRY_CODE
  | typeof IM_COUNTRY_CODE
  | typeof IL_COUNTRY_CODE
  | typeof IT_COUNTRY_CODE
  | typeof CI_COUNTRY_CODE
  | typeof JM_COUNTRY_CODE
  | typeof JP_COUNTRY_CODE
  | typeof JE_COUNTRY_CODE
  | typeof JO_COUNTRY_CODE
  | typeof KZ_COUNTRY_CODE
  | typeof KE_COUNTRY_CODE
  | typeof KI_COUNTRY_CODE
  | typeof KW_COUNTRY_CODE
  | typeof KG_COUNTRY_CODE
  | typeof LA_COUNTRY_CODE
  | typeof LV_COUNTRY_CODE
  | typeof LB_COUNTRY_CODE
  | typeof LS_COUNTRY_CODE
  | typeof LR_COUNTRY_CODE
  | typeof LY_COUNTRY_CODE
  | typeof LI_COUNTRY_CODE
  | typeof LT_COUNTRY_CODE
  | typeof LU_COUNTRY_CODE
  | typeof MO_COUNTRY_CODE
  | typeof MG_COUNTRY_CODE
  | typeof MW_COUNTRY_CODE
  | typeof MY_COUNTRY_CODE
  | typeof MV_COUNTRY_CODE
  | typeof ML_COUNTRY_CODE
  | typeof MT_COUNTRY_CODE
  | typeof MH_COUNTRY_CODE
  | typeof MQ_COUNTRY_CODE
  | typeof MR_COUNTRY_CODE
  | typeof MU_COUNTRY_CODE
  | typeof YT_COUNTRY_CODE
  | typeof MX_COUNTRY_CODE
  | typeof FM_COUNTRY_CODE
  | typeof MD_COUNTRY_CODE
  | typeof MC_COUNTRY_CODE
  | typeof MN_COUNTRY_CODE
  | typeof ME_COUNTRY_CODE
  | typeof MS_COUNTRY_CODE
  | typeof MA_COUNTRY_CODE
  | typeof MZ_COUNTRY_CODE
  | typeof MM_COUNTRY_CODE
  | typeof NA_COUNTRY_CODE
  | typeof NR_COUNTRY_CODE
  | typeof NP_COUNTRY_CODE
  | typeof NL_COUNTRY_CODE
  | typeof NC_COUNTRY_CODE
  | typeof NZ_COUNTRY_CODE
  | typeof NI_COUNTRY_CODE
  | typeof NE_COUNTRY_CODE
  | typeof NG_COUNTRY_CODE
  | typeof NU_COUNTRY_CODE
  | typeof NF_COUNTRY_CODE
  | typeof KP_COUNTRY_CODE
  | typeof MK_COUNTRY_CODE
  | typeof MP_COUNTRY_CODE
  | typeof NO_COUNTRY_CODE
  | typeof OM_COUNTRY_CODE
  | typeof PK_COUNTRY_CODE
  | typeof PW_COUNTRY_CODE
  | typeof PS_COUNTRY_CODE
  | typeof PA_COUNTRY_CODE
  | typeof PG_COUNTRY_CODE
  | typeof PY_COUNTRY_CODE
  | typeof PE_COUNTRY_CODE
  | typeof PH_COUNTRY_CODE
  | typeof PN_COUNTRY_CODE
  | typeof PL_COUNTRY_CODE
  | typeof PT_COUNTRY_CODE
  | typeof PR_COUNTRY_CODE
  | typeof QA_COUNTRY_CODE
  | typeof CG_COUNTRY_CODE
  | typeof RE_COUNTRY_CODE
  | typeof RO_COUNTRY_CODE
  | typeof RU_COUNTRY_CODE
  | typeof RW_COUNTRY_CODE
  | typeof BL_COUNTRY_CODE
  | typeof SH_COUNTRY_CODE
  | typeof KN_COUNTRY_CODE
  | typeof LC_COUNTRY_CODE
  | typeof MF_COUNTRY_CODE
  | typeof PM_COUNTRY_CODE
  | typeof VC_COUNTRY_CODE
  | typeof WS_COUNTRY_CODE
  | typeof SM_COUNTRY_CODE
  | typeof ST_COUNTRY_CODE
  | typeof SA_COUNTRY_CODE
  | typeof SN_COUNTRY_CODE
  | typeof RS_COUNTRY_CODE
  | typeof SC_COUNTRY_CODE
  | typeof SL_COUNTRY_CODE
  | typeof SG_COUNTRY_CODE
  | typeof SX_COUNTRY_CODE
  | typeof SK_COUNTRY_CODE
  | typeof SI_COUNTRY_CODE
  | typeof SB_COUNTRY_CODE
  | typeof SO_COUNTRY_CODE
  | typeof ZA_COUNTRY_CODE
  | typeof GS_COUNTRY_CODE
  | typeof KR_COUNTRY_CODE
  | typeof SS_COUNTRY_CODE
  | typeof ES_COUNTRY_CODE
  | typeof LK_COUNTRY_CODE
  | typeof SD_COUNTRY_CODE
  | typeof SR_COUNTRY_CODE
  | typeof SJ_COUNTRY_CODE
  | typeof SE_COUNTRY_CODE
  | typeof CH_COUNTRY_CODE
  | typeof SY_COUNTRY_CODE
  | typeof TW_COUNTRY_CODE
  | typeof TJ_COUNTRY_CODE
  | typeof TZ_COUNTRY_CODE
  | typeof TH_COUNTRY_CODE
  | typeof TL_COUNTRY_CODE
  | typeof TG_COUNTRY_CODE
  | typeof TK_COUNTRY_CODE
  | typeof TO_COUNTRY_CODE
  | typeof TT_COUNTRY_CODE
  | typeof TN_COUNTRY_CODE
  | typeof TR_COUNTRY_CODE
  | typeof TM_COUNTRY_CODE
  | typeof TC_COUNTRY_CODE
  | typeof TV_COUNTRY_CODE
  | typeof UG_COUNTRY_CODE
  | typeof UA_COUNTRY_CODE
  | typeof AE_COUNTRY_CODE
  | typeof GB_COUNTRY_CODE
  | typeof UM_COUNTRY_CODE
  | typeof US_COUNTRY_CODE
  | typeof UY_COUNTRY_CODE
  | typeof UZ_COUNTRY_CODE
  | typeof VU_COUNTRY_CODE
  | typeof VE_COUNTRY_CODE
  | typeof VN_COUNTRY_CODE
  | typeof VG_COUNTRY_CODE
  | typeof VI_COUNTRY_CODE
  | typeof WF_COUNTRY_CODE
  | typeof EH_COUNTRY_CODE
  | typeof YE_COUNTRY_CODE
  | typeof ZM_COUNTRY_CODE
  | typeof ZW_COUNTRY_CODE;

export type CountryOption = {
  code: CountryCode;
  label: string;
};

const countries: CountryOption[] = [
  { code: US_COUNTRY_CODE, label: 'United States' },
  { code: CA_COUNTRY_CODE, label: 'Canada' },
  { code: MX_COUNTRY_CODE, label: 'Mexico' },
  { code: AF_COUNTRY_CODE, label: 'Afghanistan' },
  { code: AX_COUNTRY_CODE, label: 'Åland Islands' },
  { code: AL_COUNTRY_CODE, label: 'Albania' },
  { code: DZ_COUNTRY_CODE, label: 'Algeria' },
  { code: AS_COUNTRY_CODE, label: 'American Samoa' },
  { code: AD_COUNTRY_CODE, label: 'Andorra' },
  { code: AO_COUNTRY_CODE, label: 'Angola' },
  { code: AI_COUNTRY_CODE, label: 'Anguilla' },
  { code: AQ_COUNTRY_CODE, label: 'Antarctica' },
  { code: AG_COUNTRY_CODE, label: 'Antigua and Barbuda' },
  { code: AR_COUNTRY_CODE, label: 'Argentina' },
  { code: AM_COUNTRY_CODE, label: 'Armenia' },
  { code: AW_COUNTRY_CODE, label: 'Aruba' },
  { code: AU_COUNTRY_CODE, label: 'Australia' },
  { code: AT_COUNTRY_CODE, label: 'Austria' },
  { code: AZ_COUNTRY_CODE, label: 'Azerbaijan' },
  { code: BS_COUNTRY_CODE, label: 'Bahamas' },
  { code: BH_COUNTRY_CODE, label: 'Bahrain' },
  { code: BD_COUNTRY_CODE, label: 'Bangladesh' },
  { code: BB_COUNTRY_CODE, label: 'Barbados' },
  { code: BY_COUNTRY_CODE, label: 'Belarus' },
  { code: BE_COUNTRY_CODE, label: 'Belgium' },
  { code: BZ_COUNTRY_CODE, label: 'Belize' },
  { code: BJ_COUNTRY_CODE, label: 'Benin' },
  { code: BM_COUNTRY_CODE, label: 'Bermuda' },
  { code: BT_COUNTRY_CODE, label: 'Bhutan' },
  { code: BO_COUNTRY_CODE, label: 'Bolivia' },
  { code: BA_COUNTRY_CODE, label: 'Bosnia and Herzegovina' },
  { code: BW_COUNTRY_CODE, label: 'Botswana' },
  { code: BV_COUNTRY_CODE, label: 'Bouvet Island' },
  { code: BR_COUNTRY_CODE, label: 'Brazil' },
  { code: IO_COUNTRY_CODE, label: 'British Indian Ocean Territory' },
  { code: BN_COUNTRY_CODE, label: 'Brunei' },
  { code: BG_COUNTRY_CODE, label: 'Bulgaria' },
  { code: BF_COUNTRY_CODE, label: 'Burkina Faso' },
  { code: BI_COUNTRY_CODE, label: 'Burundi' },
  { code: CV_COUNTRY_CODE, label: 'Cabo Verde' },
  { code: KH_COUNTRY_CODE, label: 'Cambodia' },
  { code: CM_COUNTRY_CODE, label: 'Cameroon' },
  { code: BQ_COUNTRY_CODE, label: 'Caribbean Netherlands' },
  { code: KY_COUNTRY_CODE, label: 'Cayman Islands' },
  { code: CF_COUNTRY_CODE, label: 'Central African Republic' },
  { code: TD_COUNTRY_CODE, label: 'Chad' },
  { code: CL_COUNTRY_CODE, label: 'Chile' },
  { code: CN_COUNTRY_CODE, label: 'China' },
  { code: CX_COUNTRY_CODE, label: 'Christmas Island' },
  { code: CC_COUNTRY_CODE, label: 'Cocos Islands' },
  { code: CO_COUNTRY_CODE, label: 'Colombia' },
  { code: KM_COUNTRY_CODE, label: 'Comoros' },
  { code: CK_COUNTRY_CODE, label: 'Cook Islands' },
  { code: CR_COUNTRY_CODE, label: 'Costa Rica' },
  { code: HR_COUNTRY_CODE, label: 'Croatia' },
  { code: CU_COUNTRY_CODE, label: 'Cuba' },
  { code: CW_COUNTRY_CODE, label: 'Curaçao' },
  { code: CY_COUNTRY_CODE, label: 'Cyprus' },
  { code: CZ_COUNTRY_CODE, label: 'Czechia' },
  { code: CD_COUNTRY_CODE, label: 'Democratic Republic of the Congo' },
  { code: DK_COUNTRY_CODE, label: 'Denmark' },
  { code: DJ_COUNTRY_CODE, label: 'Djibouti' },
  { code: DM_COUNTRY_CODE, label: 'Dominica' },
  { code: DO_COUNTRY_CODE, label: 'Dominican Republic' },
  { code: EC_COUNTRY_CODE, label: 'Ecuador' },
  { code: EG_COUNTRY_CODE, label: 'Egypt' },
  { code: SV_COUNTRY_CODE, label: 'El Salvador' },
  { code: GQ_COUNTRY_CODE, label: 'Equatorial Guinea' },
  { code: ER_COUNTRY_CODE, label: 'Eritrea' },
  { code: EE_COUNTRY_CODE, label: 'Estonia' },
  { code: EU_COUNTRY_CODE, label: 'European Union' },
  { code: SZ_COUNTRY_CODE, label: 'Eswatini' },
  { code: ET_COUNTRY_CODE, label: 'Ethiopia' },
  { code: FK_COUNTRY_CODE, label: 'Falkland Islands' },
  { code: FO_COUNTRY_CODE, label: 'Faroe Islands' },
  { code: FJ_COUNTRY_CODE, label: 'Fiji' },
  { code: FI_COUNTRY_CODE, label: 'Finland' },
  { code: FR_COUNTRY_CODE, label: 'France' },
  { code: GF_COUNTRY_CODE, label: 'French Guiana' },
  { code: PF_COUNTRY_CODE, label: 'French Polynesia' },
  { code: TF_COUNTRY_CODE, label: 'French Southern Territories' },
  { code: GA_COUNTRY_CODE, label: 'Gabon' },
  { code: GM_COUNTRY_CODE, label: 'Gambia' },
  { code: GE_COUNTRY_CODE, label: 'Georgia' },
  { code: DE_COUNTRY_CODE, label: 'Germany' },
  { code: GH_COUNTRY_CODE, label: 'Ghana' },
  { code: GI_COUNTRY_CODE, label: 'Gibraltar' },
  { code: GR_COUNTRY_CODE, label: 'Greece' },
  { code: GL_COUNTRY_CODE, label: 'Greenland' },
  { code: GD_COUNTRY_CODE, label: 'Grenada' },
  { code: GP_COUNTRY_CODE, label: 'Guadeloupe' },
  { code: GU_COUNTRY_CODE, label: 'Guam' },
  { code: GT_COUNTRY_CODE, label: 'Guatemala' },
  { code: GG_COUNTRY_CODE, label: 'Guernsey' },
  { code: GN_COUNTRY_CODE, label: 'Guinea' },
  { code: GW_COUNTRY_CODE, label: 'Guinea-Bissau' },
  { code: GY_COUNTRY_CODE, label: 'Guyana' },
  { code: HT_COUNTRY_CODE, label: 'Haiti' },
  { code: HM_COUNTRY_CODE, label: 'Heard Island & Mcdonald Islands' },
  { code: VA_COUNTRY_CODE, label: 'Holy See' },
  { code: HN_COUNTRY_CODE, label: 'Honduras' },
  { code: HK_COUNTRY_CODE, label: 'Hong Kong' },
  { code: HU_COUNTRY_CODE, label: 'Hungary' },
  { code: IS_COUNTRY_CODE, label: 'Iceland' },
  { code: IN_COUNTRY_CODE, label: 'India' },
  { code: ID_COUNTRY_CODE, label: 'Indonesia' },
  { code: IR_COUNTRY_CODE, label: 'Iran' },
  { code: IQ_COUNTRY_CODE, label: 'Iraq' },
  { code: IE_COUNTRY_CODE, label: 'Ireland' },
  { code: IM_COUNTRY_CODE, label: 'Isle of Man' },
  { code: IL_COUNTRY_CODE, label: 'Israel' },
  { code: IT_COUNTRY_CODE, label: 'Italy' },
  { code: CI_COUNTRY_CODE, label: 'Ivory Coast' },
  { code: JM_COUNTRY_CODE, label: 'Jamaica' },
  { code: JP_COUNTRY_CODE, label: 'Japan' },
  { code: JE_COUNTRY_CODE, label: 'Jersey' },
  { code: JO_COUNTRY_CODE, label: 'Jordan' },
  { code: KZ_COUNTRY_CODE, label: 'Kazakhstan' },
  { code: KE_COUNTRY_CODE, label: 'Kenya' },
  { code: KI_COUNTRY_CODE, label: 'Kiribati' },
  { code: KW_COUNTRY_CODE, label: 'Kuwait' },
  { code: KG_COUNTRY_CODE, label: 'Kyrgyzstan' },
  { code: LA_COUNTRY_CODE, label: 'Laos' },
  { code: LV_COUNTRY_CODE, label: 'Latvia' },
  { code: LB_COUNTRY_CODE, label: 'Lebanon' },
  { code: LS_COUNTRY_CODE, label: 'Lesotho' },
  { code: LR_COUNTRY_CODE, label: 'Liberia' },
  { code: LY_COUNTRY_CODE, label: 'Libya' },
  { code: LI_COUNTRY_CODE, label: 'Liechtenstein' },
  { code: LT_COUNTRY_CODE, label: 'Lithuania' },
  { code: LU_COUNTRY_CODE, label: 'Luxembourg' },
  { code: MO_COUNTRY_CODE, label: 'Macao' },
  { code: MG_COUNTRY_CODE, label: 'Madagascar' },
  { code: MW_COUNTRY_CODE, label: 'Malawi' },
  { code: MY_COUNTRY_CODE, label: 'Malaysia' },
  { code: MV_COUNTRY_CODE, label: 'Maldives' },
  { code: ML_COUNTRY_CODE, label: 'Mali' },
  { code: MT_COUNTRY_CODE, label: 'Malta' },
  { code: MH_COUNTRY_CODE, label: 'Marshall Islands' },
  { code: MQ_COUNTRY_CODE, label: 'Martinique' },
  { code: MR_COUNTRY_CODE, label: 'Mauritania' },
  { code: MU_COUNTRY_CODE, label: 'Mauritius' },
  { code: YT_COUNTRY_CODE, label: 'Mayotte' },
  { code: FM_COUNTRY_CODE, label: 'Micronesia' },
  { code: MD_COUNTRY_CODE, label: 'Moldova' },
  { code: MC_COUNTRY_CODE, label: 'Monaco' },
  { code: MN_COUNTRY_CODE, label: 'Mongolia' },
  { code: ME_COUNTRY_CODE, label: 'Montenegro' },
  { code: MS_COUNTRY_CODE, label: 'Montserrat' },
  { code: MA_COUNTRY_CODE, label: 'Morocco' },
  { code: MZ_COUNTRY_CODE, label: 'Mozambique' },
  { code: MM_COUNTRY_CODE, label: 'Myanmar' },
  { code: NA_COUNTRY_CODE, label: 'Namibia' },
  { code: NR_COUNTRY_CODE, label: 'Nauru' },
  { code: NP_COUNTRY_CODE, label: 'Nepal' },
  { code: NL_COUNTRY_CODE, label: 'Netherlands' },
  { code: NC_COUNTRY_CODE, label: 'New Caledonia' },
  { code: NZ_COUNTRY_CODE, label: 'New Zealand' },
  { code: NI_COUNTRY_CODE, label: 'Nicaragua' },
  { code: NE_COUNTRY_CODE, label: 'Niger' },
  { code: NG_COUNTRY_CODE, label: 'Nigeria' },
  { code: NU_COUNTRY_CODE, label: 'Niue' },
  { code: NF_COUNTRY_CODE, label: 'Norfolk Island' },
  { code: KP_COUNTRY_CODE, label: 'North Korea' },
  { code: MK_COUNTRY_CODE, label: 'North Macedonia' },
  { code: MP_COUNTRY_CODE, label: 'Northern Mariana Islands' },
  { code: NO_COUNTRY_CODE, label: 'Norway' },
  { code: OM_COUNTRY_CODE, label: 'Oman' },
  { code: PK_COUNTRY_CODE, label: 'Pakistan' },
  { code: PW_COUNTRY_CODE, label: 'Palau' },
  { code: PS_COUNTRY_CODE, label: 'Palestine' },
  { code: PA_COUNTRY_CODE, label: 'Panama' },
  { code: PG_COUNTRY_CODE, label: 'Papua New Guinea' },
  { code: PY_COUNTRY_CODE, label: 'Paraguay' },
  { code: PE_COUNTRY_CODE, label: 'Peru' },
  { code: PH_COUNTRY_CODE, label: 'Philippines' },
  { code: PN_COUNTRY_CODE, label: 'Pitcairn' },
  { code: PL_COUNTRY_CODE, label: 'Poland' },
  { code: PT_COUNTRY_CODE, label: 'Portugal' },
  { code: PR_COUNTRY_CODE, label: 'Puerto Rico' },
  { code: QA_COUNTRY_CODE, label: 'Qatar' },
  { code: CG_COUNTRY_CODE, label: 'Republic of the Congo' },
  { code: RE_COUNTRY_CODE, label: 'Réunion' },
  { code: RO_COUNTRY_CODE, label: 'Romania' },
  { code: RU_COUNTRY_CODE, label: 'Russia' },
  { code: RW_COUNTRY_CODE, label: 'Rwanda' },
  { code: BL_COUNTRY_CODE, label: 'Saint Barthélemy' },
  { code: SH_COUNTRY_CODE, label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: KN_COUNTRY_CODE, label: 'Saint Kitts and Nevis' },
  { code: LC_COUNTRY_CODE, label: 'Saint Lucia' },
  { code: MF_COUNTRY_CODE, label: 'Saint Martin' },
  { code: PM_COUNTRY_CODE, label: 'Saint Pierre and Miquelon' },
  { code: VC_COUNTRY_CODE, label: 'Saint Vincent and the Grenadines' },
  { code: WS_COUNTRY_CODE, label: 'Samoa' },
  { code: SM_COUNTRY_CODE, label: 'San Marino' },
  { code: ST_COUNTRY_CODE, label: 'Sao Tome and Principe' },
  { code: SA_COUNTRY_CODE, label: 'Saudi Arabia' },
  { code: SN_COUNTRY_CODE, label: 'Senegal' },
  { code: RS_COUNTRY_CODE, label: 'Serbia' },
  { code: SC_COUNTRY_CODE, label: 'Seychelles' },
  { code: SL_COUNTRY_CODE, label: 'Sierra Leone' },
  { code: SG_COUNTRY_CODE, label: 'Singapore' },
  { code: SX_COUNTRY_CODE, label: 'Sint Maarten' },
  { code: SK_COUNTRY_CODE, label: 'Slovakia' },
  { code: SI_COUNTRY_CODE, label: 'Slovenia' },
  { code: SB_COUNTRY_CODE, label: 'Solomon Islands' },
  { code: SO_COUNTRY_CODE, label: 'Somalia' },
  { code: ZA_COUNTRY_CODE, label: 'South Africa' },
  { code: GS_COUNTRY_CODE, label: 'South Georgia and the South Sandwich Islands' },
  { code: KR_COUNTRY_CODE, label: 'South Korea' },
  { code: SS_COUNTRY_CODE, label: 'South Sudan' },
  { code: ES_COUNTRY_CODE, label: 'Spain' },
  { code: LK_COUNTRY_CODE, label: 'Sri Lanka' },
  { code: SD_COUNTRY_CODE, label: 'Sudan' },
  { code: SR_COUNTRY_CODE, label: 'Suriname' },
  { code: SJ_COUNTRY_CODE, label: 'Svalbard and Jan Mayen' },
  { code: SE_COUNTRY_CODE, label: 'Sweden' },
  { code: CH_COUNTRY_CODE, label: 'Switzerland' },
  { code: SY_COUNTRY_CODE, label: 'Syria' },
  { code: TW_COUNTRY_CODE, label: 'Taiwan' },
  { code: TJ_COUNTRY_CODE, label: 'Tajikistan' },
  { code: TZ_COUNTRY_CODE, label: 'Tanzania' },
  { code: TH_COUNTRY_CODE, label: 'Thailand' },
  { code: TL_COUNTRY_CODE, label: 'Timor-Leste' },
  { code: TG_COUNTRY_CODE, label: 'Togo' },
  { code: TK_COUNTRY_CODE, label: 'Tokelau' },
  { code: TO_COUNTRY_CODE, label: 'Tonga' },
  { code: TT_COUNTRY_CODE, label: 'Trinidad and Tobago' },
  { code: TN_COUNTRY_CODE, label: 'Tunisia' },
  { code: TR_COUNTRY_CODE, label: 'Türkiye' },
  { code: TM_COUNTRY_CODE, label: 'Turkmenistan' },
  { code: TC_COUNTRY_CODE, label: 'Turks and Caicos Islands' },
  { code: TV_COUNTRY_CODE, label: 'Tuvalu' },
  { code: UG_COUNTRY_CODE, label: 'Uganda' },
  { code: UA_COUNTRY_CODE, label: 'Ukraine' },
  { code: AE_COUNTRY_CODE, label: 'United Arab Emirates' },
  { code: GB_COUNTRY_CODE, label: 'United Kingdom' },
  { code: UM_COUNTRY_CODE, label: 'United States Minor Outlying Islands' },
  { code: UY_COUNTRY_CODE, label: 'Uruguay' },
  { code: UZ_COUNTRY_CODE, label: 'Uzbekistan' },
  { code: VU_COUNTRY_CODE, label: 'Vanuatu' },
  { code: VE_COUNTRY_CODE, label: 'Venezuela' },
  { code: VN_COUNTRY_CODE, label: 'Vietnam' },
  { code: VG_COUNTRY_CODE, label: 'Virgin Islands (UK)' },
  { code: VI_COUNTRY_CODE, label: 'Virgin Islands (US)' },
  { code: WF_COUNTRY_CODE, label: 'Wallis and Futuna' },
  { code: EH_COUNTRY_CODE, label: 'Western Sahara' },
  { code: YE_COUNTRY_CODE, label: 'Yemen' },
  { code: ZM_COUNTRY_CODE, label: 'Zambia' },
  { code: ZW_COUNTRY_CODE, label: 'Zimbabwe' },
];

export default countries;
