import createPushItemsReducer from '@glass/shared/modules/redux/arrayReducer/createPushItemsReducer';
import createRemoveItemsReducer from '@glass/shared/modules/redux/arrayReducer/createRemoveItemsReducer';

const createInitialState = (keys) =>
  keys.reduce(
    (agg, key) => ({
      ...agg,
      [key]: [],
    }),
    null,
  );

const createArrayReducer = (config) => {
  const { keys } = config;
  return {
    initialState: createInitialState(keys),
    pushItemsReducer: createPushItemsReducer(config),
    removeItemsReducer: createRemoveItemsReducer(config),
  };
};

export default createArrayReducer;
