import { BASE_PATH_2_QUERY, BASE_PATH_3_QUERY } from '@glass/common/modules/pages/queryParams';
import { PERIOD_DELIMITER } from '@glass/common/modules/strings/constants';
import filterJoin from '@glass/common/modules/strings/filterJoin';
import { SectionStepWithQuery } from '@glass/web/modules/resumes/router/stepData';
import { ROOT_STEP } from '@glass/web/modules/resumes/router/steps';

const generateSectionStepKey = ({
  [BASE_PATH_2_QUERY]: section,
  [BASE_PATH_3_QUERY]: step,
}: SectionStepWithQuery) =>
  filterJoin([section, step === ROOT_STEP ? undefined : step], PERIOD_DELIMITER);

export default generateSectionStepKey;
