import persistReducer from '@glass/web/modules/redux/persistReducer';

import { TOGGLE_USER_CONTENT } from '@glass/shared/modules/userContent/toggleUserContentAction';
import { TOGGLE_USER_CONTENT_STEPPER } from '@glass/shared/modules/userContent/toggleUserContentStepperAction';

export const USER_CONTENT_REDUCER_KEY = 'userContent';

const initialState = {
  count: 0,
  showContentWidget: null,
  contents: {},
  activeSection: null,
};

export const selectUserContent = (state) => state[USER_CONTENT_REDUCER_KEY];
export const selectUserContentCount = (state) => selectUserContent(state).count;
export const selectUserContentShowContentWidget = (state) =>
  selectUserContent(state).showContentWidget;
export const selectUserContentActiveSection = (state) => selectUserContent(state).activeSection;
export const selectUserContents = (state) => selectUserContent(state).contents;

const toggleUserContentReducer = (state, action) => {
  const { section, id, text } = action.payload;
  const { favorite } = state.contents[section]?.[id] || {};
  const nextFavorite = !favorite;
  return {
    ...state,
    contents: {
      ...state.contents,
      [section]: {
        ...state.contents[section],
        [id]: {
          text,
          favorite: nextFavorite,
        },
      },
    },
    count: state.count + (nextFavorite ? 1 : -1),
    showContentWidget: (nextFavorite && section !== state.activeSection) || state.showContentWidget,
    activeSection: section,
  };
};

const toggleUserContentStepperReducer = (state, action) => {
  const { open } = action.payload;
  return {
    ...state,
    showContentWidget: typeof open !== 'undefined' ? open : !state.open,
  };
};

const userContent = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_USER_CONTENT:
      return toggleUserContentReducer(state, action);
    case TOGGLE_USER_CONTENT_STEPPER:
      return toggleUserContentStepperReducer(state, action);
    default:
      return state;
  }
};

const persistConfig = {
  key: USER_CONTENT_REDUCER_KEY,
  // make version an integer on next change
  version: 2,
  whitelist: ['count', 'contents'], // don't put showContentWidget here so it will reopen on the following page load
};

export default persistReducer(persistConfig, userContent);
