import { UtmProperties } from '@glass/common/modules/tracking/utm/createUtmProperties';
import filterObject from '@glass/common/modules/utils/filterObject';

const filterUtmParams = (query: Record<string, string>): Partial<UtmProperties> =>
  filterObject(
    query,
    /^utm_(id|source|medium|campaign|term|content|email)$/,
  )[0] as Partial<UtmProperties>;

export default filterUtmParams;
