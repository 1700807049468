import Router from 'next/router';

import { PERMANENT_REDIRECT_STATUS } from '@glass/common/modules/http/statusCodes';
import compileDynamicUrl from '@glass/common/modules/url/compileDynamicUrl';
import { formatTitle } from '@glass/common/modules/utils/printTitle';

const redirect = (
  context,
  href,
  { status = PERMANENT_REDIRECT_STATUS, shallow, replace, scroll } = {},
) => {
  const { res, asPath: contextAsPath } = context || {};
  const asPath = __BROWSER__ ? Router?.asPath : contextAsPath;

  if (!href || href === asPath) {
    if (process.env.NODE_ENV !== 'production') {
      console.info(formatTitle('Redirect Skipped'));
    }
    return null;
  }
  if (process.env.NODE_ENV !== 'production') {
    console.info(formatTitle('Redirect'));
    console.info(`FROM:${asPath}\nTO: ${JSON.stringify(href)}`);
  }
  if (__BROWSER__) {
    return replace
      ? Router.replace(href, undefined, { shallow, scroll })
      : Router.push(href, undefined, { shallow, scroll });
  }
  // server
  if (__SERVER__ && res && !res.headersSent && typeof res.writeHead === 'function') {
    res
      .writeHead(status, { Location: typeof href === 'string' ? href : compileDynamicUrl(href) })
      .end();
  }
  return null;
};

export default redirect;
