export const ZENDESK_REDUCER_KEY = 'zendesk';

const ZENDESK_LOADED = 'ZENDESK_LOADED';

const initialState = {
  loaded: false,
};

export default function zendesk(state = initialState, action) {
  switch (action.type) {
    case ZENDESK_LOADED:
      return {
        ...state,
        loaded: true,
      };
    default:
      return state;
  }
}

export const zendeskLoadedAction = () => ({
  type: ZENDESK_LOADED,
});

export const selectZendeskLoaded = (state) => state[ZENDESK_REDUCER_KEY].loaded;
