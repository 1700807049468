import createAssignmentSeed from '@glass/common/modules/assignments/createAssignmentSeed';
import {
  AssignmentVariationValue,
  AssignmentConfig,
  AssignmentContext,
} from '@glass/common/modules/assignments/types';
import randomFromArraySeed from '@glass/common/modules/random/randomFromArraySeed';

export const ASSIGNMENT_PREFIX = 'ASS_';
export const CONTENT_ASSIGNMENT_PREFIX = `C${ASSIGNMENT_PREFIX}`;

const defaultSelectAssignmentValue = (config: AssignmentConfig, assignmentId: string) => {
  const { name, version, variations = [] } = config;
  const seed = createAssignmentSeed({ id: assignmentId, name, version });
  return randomFromArraySeed(variations, seed);
};

export type Settings = {
  createAssignmentKey?: (_: AssignmentConfig) => string;
  contexts?: AssignmentContext[];
  overrides?: Record<string, AssignmentVariationValue>;
};

const createAssignments = (
  configs: AssignmentConfig[],
  assignmentId: string,
  { contexts, overrides, createAssignmentKey = ({ name }) => name }: Settings = {},
) => {
  if (!configs) {
    throw new Error('configs are required');
  }
  if (!assignmentId) {
    throw new Error('assignmentId is required');
  }

  const assignments: Record<string, AssignmentVariationValue> = {};

  configs.forEach((config) => {
    const { value, contexts: contextsConfig, name } = config;

    // no contexts mean all
    if (contexts?.length && contexts.every((context) => !contextsConfig?.includes(context))) {
      return;
    }

    if (value !== undefined) {
      assignments[createAssignmentKey(config)] = value;
      return;
    }

    assignments[createAssignmentKey(config)] =
      overrides?.[name] || defaultSelectAssignmentValue(config, assignmentId);
  });

  return assignments;
};

export default createAssignments;
