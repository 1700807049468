// todo: remove lodash on client
import startCase from 'lodash/startCase';

import {
  ABBREVIATED_CAPITALIZED_KEY,
  ABBREVIATED_KEY,
  CAPITALIZED_KEY,
  createPostFixWordFn,
  createStartCaseWordFn,
  createWordVariations,
  DEFAULT_CONTENT_CREATE_FUNCTIONS,
  PLURAL_ABBREVIATED_CAPITALIZED_KEY,
  PLURAL_ABBREVIATED_KEY,
  PLURAL_CAPITALIZED_KEY,
  PLURAL_KEY,
  SINGULAR_KEY,
  VariationKey,
} from '@glass/common/modules/content/staticVariations/createWordVariations';
import postFixWord from '@glass/common/modules/strings/postFixWord';

export const RESUME_RESUME_WORD = 'resume';
export const CV_RESUME_WORD = 'cv';
export const CURRICULUM_VITAE_RESUME_WORD = 'curriculum vitae';

export const CV_CAPITALIZED = CV_RESUME_WORD.toUpperCase();
export const CV_CAPITALIZED_PLURAL = postFixWord(CV_CAPITALIZED);
export const CURRICULUM_VITAE_PLURAL = 'curricula vitae';
export const CURRICULUM_VITAE_PLURAL_CAPITALIZED = startCase(CURRICULUM_VITAE_PLURAL);

export const RESUME_WORD_KEYS: Array<VariationKey> = [
  SINGULAR_KEY,
  PLURAL_KEY,
  CAPITALIZED_KEY,
  ABBREVIATED_KEY,
  ABBREVIATED_CAPITALIZED_KEY,
  PLURAL_ABBREVIATED_KEY,
  PLURAL_CAPITALIZED_KEY,
  PLURAL_ABBREVIATED_CAPITALIZED_KEY,
];

const RESUME_WORD_CREATE_FUNCTIONS = {
  ...DEFAULT_CONTENT_CREATE_FUNCTIONS,
  [PLURAL_KEY]: createPostFixWordFn(SINGULAR_KEY),
  [PLURAL_CAPITALIZED_KEY]: createStartCaseWordFn(PLURAL_KEY),
  [ABBREVIATED_CAPITALIZED_KEY]: createStartCaseWordFn(ABBREVIATED_KEY),
  [PLURAL_ABBREVIATED_KEY]: createPostFixWordFn(ABBREVIATED_KEY),
  [PLURAL_ABBREVIATED_CAPITALIZED_KEY]: createPostFixWordFn(ABBREVIATED_CAPITALIZED_KEY),
};

const defaults: { [key: string]: { [key2: string]: string } } = {
  [CV_RESUME_WORD]: {
    [SINGULAR_KEY]: CV_CAPITALIZED,
    [PLURAL_CAPITALIZED_KEY]: CV_CAPITALIZED_PLURAL,
  },
  [CURRICULUM_VITAE_RESUME_WORD]: {
    [PLURAL_KEY]: CURRICULUM_VITAE_PLURAL,
    [ABBREVIATED_KEY]: CV_CAPITALIZED,
    [PLURAL_ABBREVIATED_CAPITALIZED_KEY]: CV_CAPITALIZED_PLURAL,
  },
};

export const adjustCvString = (string: string) =>
  string.replace(/\bcv(s?)\b/gi, `${CV_CAPITALIZED}$1`);

export const createResumeWords = (
  variationKey: string,
  options?: { [key: string]: boolean | string },
) =>
  variationKey
    ? createWordVariations(
        {
          [SINGULAR_KEY]: variationKey,
          ...(defaults[variationKey] || {}),
        },
        RESUME_WORD_KEYS,
        RESUME_WORD_CREATE_FUNCTIONS,
        options,
      )
    : null;

export const DEFAULT_RESUME_WORDS = createResumeWords(RESUME_RESUME_WORD, {
  isBrowserSafe: true,
});

const resumeWordVariations = [RESUME_RESUME_WORD, CV_RESUME_WORD, CURRICULUM_VITAE_RESUME_WORD];

export default resumeWordVariations;
