import isEmpty from 'lodash/isEmpty';
import Router from 'next/router';

import filterUtmParams from '@glass/common/modules/tracking/utm/filterUtmParams';
import pathToURL from '@glass/common/modules/url/pathToURL';
import {
  GOOGLE_CLICK_COOKIE_NAME,
  GOOGLE_DISPLAY_COOKIE_NAME,
  UTM_COOKIE_NAME,
} from '@glass/web/modules/cookies/names';

import getGoogleClickId from '@glass/shared/modules/attribution/getGoogleClickId';
import getGoogleDisplayId from '@glass/shared/modules/attribution/getGoogleDisplayId';

const initAttributionCookieAction =
  () =>
  async (dispatch, getState, { cookies }) => {
    const { query } = pathToURL(Router.asPath, true);
    const currentUtmParams = filterUtmParams(query);
    if (!isEmpty(currentUtmParams)) {
      cookies.set(UTM_COOKIE_NAME, JSON.stringify(currentUtmParams));
    }

    const currentGclid = getGoogleClickId({ cookies, query });
    if (currentGclid) {
      cookies.set(GOOGLE_CLICK_COOKIE_NAME, currentGclid);
    }

    const currentDclid = getGoogleDisplayId({ cookies, query });
    if (currentDclid) {
      cookies.set(GOOGLE_DISPLAY_COOKIE_NAME, currentGclid);
    }

    return null;
  };

export default initAttributionCookieAction;
