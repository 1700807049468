import uniq from 'lodash/uniq';

import { CLIENT_IDENTIFIER_REQUIREMENT_ERROR } from '@glass/common/modules/errors/messages';
import makeArray from '@glass/common/modules/utils/makeArray';
import { PACKAGES_COOKIE_NAME } from '@glass/web/modules/cookies/names';

const handlePackageQueryAction =
  (packageSlugs) =>
  async (dispatch, getState, { cookies }) => {
    if (!packageSlugs) {
      throw new Error(CLIENT_IDENTIFIER_REQUIREMENT_ERROR);
    }
    const existingPackageSlugs = cookies.get(PACKAGES_COOKIE_NAME);
    const newPackageSlugs = uniq([...(existingPackageSlugs ?? []), ...makeArray(packageSlugs)]);
    return cookies.set(PACKAGES_COOKIE_NAME, newPackageSlugs);
  };

export default handlePackageQueryAction;
