import { selectUser } from '@glass/web/modules/auth';
import { RootState } from '@glass/web/modules/redux/types';
import selectUserConfig from '@glass/web/modules/userConfig/selectors/selectUserConfig';

const selectUserAssignmentId = (globalState: RootState) => {
  const { assignmentId: userAssignmentId } = selectUser(globalState) || {};
  if (userAssignmentId) {
    return userAssignmentId;
  }
  const { deviceId, assignmentId } = selectUserConfig(globalState) || {};
  return assignmentId || deviceId;
};

export default selectUserAssignmentId;
