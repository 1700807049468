import { LOCALE_EN_US } from '@glass/env/modules/locales/constants';

// TODO: Use https://github.com/google/libphonenumber to parse phone numbers
export const parsePhoneNumber = (value: string) => value?.replace(/\D/g, '');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const standardizePhone = (value: string, { prefix = '', locale = LOCALE_EN_US } = {}) => {
  let parsedPhone = parsePhoneNumber(value);
  if (parsedPhone?.length > 9 && parsedPhone?.length < 12) {
    if (parsedPhone.length === 10) {
      parsedPhone = `${prefix || ''}1${parsedPhone}`;
    } else {
      parsedPhone = `${prefix || ''}${parsedPhone}`;
    }
    return parsedPhone;
  }
  return null;
};

const formatPhone = (value?: string) => {
  if (!value) {
    return '';
  }
  const cleanPhoneNumber = parsePhoneNumber(value);
  const len = cleanPhoneNumber.length;
  if (len > 11 || len < 1) {
    return cleanPhoneNumber;
  }
  if (len > 10) {
    return cleanPhoneNumber.replace(/(\d)(\d{3})(\d{3})(\d)/, '$1 ($2) $3-$4');
  }
  if (len > 6) {
    return cleanPhoneNumber.replace(/(\d{3})(\d{3})(\d)/, '($1) $2-$3');
  }
  if (len > 3) {
    return cleanPhoneNumber.replace(/(\d{3})(\d)/, '($1) $2');
  }
  return cleanPhoneNumber.replace(/(\d*)/, '($1');
};

export default formatPhone;
