import { DeviceDetectorResult } from 'device-detector-js';

import { GeoType } from '@glass/common/modules/geo/types';

export const USER_CONFIG_REDUCER_KEY = 'userConfig';

export type UserConfigState = {
  internal?: boolean;
  asyncReady?: boolean;
  assignmentId?: string;
  deviceId?: string;
  bot: DeviceDetectorResult;
  geo: Partial<GeoType> | null;
  authReady?: boolean;
};
