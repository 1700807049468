export const DEFAULT = 'default';
export const AUTH = 'auth';
export const START = 'start';
export const END = 'end';
export const CREATE = 'create';
export const CLICK = 'click';
export const ATTEMPT = 'attempt';
export const ACCESS = 'access';
export const CANCEL = 'cancel';
export const SUCCESS = 'success';
export const FAIL = 'fail';
export const PROMPT = 'prompt';
export const SEND = 'send';
export const CANCEL_PROMPT = 'cancel-prompt';
export const TOGGLE_CONTENT = 'toggle-content';
export const SUGGESTION = 'suggestion';
export const EXPRESS_CHECKOUT_READY = 'express-checkout-ready';
