import { formValueSelector, getFormValues, isDirty, isSubmitting, isPristine } from 'redux-form';

import {
  PAYMENT_FORM_NAME,
  RESUME_BUILDER_FORM_NAME,
} from '@glass/web/modules/reduxForm/formNames';

export const selectResumeIsPristine = isPristine(RESUME_BUILDER_FORM_NAME);
export const selectResumeIsSubmitting = isSubmitting(RESUME_BUILDER_FORM_NAME);
export const selectResumeIsDirty = isDirty(RESUME_BUILDER_FORM_NAME);
export const selectResumeValue = getFormValues(RESUME_BUILDER_FORM_NAME);

export const selectResumeIdValue = (state: Record<string, unknown>): string =>
  formValueSelector(RESUME_BUILDER_FORM_NAME)(state, 'id');

export const selectResumeValueSelector = formValueSelector(RESUME_BUILDER_FORM_NAME);
export const createReduxFormValuesSelector = (formName: string) => getFormValues(formName);

export const selectPaymentFormValue = getFormValues(PAYMENT_FORM_NAME);
