import assignmentConfigs from '@glass/rock/modules/assignments/user/configs';
import { i18nConfig } from '@glass/rock/modules/locales/config';
import rockTheme from '@glass/rock/modules/theme';

import setupHighPriorityAssetEvent from '@glass/web/modules/loading/setupHighPriorityAssetEvent';
import setupKeyboardEventListeners from '@glass/web/modules/loading/setupKeyboardEventListeners';
import withPriorityEvents from '@glass/web/modules/loading/withPriorityEvents';
import '@glass/web/modules/stripe/lazyLoadStripe';
import createThemeGenerator from '@glass/web/modules/theme/createThemeGenerator';
import withTheme from '@glass/web/modules/theme/withTheme';

import App from '@glass/shared/components/App/App';
import withApollo from '@glass/shared/components/App/withApollo';
import withCookies from '@glass/shared/components/App/withCookies';
import withGlobalTracking from '@glass/shared/components/App/withGlobalTracking';
import withRedux from '@glass/shared/components/App/withRedux';
import compose from '@glass/shared/helpers/utils/compose';
import withPageData from '@glass/shared/modules/pageData/withPageData';
import reducer from '@glass/shared/modules/redux/reducer';
import subscribe from '@glass/shared/modules/store/subscribe';
import reduxTracking from '@glass/shared/modules/tracking/reduxTracking';
import initUserConfigAction from '@glass/shared/modules/userConfig/initUserConfigAction';

setupHighPriorityAssetEvent();
setupKeyboardEventListeners();

export default compose(
  withCookies({ ssr: true }),
  withApollo({
    ssr: true,
    i18nConfig,
    memoryCacheOptions: {
      typePolicies: {
        Pricing: {
          keyFields: ['id', 'currencyConfig'],
        },
      },
    },
  }),
  withRedux(reducer, {
    ssr: true,
    subscribe,
    helpers: { tracking: reduxTracking, assignmentConfigs, i18nConfig },
    onCreateStoreAction: initUserConfigAction,
  }), // must follow apollo and cookies
  withTheme(createThemeGenerator(rockTheme)),
  withPriorityEvents(),
  withPageData({ i18nConfig }),
  withGlobalTracking(),
)(App);
