import createSearchString from '@glass/common/modules/url/createSearchString';

const createUrl = ({
  origin,
  pathname,
  query,
}: {
  origin?: string;
  pathname?: string;
  query?: Record<string, unknown> | null;
}) => {
  const search = createSearchString(query);
  const path = pathname || (!search ? '/' : '');
  return `${origin || ''}${path}${search}`;
};

export default createUrl;
