import delay from '@glass/shared/helpers/utils/delay';

const waitForCondition = async (
  conditionFn,
  { remainingChecks = 40, waitInterval = 300, onWait } = {},
) => {
  if (!conditionFn()) {
    if (typeof onWait === 'function') {
      onWait();
    }
    if (remainingChecks > 0) {
      return delay(waitInterval).then(() =>
        waitForCondition(conditionFn, { remainingChecks: remainingChecks - 1, waitInterval }),
      );
    }
    throw new Error('waitForCondition timeout');
  }
  return null;
};

export default waitForCondition;
