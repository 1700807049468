import { PERIOD_DELIMITER } from '@glass/common/modules/strings/constants';
import filterJoin from '@glass/common/modules/strings/filterJoin';
import makeArray from '@glass/common/modules/utils/makeArray';

export type OptionsType = {
  keyPrefix?: string | number;
  getIndexKey?: (itm: unknown, idx: number) => string;
};

export const generateKey = (...args: unknown[]) => filterJoin(args, PERIOD_DELIMITER);

export type ModifyFn = (
  key: string,
  value: unknown,
  options: OptionsType,
) => [string, unknown] | unknown;

const modifyObjectKeyValues = (
  obj: unknown,
  modifierFn: ModifyFn,
  options: OptionsType = {},
): unknown => {
  const { keyPrefix, getIndexKey } = options;

  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item, idx) => {
      const indexKey = typeof getIndexKey === 'function' ? getIndexKey(item, idx) : idx;
      const newKeyPrefix = generateKey(keyPrefix, indexKey);
      return modifyObjectKeyValues(item, modifierFn, { ...options, keyPrefix: newKeyPrefix });
    });
  }

  return Object.entries(obj).reduce((acc: Record<string, unknown>, [key, value]) => {
    const [newKey = key, modifiedValue = value] = makeArray(
      modifierFn(key, value, { keyPrefix, getIndexKey }),
    );

    const newKeyPrefix = generateKey(keyPrefix, key);
    acc[newKey as string] = modifyObjectKeyValues(modifiedValue, modifierFn, {
      ...options,
      keyPrefix: newKeyPrefix,
    });
    return acc;
  }, {});
};

export default modifyObjectKeyValues;
