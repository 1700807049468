import { CountryCode } from '@glass/common/modules/geo/data/countries';
import { LocaleSettingType } from '@glass/env/modules/locales/constants';

export type CurrencySetting = {
  minUnitAmount?: number;
  name: string;
  locales?: LocaleSettingType[];
  flag: CountryCode;
  isAmex?: boolean;
  decimals?: number;
  significantDigit?: number;
};

export type CurrencyConfig = CurrencySetting & {
  id: string;
  code: CurrencyCode;
  decimals: number;
  significantDigit: number;
  minUnitAmount: number;
  isAmex: boolean;
  conversionFee: number;
};

export const USD_CURRENCY = 'usd';
export const AED_CURRENCY = 'aed';
export const AFN_CURRENCY = 'afn';
export const ALL_CURRENCY = 'all';
export const AMD_CURRENCY = 'amd';
export const ANG_CURRENCY = 'ang';
export const AOA_CURRENCY = 'aoa';
export const ARS_CURRENCY = 'ars';
export const AUD_CURRENCY = 'aud';
export const AWG_CURRENCY = 'awg';
export const AZN_CURRENCY = 'azn';
export const BAM_CURRENCY = 'bam';
export const BBD_CURRENCY = 'bbd';
export const BDT_CURRENCY = 'bdt';
export const BGN_CURRENCY = 'bgn';
export const BHD_CURRENCY = 'bhd';
export const BIF_CURRENCY = 'bif';
export const BMD_CURRENCY = 'bmd';
export const BND_CURRENCY = 'bnd';
export const BOB_CURRENCY = 'bob';
export const BRL_CURRENCY = 'brl';
export const BSD_CURRENCY = 'bsd';
export const BWP_CURRENCY = 'bwp';
export const BYN_CURRENCY = 'byn';
export const BZD_CURRENCY = 'bzd';
export const CAD_CURRENCY = 'cad';
export const CDF_CURRENCY = 'cdf';
export const CHF_CURRENCY = 'chf';
export const CLP_CURRENCY = 'clp';
export const CNY_CURRENCY = 'cny';
export const COP_CURRENCY = 'cop';
export const CRC_CURRENCY = 'crc';
export const CVE_CURRENCY = 'cve';
export const CZK_CURRENCY = 'czk';
export const DJF_CURRENCY = 'djf';
export const DKK_CURRENCY = 'dkk';
export const DOP_CURRENCY = 'dop';
export const DZD_CURRENCY = 'dzd';
export const EGP_CURRENCY = 'egp';
export const ETB_CURRENCY = 'etb';
export const EUR_CURRENCY = 'eur';
export const FJD_CURRENCY = 'fjd';
export const FKP_CURRENCY = 'fkp';
export const GBP_CURRENCY = 'gbp';
export const GEL_CURRENCY = 'gel';
export const GIP_CURRENCY = 'gip';
export const GMD_CURRENCY = 'gmd';
export const GNF_CURRENCY = 'gnf';
export const GTQ_CURRENCY = 'gtq';
export const GYD_CURRENCY = 'gyd';
export const HKD_CURRENCY = 'hkd';
export const HNL_CURRENCY = 'hnl';
export const HTG_CURRENCY = 'htg';
export const HUF_CURRENCY = 'huf';
export const IDR_CURRENCY = 'idr';
export const ILS_CURRENCY = 'ils';
export const INR_CURRENCY = 'inr';
export const ISK_CURRENCY = 'isk';
export const JMD_CURRENCY = 'jmd';
export const JOD_CURRENCY = 'jod';
export const JPY_CURRENCY = 'jpy';
export const KES_CURRENCY = 'kes';
export const KGS_CURRENCY = 'kgs';
export const KHR_CURRENCY = 'khr';
export const KMF_CURRENCY = 'kmf';
export const KRW_CURRENCY = 'krw';
export const KWD_CURRENCY = 'kwd';
export const KYD_CURRENCY = 'kyd';
export const KZT_CURRENCY = 'kzt';
export const LAK_CURRENCY = 'lak';
export const LBP_CURRENCY = 'lbp';
export const LKR_CURRENCY = 'lkr';
export const LRD_CURRENCY = 'lrd';
export const LSL_CURRENCY = 'lsl';
export const MAD_CURRENCY = 'mad';
export const MDL_CURRENCY = 'mdl';
export const MGA_CURRENCY = 'mga';
export const MKD_CURRENCY = 'mkd';
export const MMK_CURRENCY = 'mmk';
export const MNT_CURRENCY = 'mnt';
export const MOP_CURRENCY = 'mop';
export const MUR_CURRENCY = 'mur';
export const MVR_CURRENCY = 'mvr';
export const MWK_CURRENCY = 'mwk';
export const MXN_CURRENCY = 'mxn';
export const MYR_CURRENCY = 'myr';
export const MZN_CURRENCY = 'mzn';
export const NAD_CURRENCY = 'nad';
export const NGN_CURRENCY = 'ngn';
export const NIO_CURRENCY = 'nio';
export const NOK_CURRENCY = 'nok';
export const NPR_CURRENCY = 'npr';
export const OMR_CURRENCY = 'omr';
export const NZD_CURRENCY = 'nzd';
export const PAB_CURRENCY = 'pab';
export const PEN_CURRENCY = 'pen';
export const PGK_CURRENCY = 'pgk';
export const PHP_CURRENCY = 'php';
export const PKR_CURRENCY = 'pkr';
export const PLN_CURRENCY = 'pln';
export const PYG_CURRENCY = 'pyg';
export const QAR_CURRENCY = 'qar';
export const RON_CURRENCY = 'ron';
export const RSD_CURRENCY = 'rsd';
export const RUB_CURRENCY = 'rub';
export const RWF_CURRENCY = 'rwf';
export const SAR_CURRENCY = 'sar';
export const SBD_CURRENCY = 'sbd';
export const SCR_CURRENCY = 'scr';
export const SEK_CURRENCY = 'sek';
export const SGD_CURRENCY = 'sgd';
export const SHP_CURRENCY = 'shp';
export const SLE_CURRENCY = 'sle';
export const SOS_CURRENCY = 'sos';
export const SRD_CURRENCY = 'srd';
export const STD_CURRENCY = 'std';
export const SZL_CURRENCY = 'szl';
export const THB_CURRENCY = 'thb';
export const TJS_CURRENCY = 'tjs';
export const TOP_CURRENCY = 'top';
export const TND_CURRENCY = 'tnd';
export const TRY_CURRENCY = 'try';
export const TTD_CURRENCY = 'ttd';
export const TWD_CURRENCY = 'twd';
export const TZS_CURRENCY = 'tzs';
export const UAH_CURRENCY = 'uah';
export const UGX_CURRENCY = 'ugx';
export const UYU_CURRENCY = 'uyu';
export const UZS_CURRENCY = 'uzs';
export const VND_CURRENCY = 'vnd';
export const VUV_CURRENCY = 'vuv';
export const WST_CURRENCY = 'wst';
export const XAF_CURRENCY = 'xaf';
export const XCD_CURRENCY = 'xcd';
export const XOF_CURRENCY = 'xof';
export const XPF_CURRENCY = 'xpf';
export const YER_CURRENCY = 'yer';
export const ZAR_CURRENCY = 'zar';
export const ZMW_CURRENCY = 'zmw';

export type CurrencyCode =
  | typeof USD_CURRENCY
  | typeof AED_CURRENCY
  | typeof AFN_CURRENCY
  | typeof ALL_CURRENCY
  | typeof AMD_CURRENCY
  | typeof ANG_CURRENCY
  | typeof AOA_CURRENCY
  | typeof ARS_CURRENCY
  | typeof AUD_CURRENCY
  | typeof AWG_CURRENCY
  | typeof AZN_CURRENCY
  | typeof BAM_CURRENCY
  | typeof BBD_CURRENCY
  | typeof BDT_CURRENCY
  | typeof BGN_CURRENCY
  | typeof BHD_CURRENCY
  | typeof BIF_CURRENCY
  | typeof BMD_CURRENCY
  | typeof BND_CURRENCY
  | typeof BOB_CURRENCY
  | typeof BRL_CURRENCY
  | typeof BSD_CURRENCY
  | typeof BWP_CURRENCY
  | typeof BYN_CURRENCY
  | typeof BZD_CURRENCY
  | typeof CAD_CURRENCY
  | typeof CDF_CURRENCY
  | typeof CHF_CURRENCY
  | typeof CLP_CURRENCY
  | typeof CNY_CURRENCY
  | typeof COP_CURRENCY
  | typeof CRC_CURRENCY
  | typeof CVE_CURRENCY
  | typeof CZK_CURRENCY
  | typeof DJF_CURRENCY
  | typeof DKK_CURRENCY
  | typeof DOP_CURRENCY
  | typeof DZD_CURRENCY
  | typeof EGP_CURRENCY
  | typeof ETB_CURRENCY
  | typeof EUR_CURRENCY
  | typeof FJD_CURRENCY
  | typeof FKP_CURRENCY
  | typeof GBP_CURRENCY
  | typeof GEL_CURRENCY
  | typeof GIP_CURRENCY
  | typeof GMD_CURRENCY
  | typeof GNF_CURRENCY
  | typeof GTQ_CURRENCY
  | typeof GYD_CURRENCY
  | typeof HKD_CURRENCY
  | typeof HNL_CURRENCY
  | typeof HTG_CURRENCY
  | typeof HUF_CURRENCY
  | typeof IDR_CURRENCY
  | typeof ILS_CURRENCY
  | typeof INR_CURRENCY
  | typeof ISK_CURRENCY
  | typeof JMD_CURRENCY
  | typeof JOD_CURRENCY
  | typeof JPY_CURRENCY
  | typeof KES_CURRENCY
  | typeof KGS_CURRENCY
  | typeof KHR_CURRENCY
  | typeof KMF_CURRENCY
  | typeof KRW_CURRENCY
  | typeof KWD_CURRENCY
  | typeof KYD_CURRENCY
  | typeof KZT_CURRENCY
  | typeof LAK_CURRENCY
  | typeof LBP_CURRENCY
  | typeof LKR_CURRENCY
  | typeof LRD_CURRENCY
  | typeof LSL_CURRENCY
  | typeof MAD_CURRENCY
  | typeof MDL_CURRENCY
  | typeof MGA_CURRENCY
  | typeof MKD_CURRENCY
  | typeof MMK_CURRENCY
  | typeof MNT_CURRENCY
  | typeof MOP_CURRENCY
  | typeof MUR_CURRENCY
  | typeof MVR_CURRENCY
  | typeof MWK_CURRENCY
  | typeof MXN_CURRENCY
  | typeof MYR_CURRENCY
  | typeof MZN_CURRENCY
  | typeof NAD_CURRENCY
  | typeof NGN_CURRENCY
  | typeof NIO_CURRENCY
  | typeof NOK_CURRENCY
  | typeof NPR_CURRENCY
  | typeof OMR_CURRENCY
  | typeof NZD_CURRENCY
  | typeof PAB_CURRENCY
  | typeof PEN_CURRENCY
  | typeof PGK_CURRENCY
  | typeof PHP_CURRENCY
  | typeof PKR_CURRENCY
  | typeof PLN_CURRENCY
  | typeof PYG_CURRENCY
  | typeof QAR_CURRENCY
  | typeof RON_CURRENCY
  | typeof RSD_CURRENCY
  | typeof RUB_CURRENCY
  | typeof RWF_CURRENCY
  | typeof SAR_CURRENCY
  | typeof SBD_CURRENCY
  | typeof SCR_CURRENCY
  | typeof SEK_CURRENCY
  | typeof SGD_CURRENCY
  | typeof SHP_CURRENCY
  | typeof SLE_CURRENCY
  | typeof SOS_CURRENCY
  | typeof SRD_CURRENCY
  | typeof STD_CURRENCY
  | typeof SZL_CURRENCY
  | typeof THB_CURRENCY
  | typeof TJS_CURRENCY
  | typeof TOP_CURRENCY
  | typeof TND_CURRENCY
  | typeof TRY_CURRENCY
  | typeof TTD_CURRENCY
  | typeof TWD_CURRENCY
  | typeof TZS_CURRENCY
  | typeof UAH_CURRENCY
  | typeof UGX_CURRENCY
  | typeof UYU_CURRENCY
  | typeof UZS_CURRENCY
  | typeof VND_CURRENCY
  | typeof VUV_CURRENCY
  | typeof WST_CURRENCY
  | typeof XAF_CURRENCY
  | typeof XCD_CURRENCY
  | typeof XOF_CURRENCY
  | typeof XPF_CURRENCY
  | typeof YER_CURRENCY
  | typeof ZAR_CURRENCY
  | typeof ZMW_CURRENCY;
