import {
  CAPITALIZED_KEY,
  createStartCaseWordFn,
  createWorderWordFn,
  createWordVariations,
  DEFAULT_CONTENT_CREATE_FUNCTIONS,
  PAST_CAPITALIZED_KEY,
  PAST_KEY,
  SINGULAR_KEY,
  WORDER_CAPITALIZED_KEY,
  WORDER_KEY,
  WORDING_CAPITALIZED_KEY,
  WORDING_KEY,
  CreateWordVariationsOptions,
  VariationKey,
  StringObject,
} from '@glass/common/modules/content/staticVariations/createWordVariations';
import makeWorder from '@glass/common/modules/strings/makeWorder';

import {
  CREATE_VERBER_WORD,
  EDIT_VERBER_WORD,
  GENERATE_VERBER_WORD,
} from '@glass/shared/modules/content/staticVariations/verbWords/verberWordVariations';

export const BUILD_VERB_WORD = 'build';
export const CREATE_VERB_WORD = 'create';
export const MAKE_VERB_WORD = 'make';
export const WRITE_VERB_WORD = 'write';
export const GENERATE_VERB_WORD = 'generate';
export const EDIT_VERB_WORD = 'edit';

export const BUILD_PAST_VERB_WORD = 'built';
export const MAKE_PAST_VERB_WORD = 'made';
export const WRITE_PAST_VERB_WORD = 'wrote';

export const VERB_WORD_KEYS: VariationKey[] = [
  SINGULAR_KEY,
  CAPITALIZED_KEY,
  WORDER_KEY,
  WORDER_CAPITALIZED_KEY,
  WORDING_KEY,
  WORDING_CAPITALIZED_KEY,
  PAST_KEY,
  PAST_CAPITALIZED_KEY,
];

const defaults: Record<string, StringObject> = {
  [BUILD_VERB_WORD]: {
    [PAST_KEY]: BUILD_PAST_VERB_WORD,
  },
  [CREATE_VERB_WORD]: {
    [WORDER_KEY]: CREATE_VERBER_WORD,
  },
  [MAKE_PAST_VERB_WORD]: {
    [PAST_KEY]: MAKE_PAST_VERB_WORD,
  },
  [WRITE_VERB_WORD]: {
    [PAST_KEY]: WRITE_PAST_VERB_WORD,
  },
  [GENERATE_VERB_WORD]: {
    [WORDER_KEY]: GENERATE_VERBER_WORD,
  },
  [EDIT_VERB_WORD]: {
    [WORDER_KEY]: EDIT_VERBER_WORD,
  },
} as const;

const VERB_WORD_CREATE_FUNCTIONS = {
  ...DEFAULT_CONTENT_CREATE_FUNCTIONS,
  [WORDER_KEY]: createWorderWordFn(SINGULAR_KEY),
  [PAST_KEY]: createWorderWordFn(SINGULAR_KEY, 'ed'),
  [WORDING_KEY]: createWorderWordFn(SINGULAR_KEY, 'ing'),
  [WORDER_CAPITALIZED_KEY]: createStartCaseWordFn(WORDER_KEY),
  [PAST_CAPITALIZED_KEY]: createStartCaseWordFn(PAST_KEY),
  [WORDING_CAPITALIZED_KEY]: createStartCaseWordFn(WORDING_KEY),
};

export const createVerbWords = (
  variationKey?: string | null,
  options?: CreateWordVariationsOptions,
) =>
  variationKey
    ? createWordVariations(
        {
          [SINGULAR_KEY]: variationKey,
          ...(defaults[variationKey] || {}),
        },
        VERB_WORD_KEYS,
        VERB_WORD_CREATE_FUNCTIONS,
        options,
      )
    : null;

export const DEFAULT_VERB_WORDS = createVerbWords(BUILD_VERB_WORD, { isBrowserSafe: true });

const primaryVerbVariations = [
  BUILD_VERB_WORD,
  CREATE_VERB_WORD,
  MAKE_VERB_WORD,
  WRITE_VERB_WORD,
] as const;

const verbWordVariations = [...primaryVerbVariations, GENERATE_VERB_WORD, EDIT_VERB_WORD] as const;

export const verberToVerbMapping = verbWordVariations.reduce(
  (map, verbWord) => ({
    ...map,
    [defaults[verbWord]?.[WORDER_KEY] || makeWorder(verbWord)]: verbWord,
  }),
  {},
);

export default verbWordVariations;
