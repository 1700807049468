import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { BASE_PATH_1_PATHNAME, BROWSE_BASE_PATHS } from '@glass/common/modules/pages/paths';
import { BASE_PATH_1_QUERY } from '@glass/common/modules/pages/queryParams';
import setResumeBuilderBasePath from '@glass/web/modules/content/actions/setResumeBuilderBasePath';
import useDispatch from '@glass/web/modules/redux/useDispatch';
import useBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/useBuilderBasePath';

const useSetDefaultResumeBuilderBasePath = () => {
  const dispatch = useDispatch();
  const {
    query: { [BASE_PATH_1_QUERY]: basePath1 },
    pathname,
  } = useRouter();
  const defaultResumeBuilderBasePath = useBuilderBasePath();
  useEffect(() => {
    const isNotDefaultPath = basePath1 && basePath1 !== defaultResumeBuilderBasePath;
    const isDifferentToNotFoundPath = pathname && pathname !== '/404';
    const hasBasePath1 = pathname.includes(BASE_PATH_1_PATHNAME);
    const hasBrowsePath = BROWSE_BASE_PATHS.includes(
      basePath1 as (typeof BROWSE_BASE_PATHS)[number],
    );
    if (isNotDefaultPath && isDifferentToNotFoundPath && hasBasePath1 && !hasBrowsePath) {
      dispatch(setResumeBuilderBasePath(basePath1 as string));
    }
  }, [defaultResumeBuilderBasePath, dispatch, basePath1, pathname]);

  return defaultResumeBuilderBasePath;
};

export default useSetDefaultResumeBuilderBasePath;
