export const ACTIVE_RESUME = 'active-resume';
export const API = 'api';
export const ASSIGNMENTS = 'assignments';
export const AUTH = 'auth';
export const CHECKOUT = 'checkout';
export const CHURNKEY = 'churnkey';
export const CLICK = 'click';
export const COMMUNICATION = 'communication';
export const DOWNLOAD = 'download';
export const OFFER = 'offer';
export const EMAIL = 'email';
export const PAGEVIEW = 'pageview';
export const PRICING = 'pricing';
export const RESUME = 'resume';
export const RESUME_IMPROVEMENTS = 'resume-improvements';
export const SAVE_PROGRESS = 'save-progress';
export const SEARCH = 'search';
export const SESSION = 'session';
export const SHARE = 'share';
export const SUBSCRIPTION = 'subscription';
export const USER_PACKAGE = 'user-package';
export const ZENDESK_WIDGET = 'zendesk-widget';
export const EXCEPTION = 'exception';
